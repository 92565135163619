import styled from 'styled-components';

import { media } from 'Components/Layout';

const StyledCookieBanner = styled.div`
  position: fixed;
  right: 24px;
  bottom: 24px;
  background-color: ${props => props.theme.colors.white};
  width: 368px;
  max-height: 395px;
  padding: 24px;
  box-shadow: 0px 4px 13px rgba(193, 188, 183, 0.3);
  border-radius: 16px;
  font-size: 16px;
  line-height: 24px;
  z-index: 99;

  div.close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &:hover {
      cursor: pointer;
    }

    svg {
      width: auto;
    }
  }

  p {
    margin: 0;
    margin-top: 37px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 57px;
  }

  a {
    color: ${props => props.theme.colors.brand.orange} !important;
    &.configure-cookies {
      display: block;
      margin-top: 20px;
    }
  }

  div.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 100%;
    }
  }

  ${media.mobile(`
    left: 0;
    right: 0;
    width: calc(100% - 48px);
    box-sizing: border-box;
    margin: 0 24px;
    max-height: 100%;
    z-index: 1;
  `)}
`;

export const StyledModal = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  border-radius: 16px;
  padding: 16px;
  position: fixed;
  left: 25vw;
  background-color: ${props => props.theme.colors.white};
  z-index: 11;
  width: 760px;
  ${media.mobile(`
    left: 0;
    right: 0;
    margin: 0px 16px;
    width: auto
  `)}
`;

export const StyledBackdrop = styled.div`
  position: fixed;
  top: -15vh;
  left: -50vw;
  background: #00000044;
  width: 150vw;
  height: 200vh;
  overflow: hidden;
  z-index: 10;
`;

export const CookiesCard = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d2ccc6;
  margin-bottom: 16px;
  width: calc(100% - 48px);
  padding: 8px;
`;
export default StyledCookieBanner;
