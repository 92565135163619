import React from 'react';
import Header from './Header/Header';
import ServicesList from './ServiceList/ServicesList';

const ServicesPage = () => {
  return (
    <>
      <Header />
      <ServicesList />
    </>
  );
};

export default ServicesPage;
