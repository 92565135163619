import instance from 'API/instance';
import { useCallback, useState } from 'react';

const RATING_REQUEST_INITIAL_STATE = {
  loading: false,
  success: false,
  response: '',
  error: null
};
const RATING_SUBMIT_REQUEST = {
  ...RATING_REQUEST_INITIAL_STATE,
  loading: true
};
const RATING_SUBMIT_SUCCESS = res => ({
  ...RATING_REQUEST_INITIAL_STATE,
  loading: false,
  success: true,
  response: res
});
const RATING_SUBMIT_FAIL = error => ({
  ...RATING_REQUEST_INITIAL_STATE,
  loading: false,
  success: false,
  error: error || true
});

export const useRatings = ({} = {}) => {
  const [ratingRequest, setRatingRequest] = useState(
    RATING_REQUEST_INITIAL_STATE
  );

  const handleRatingSubmit = useCallback((answers, id, cb) => {
    setRatingRequest(RATING_SUBMIT_REQUEST);
    instance
      .post('/client/ratings', {
        rating: {
          appointment_id: id,
          value: answers.rating,
          comment: answers.comment
        }
      })
      .then(res => {
        if (res && res.data) {
          setRatingRequest(RATING_SUBMIT_SUCCESS(res.data || 'success'));
          if (cb?.success) {
            cb.success();
          }
        }
      })
      .catch(e => {
        setRatingRequest(RATING_SUBMIT_FAIL(e.message));
        if (cb?.fail) {
          cb.fail();
        }
      });
  }, []);

  return {
    ratingRequest,
    handleRatingSubmit
  };
};

export default useRatings;
