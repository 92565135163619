import React, { useCallback, useState } from 'react';
import { useTranslate } from '../../../../features/polyglot';
import PropTypes from 'prop-types';
import useAppointments from 'Hooks/useAppointments';
import { Loader, Pagination } from 'ui-55';
import CardRequest from 'Components/CardRequest';


const ProviderAccountRequests = ({ type, user, history }) => {
  const t = useTranslate('accountRequests');
  const [page, setPage] = useState(1);

  const { appointments, loading } = useAppointments({
    type,
    user,
    page
  });
  const filteredAppointments = appointments?.[`${type}-${page}`];

  const totalPages = filteredAppointments?.meta['Total-Pages'];

  const handlePagination = useCallback(
    vals => {
      if (totalPages && vals <= totalPages) {
        setPage(vals);
      }
    },
    [totalPages]
  );



  return loading ? (
    <Loader />
  ) : (
    <>
      {filteredAppointments?.data?.map(appointment => {
        const client = filteredAppointments?.included?.find(
          e =>
            e.type === 'client' &&
            e?.id === appointment?.relationships?.client?.data?.id
        );

        const provider = filteredAppointments?.included?.find(
          e =>
            e.type === 'provider' &&
            e?.id === appointment?.relationships?.provider?.data?.id
        );

        const service = filteredAppointments?.included?.find(
          e =>
            e.type === 'service' &&
            e.id === appointment?.relationships?.service?.data?.id
        );


        return (


          <CardRequest
              key={appointment.id}
              appointment={appointment}
              user={user}
              service={service}
              history={history}
              client={client}
              provider={provider}
              type={type}
            />


        );
      })}

      <Pagination
        key={page}
        totalPages={+totalPages}
        translate={t}
        currentPage={page}
        action={handlePagination}
      />
    </>
  );
};

ProviderAccountRequests.propTypes = {
  userType: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    attributes: PropTypes.object,
    relationships: PropTypes.object
  }),
  history: PropTypes.object
};

export default ProviderAccountRequests;
