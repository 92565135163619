import authClient from 'API/authService';
import useStorage from 'Hooks/useStorage';
import { setLocale } from '../../features/polyglot/polyglotSlice';

const { clearStorage, getStorageItem, setStorageAuthToken } = useStorage();
const STORAGE_AUTH_TOKEN = getStorageItem('AUTH_TOKEN') || {};
const { user, token, tokenDate } = STORAGE_AUTH_TOKEN;

const REDUCER = 'auth';
export const ACTION_TYPES = {
  LOGIN_USER: `${REDUCER}/LOGIN_USER`,
  LOGIN_USER_FAIL: `${REDUCER}/LOGIN_USER_FAIL`,
  LOGIN_USER_SUCCESS: `${REDUCER}/LOGIN_USER_SUCCESS`,
  LOGOUT_USER: `${REDUCER}/LOGOUT_USER`,
  LOGOUT_USER_SUCCESS: `${REDUCER}/LOGOUT_USER_SUCCESS`,
  LOGOUT_USER_FAIL: `${REDUCER}/LOGOUT_USER_FAIL`,
  RESET_USER_PASSWORD: `${REDUCER}/RESET_USER_PASSWORD`,
  RESET_USER_PASSWORD_SUCCESS: `${REDUCER}/RESET_USER_PASSWORD_SUCCESS`,
  RESET_USER_PASSWORD_FAIL: `${REDUCER}/RESET_USER_PASSWORD_FAIL`,
  PATCH_USER_NEW_PASSWORD: `${REDUCER}/PATCH_USER_NEW_PASSWORD`,
  PATCH_USER_NEW_PASSWORD_SUCCESS: `${REDUCER}/PATCH_USER_NEW_PASSWORD_SUCCESS`,
  PATCH_USER_NEW_PASSWORD_FAIL: `${REDUCER}/PATCH_USER_NEW_PASSWORD_FAIL`,
  SIGN_UP_CLIENT: `${REDUCER}/SIGN_UP_CLIENT`,
  SIGN_UP_CLIENT_SUCCESS: `${REDUCER}/SIGN_UP_CLIENT_SUCCESS`,
  SIGN_UP_CLIENT_FAIL: `${REDUCER}/SIGN_UP_CLIENT_FAIL`,
  UPDATE_USER: `${REDUCER}/UPDATE_USER`,
  UPDATE_USER_SUCCESS: `${REDUCER}/UPDATE_USER_SUCCESS`,
  UPDATE_USER_FAIL: `${REDUCER}/UPDATE_USER_FAIL`
};

const loginUser = (dispatch, { userType, email, password, callback }) => {
  dispatch({
    type: ACTION_TYPES.LOGIN_USER
  });
  authClient
    .postLogin({ userType, email, password })
    .then(res => {
      if (res && res.data) {
        if(res?.data?.data?.attributes?.language_code) {
          dispatch(setLocale(res.data.data.attributes.language_code));
        }
        dispatch({
          type: ACTION_TYPES.LOGIN_USER_SUCCESS,
          payload: res.data
        });

        callback();
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.LOGIN_USER_FAIL,
        error: e?.response?.data
      });
    });
};

const logoutUser = dispatch => {
  dispatch({
    type: ACTION_TYPES.LOGOUT_USER
  });

  const auth = getStorageItem('AUTH_TOKEN');
  authClient
    .userLogout(auth?.user?.type)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.LOGOUT_USER_SUCCESS
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.LOGOUT_USER_FAIL,
        error: e?.response
      });
    })
    .finally(() => {
      clearStorage();
    });
};

const resetPassword = (dispatch, payload) => {
  dispatch({ type: ACTION_TYPES.RESET_USER_PASSWORD });
  authClient
    .postResetPassword(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.RESET_USER_PASSWORD_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.RESET_USER_PASSWORD_FAIL,
        errors: e.response.data
      });
    });
};

const patchNewPassword = (dispatch, payload) => {
  dispatch({ type: ACTION_TYPES.PATCH_USER_NEW_PASSWORD, payload });
  authClient
    .patchNewPassword(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.PATCH_USER_NEW_PASSWORD_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.PATCH_USER_NEW_PASSWORD_FAIL,
        errors: e.response.data
      });
    });
};

const signUpClient = (dispatch, payload, callback) => {
  //!TODO all required fields
  dispatch({ type: ACTION_TYPES.SIGN_UP_CLIENT });
  authClient
    .createClientAccount(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.SIGN_UP_CLIENT_SUCCESS,
          payload: res.data
        });
      }
      callback && callback();
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.SIGN_UP_CLIENT_FAIL,
        errors: e?.response?.data?.meta
          ? e?.response?.data?.meta?.message
          : 'Something went wrong'
      });
    });
};

const updateByUserType = (dispatch, userType, payload) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_USER
  });
  authClient
    .updateUser(userType, payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_USER_SUCCESS,
          payload: res.data
        });
        if (user) {
          setStorageAuthToken({
            token,
            tokenDate,
            user: {
              ...user,
              attributes: res?.data?.data?.attributes
            }
          });
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_USER_FAIL,
        error: 'Error updating'
      });
    });
};

export {
  signUpClient,
  loginUser,
  logoutUser,
  resetPassword,
  patchNewPassword,
  updateByUserType
};
