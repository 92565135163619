import styled from 'styled-components';

import { media } from 'Components/Layout';
import theme from 'Theme';
import { Row } from 'ui-55';

export const StyledFormContainer = styled.div`
  button {
    ${props =>
      props.screen === 'createAccount'
        ? `${media.mobile(`margin-top:  120px`)}`
        : null}
    margin: 80px 0;
  }
`;
export const StyledLoginContainer = styled.div`
  background-color: ${props => props.theme.colors.lightBeige};
  display: flex;
  background-color: #f3e9e1;
  width: 100%;
  padding: 80px 0px;
  ${media.mobile(`
  `)}
`;

export const StyledErrorHolder = styled.div`
  background-color: ${props => props.theme.colors.brand.red};
  border-radius: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  justify-content: center;
  p {
    color: ${props => props.theme.colors.white};
  }
`;

export const StyledLinkContainer = styled.div`
  margin-top: ${props => props.top}px;
  margin-bottom: ${props => props.bottom}px;
  text-align: center;
  ${media.mobile(`
    p[name="privacyPolicy"] {
      position: relative;
      bottom: 40px;
    }
  `)}
`;

export const StyledBackButtoContainer = styled.span`
  cursor: pointer;
  position: absolute;
  border: 1px solid ${props => props.theme.colors.brand.yellow};
  border-radius: 22px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTabsRow = styled(Row)`
  button {
    ${media.mobile(`
        padding: 0;
        margin: 16px;
    `)}
  }
`;
export const StyledCard = styled.div`
  max-width: 564px;

  border-radius: 16px;
  padding: 64px;
  box-shadow: 0;
  background-color: #fff;
  color: #171f46;
  justify-content: none;
  margin: 0 auto;

  ${media.mobile(`
    padding: 16px; 
    max-width: 260px;
  `)}

  input {
    ${media.mobile(`min-width: auto; `)}
    min-width 484px;
  }
  .MrKhV > p {
    min-height: 0;
  }
`;

export const StyledCreateAccount = styled.div``;
export const customLinkStyle = {
  color: theme.colors.brand.orange
};
