import styled from 'styled-components';
import { media } from 'Components/Layout';

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0;
  margin-top: 40px;

  ${media.smallDesktop(`
    flex-flow: column;
    margin-bottom: 56px;
  `)}
`;

const Input = styled.input`
  flex: 3;
  border-radius: 48px 0 0 48px;
  border: 1px solid ${props => props.theme.mediumBeige};
  color: ${props => props.theme.colors.grey};
  padding: 0 ${props => props.theme.margin * 1.5}px;
  font-family: Muli;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.grey};
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  ${media.smallDesktop(`
    padding: 16px;
    border-radius: 48px;
    width: 100%;
    margin-bottom: 16px;
  `)}
`;
const Button = styled.button`
  border-radius: 0 48px 48px 0;
  width: 100%;
  outline: none !important;
  background-color: ${props => props.theme.colors.brand.yellow};
  border: none;
  padding: 0 ${props => props.theme.margin * 1}px;
  flex: 1;

  &:hover {
    cursor: pointer;
  }
  > p {
    text-align: center;
  }

  ${media.smallDesktop(`
    border-radius: 48px;
  `)}
`;

export { Input, Button, Container };
