import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme, { TOAST_CONTAINER_STYLE } from 'Theme';
import AppRouter from 'router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCities from 'Hooks/useCities';
import { Icon } from 'ui-55';

const StyledToastContainer = styled(ToastContainer)`
    ${({ theme }) => TOAST_CONTAINER_STYLE(theme)}
`;


function App() {
  const { loaded, dispatchGetCitiesListing } = useCities();

  useEffect(() => {
    if (!loaded) {
      dispatchGetCitiesListing();
    }
  }, [dispatchGetCitiesListing, loaded]);

  return (
    <ThemeProvider theme={theme}>
      <StyledToastContainer
        hideProgressBar={true}
        position='top-center'
        closeOnClick
        closeButton={<Icon name='Error' />}
      />
      {(loaded && <AppRouter />) || ''}
    </ThemeProvider>
  );
}

App.whyDidYouRender = {
  //logOnDifferentValues: true,
  customName: 'App'
};
export default App;
