import React, { useCallback, useState } from 'react';
import { useTranslate } from '../../../../features/polyglot';
import ReactModal from 'react-modal';
import {
  SchedulePicker,
  Row,
  Heading,
  SmallBody,
  Loader,
  Button,
  Icon
} from 'ui-55';

import useWindowMeasures from 'Hooks/useWindowMeasures';
import useAuth from 'Hooks/useAuth';

import {
  StyledPickerContainer,
  StyledContainer,
  StyledButtonsContainer,
  ModalTextNewScheduleText,
  StyledHeaderTextContainer
} from './styles';

const ProviderAccountPreferences = ({ user }) => {
  const t = useTranslate('account');
  const { updateUser, authLoading } = useAuth();

  const initialValues =
    (user?.attributes?.form_answers ?? user?.attributes?.formAnswers) || {};

  const [schedule, setSchedule] = useState(initialValues?.weekHours);
  const [hiddenButtons, setHiddenButtons] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { width } = useWindowMeasures();
  const isMobileRendering = width <= 769;

  const formatNewSchedule = newSchedule => {
    const newScheduleKeys = Object.keys(newSchedule || {});
    const formatedCells = [];
    newScheduleKeys.forEach(key => {
      if (newSchedule[key]?.length > 0) {
        formatedCells.push(
          <React.Fragment key={key}>
            <ModalTextNewScheduleText>{t(`${key}s`)}</ModalTextNewScheduleText>
            <ModalTextNewScheduleText>
              {`${newSchedule[key][0]}:00 - ${
                newSchedule[key][newSchedule[key].length - 1]
              }:00`}
            </ModalTextNewScheduleText>
          </React.Fragment>
        );
      }
    });
    return formatedCells;
  };

  /*** Handlers ************************************/
  const handleSchedule = values => {
    if (initialValues?.weekHours) {
      Object.keys(initialValues?.weekHours).forEach(key => {
        if (initialValues?.weekHours[key]?.length !== values[key]?.length) {
          setHiddenButtons(false);
          setSchedule(values);
        }
      });
    }
  };

  const cancelScheduleChanges = useCallback(() => {
    setHiddenButtons(true);
  }, []);



  return (
    <StyledContainer size={10}>
      {/** Modal *******************************/}
      <ReactModal
        appElement={document.getElementsByTagName('body')[0]}
        style={{
          content: {
            maxWidth: 'min(75vw, 760px)',
            maxHeight: '65vh',
            margin: '10vh auto',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '16px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={openModal}
      >
        {authLoading ? (
          <Loader />
        ) : (
          <>
            <Row justify='flex-end'>
              <span role='presentation' onClick={() => setOpenModal(false)}>
                <Icon name='Close' />
              </span>
            </Row>
            <Row>
              <Heading size={3}>{t('schedulerChange')}</Heading>
            </Row>
            <SmallBody>{t('newSchedule')}</SmallBody>
            {formatNewSchedule(schedule)}
            <Row
              justify='space-between'
              style={{ width: isMobileRendering ? '80%' : '66%' }}

            >
              <Button
                isFullWidth
                btnType='borded'
                text={t('cancel')}
                action={() => setOpenModal(false)}
              />
              <Button
                isFullWidth
                btnType='primary'
                text={t('confirm')}
                action={() => updateUser('provider', { week_hours: schedule })}
              />
            </Row>
          </>
        )}
      </ReactModal>
      {/***********************************************/}
      {authLoading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <StyledHeaderTextContainer>
              <Heading size={6}>{t('hourPreference')}</Heading>
            </StyledHeaderTextContainer>
            {!isMobileRendering && (
              <StyledButtonsContainer hideButtons={hiddenButtons}>
                <Button
                  btnType='borded'
                  text={t('cancel')}
                  action={cancelScheduleChanges}
                />
                <Button
                  btnType='primary'
                  text={t('save')}
                  action={() => setOpenModal(true)}
                />
              </StyledButtonsContainer>
            )}
          </Row>
          <StyledPickerContainer>
            <SchedulePicker
              value={schedule}
              action={vals => handleSchedule(vals)}
            />
          </StyledPickerContainer>
        </>
      )}
      {isMobileRendering && (
        <StyledButtonsContainer hideButtons={hiddenButtons}>
          <Button
            btnType='borded'
            text={t('cancel')}
            action={cancelScheduleChanges}
          />
          <Button
            btnType='primary'
            text={`${t('save')} `}
            action={() => setOpenModal(true)}
          />
        </StyledButtonsContainer>
      )}
    </StyledContainer>
  );
};

export default ProviderAccountPreferences;
