import React from 'react';
import { useTranslate } from '../../features/polyglot';

import { Link } from 'Components/Text';
import { Icon } from 'ui-55';
import Logo from 'Components/Logo';
import Anchor from 'Components/Anchor';
import { GridCol } from 'Components/Layout';
import { useSelector } from "react-redux";
import { selectLocale } from '../../features/polyglot/polyglotSlice';

import {
  FooterContainer,
  StyledFooter,
  BottomMargin,
  TopMargin,
  FooterText,
  CopyrightText,
  FooterBottomLine,
  FooterSocialLine,
  FooterLink
} from './style';

const Footer = () => {
  const locale = useSelector(selectLocale);
  const translateLanguage = useTranslate('languageSelect');
  const t = useTranslate('footer');

  return (
    <FooterContainer>
      <StyledFooter>
        <GridCol size={3}>
          <BottomMargin size={1.5}>
            <Link to='/'>
              <Logo color={'white'} />
            </Link>
          </BottomMargin>
          <BottomMargin size={1.5}>
            <FooterText noMargin={true} style={{fontWeight: 'bold'}}>Lisboa</FooterText>
            <FooterText noMargin={true}>Casa do Impacto</FooterText>
            <FooterText>{t('address')}</FooterText>
            <br />
            <FooterText noMargin={true} style={{fontWeight: 'bold'}}>Norte</FooterText>
            <FooterText>{t('address_porto')}</FooterText>
          </BottomMargin>

          <BottomMargin size={0.5}>
            <FooterText>{t('phone')}</FooterText>
          </BottomMargin>

          <BottomMargin size={2}>
            <FooterLink href={`mailto:${t('email')}`}>{t('email')}</FooterLink>
          </BottomMargin>

          <CopyrightText>{t('copyright')}</CopyrightText>
        </GridCol>

        <GridCol size={3} />

        <GridCol size={3}>
          <TopMargin size={2.5}>
            <BottomMargin size={1}>
              <FooterLink href='/about'>{t('about')}</FooterLink>
            </BottomMargin>

            <BottomMargin size={1}>
              <FooterLink href='/services'>{t('services')}</FooterLink>
            </BottomMargin>

            <BottomMargin size={1}>
              <FooterLink href='/become'>{t('becomeTalent')}</FooterLink>
            </BottomMargin>
          </TopMargin>
        </GridCol>
        <GridCol size={1} />
        <GridCol size={3}>
          <TopMargin size={2.5}>
            <BottomMargin size={1}>
              <FooterLink href='/frequently-asked-questions'>{t('faqs')}</FooterLink>
            </BottomMargin>

            <BottomMargin size={1}>
              <FooterLink href='/terms-and-conditions'>{t('tnc')}</FooterLink>
            </BottomMargin>

            <BottomMargin size={1}>
              <FooterLink href='/terms-of-service'>{t('termsService')}</FooterLink>
            </BottomMargin>

            <BottomMargin size={1}>
              <FooterLink href='/privacy-policy'>{t('pp')}</FooterLink>
            </BottomMargin>

            <BottomMargin size={1}>
              <FooterLink href='/transparency'>{t('transparency')}</FooterLink>
            </BottomMargin>

            <BottomMargin size={4}>

            </BottomMargin>
            <TopMargin>
              <FooterSocialLine>
                <Anchor
                  btnType='transparent'
                  icon='Facebook'
                  href='https://www.facebook.com/55mais'
                  target='_blank'
                />
                <Anchor
                  btnType='transparent'
                  icon='Linkedin'
                  href='https://www.linkedin.com/company/55mais/'
                  target='_blank'
                />
                <Anchor
                  btnType='transparent'
                  icon='Instagram'
                  href='https://www.instagram.com/55mais.pt/'
                  target='_blank'
                />
                <Anchor
                  btnType='transparent'
                  icon='Whatsapp'
                  href='https://api.whatsapp.com/send?phone=351930556575'
                  target='_blank'
                />
              </FooterSocialLine>
            </TopMargin>
          </TopMargin>
        </GridCol>

        <FooterBottomLine />
      </StyledFooter>
    </FooterContainer>
  );
};

export default Footer;
