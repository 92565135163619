import { createSelector } from 'reselect';

const selectCitiesState = state => state.cities;

const getSelectedCity = createSelector(selectCitiesState, state => {
  return state.selectedCity || null;
});
const getCities = createSelector(selectCitiesState, state => {
  return state.cities || [];
});
const getLoading = createSelector(selectCitiesState, state => {
  return state.loading || false;
});
const getLoaded = createSelector(selectCitiesState, state => {
  return state.loaded || false;
});
const getError = createSelector(selectCitiesState, state => {
  return state.error || null;
});

export { getCities, getLoading, getError, getLoaded, getSelectedCity };
