import contentService from 'api/contentService'

const REDUCER = 'content'

export const ACTION_TYPES = {
  GET_CONTENT: `${REDUCER}/GET_CONTENT`,
  GET_CONTENT_FAIL: `${REDUCER}/GET_CONTENT_FAIL`,
  GET_CONTENT_SUCCESS: `${REDUCER}/GET_CONTENT_SUCCESS`
}

const getContent = (dispatch, { id, lang } ) => {
  dispatch({
    type: ACTION_TYPES.GET_CONTENT,
    payload: { id },
  })
  contentService
    .getContentDetail(id, lang)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CONTENT_SUCCESS,
          payload: { data: res.data, id },
        })
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_CONTENT_FAIL,
        payload: { id },
        error: 'Error getting content from the server',
      })
    })
}

export { getContent }
