import instance from './instance';

const getServiceListing = () => {
  return instance.get('client/services');
};

const getServiceForm = ({ id, city }) => {
  return instance.get(`client/services/${id}`, { params: { city_id: city } });
};

const submitServiceForm = payload => {
  return instance.post(`client/leads`, payload);
};

export default { getServiceListing, getServiceForm, submitServiceForm };
