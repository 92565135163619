import React, { useEffect } from 'react';
import { Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import {
  About,
  Account,
  BecomePage,
  Feedback,
  FooterPages,
  FormsTermsAndConditions,
  Home,
  Login,
  Page404,
  Page500,
  ResetPassword,
  ServiceDetail,
  ServicesPage,
  SpecialistForm,
  ThankYouPage,
  Payment
} from 'Pages';

import { RouterPage } from 'Components/Layout';
import Navbar from 'Components/Navbar';
import Footer from 'Components/Footer';

const history = createBrowserHistory();

const pages = {
  // TODO Translate these
  payment: {
    path: '/account/payment',
    title: 'Loading Payment'
  },
  tnc: {
    path: '/terms-and-conditions',
    title: ' - Termos de Utilização do Site'
  },
  feedback: {
    path: '/feedback/:id',
    title: '- Feedback'
  },
  faq: {
    path: '/frequently-asked-questions',
    title: ' - Perguntas Frequentes'
  },
  termsService: {
    path: '/terms-of-service',
    title: ' - Termos de Utilização dos Serviços'
  },
  pp: { path: '/privacy-policy', title: ' - Política de privacidade' },
  transparency: { path: '/transparency', title: ' - Transparency' },
  login: { path: '/login', title: ' - Entrar' },
  services: { path: '/services', title: ' - Serviços' },
  about: { path: '/about', title: ' - Sobre Nós' },
  become: { path: '/become', title: ' - Torne-se num Especialista 55+' },
  serviceDetail: { path: '/services/:id', title: ' - Serviço' },
  thankYouPage: { path: '/thankYou', title: ' - Obrigado pelo seu Pedido.' },
  specialistForm: {
    path: '/specialist-form',
    title: ' - Torne-se num Especialista'
  },
  formsTermsAndConditions: {
    path: '/forms-terms-and-conditions',
    title: ' - Termos e Condições'
  },
  notFound: { path: '/not-found', title: ' - Página não encontrada' },
  serverError: { path: '/server-error', title: ' - Erro do servidor' },
  account: { path: '/account', title: ' - A minha conta' },
  resetProviderPassword: {
    path: '/provider/reset-password',
    title: ' - Repor password'
  },
  resetClientPassword: {
    path: '/client/reset-password',
    title: ' - Repor password'
  },
  home: { path: '/', title: '' }
};

history.listen(location => {
  document.title =
    '55+' +
    (Object.values(pages).find(p =>
      location.pathname.includes(p.path.replace(':id/', ''))
    )?.title || '');
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  ReactPixel.pageView();
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname);
    ReactPixel.pageView();
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// eslint-disable-next-line react/prop-types
const AppRouter = ({ component: Component, path, props, exact }) => {
  return (
    <Route exact={exact} path={path} render={() => <Component {...props} />} />
  );
};

const paths = {};
Object.entries(pages).forEach(([key, page]) => {
  paths[key] = page.path;
});

const routes = [
  { path: paths.payment, component: Payment, exact: true},
  { path: paths.feedback, component: Feedback, exact: true },
  { path: paths.notFound, component: Page404, exact: true },
  { path: paths.serverError, component: Page500, exact: true },
  { path: paths.faq, component: FooterPages.FAQ, exact: true },
  { path: paths.tnc, component: FooterPages.TermsAndConditions, exact: true },
  { path: paths.pp, component: FooterPages.PrivacyPolicy, exact: true },
  { path: paths.transparency, component: FooterPages.Transparency, exact: true },
  {
    path: paths.termsService,
    component: FooterPages.TermsOfService,
    exact: true
  },
  { path: paths.login, component: Login, exact: true },
  { path: paths.services, component: ServicesPage, exact: true },
  { path: paths.about, component: About, exact: true },
  { path: paths.become, component: BecomePage, exact: true },
  { path: paths.serviceDetail, component: ServiceDetail, exact: true },
  { path: paths.thankYouPage, component: ThankYouPage, exact: true },
  {
    path: paths.formsTermsAndConditions,
    component: FormsTermsAndConditions,
    exact: true
  },
  { path: paths.specialistForm, component: SpecialistForm, exact: true },
  { path: paths.account, component: Account },
  { path: paths.resetProviderPassword, component: ResetPassword, exact: true },
  { path: paths.resetClientPassword, component: ResetPassword, exact: true },
  { path: paths.home, component: Home, exact: true }
];

export default function AppRouting() {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Navbar />
      <RouterPage>
        <Switch>
          {routes.map((route, index) => {
            return (
              <AppRouter
                key={route.path}
                path={route.path}
                isPrivate={route.isPrivate}
                component={route.component}
                exact={route.exact}
              />
            );
          })}
          <Route path='*' render={() => <Redirect to='/' />} />
        </Switch>
      </RouterPage>
      <Footer />
    </Router>
  );
}
