import styled from 'styled-components';

const media = {
  mobile: styles => `
    @media only screen and (max-width: 415px) {
      ${styles}
    }
  `,
  tablet: styles => `
    @media only screen and (max-width: 769px) {
      ${styles}
    }
  `,
  smallDesktop: styles => `
    @media only screen and (max-width: 1024px) {
      ${styles}
    }
  `,
  desktop: styles => `
    @media only screen and (min-width: 1025px) {
      ${styles}
    }
  `,
  maxNavbar: styles => `
    @media only screen and (max-width: 1152px) and (min-width: 769px) {
      ${styles}
    }
  `
};

export const getSelectedBackground = props => {
  switch (props.bg) {
    case 'alt':
      return props.theme.colors.lightBeige;
    case 'secondary':
      return props.theme.colors.brand.yellow;
    case 'terceary':
      return props.theme.colors.brand.lightBlue;
    case 'transparent':
      return 'transparent';
    default:
      return 'transparent';
  }
};

const FullPage = styled.div`
  background-color: ${props => getSelectedBackground(props)};
`;

const RouterPage = styled.div`
  ${({ theme }) =>
    media.smallDesktop(`
    overflow-x: hidden;
    margin-bottom: ${({ theme }) => theme.margin * 2}px;
    margin-top: ${theme.margin * 4}px;
  `)}
`;

const Page = styled.div`
  max-width: ${props => props.theme.maxWidth}px;
  ${media.desktop(`
    margin: 0 auto;
  `)}
  ${media.smallDesktop(`
    margin: 0 120px;
  `)}
  ${media.tablet(`
    margin: 0 48px;
  `)}
  ${media.mobile(`
    margin: 0 32px;
  `)}
`;

const Row = styled.div`
  display: flex;
  flex-flow: row ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
  padding: ${props => (props?.padding ?? 0) * props.theme.margin}px;
  background-color: ${props => getSelectedBackground(props)};
  align-items: ${props => (props.align ? props.align : 'none')};
  justify-content: ${props => (props.justify ? props.justify : 'none')};
  min-height: ${props => props.theme.margin * props.size}px;
`;

const Col = styled.div`
  flex: ${props => props.size};
  padding: ${props => (props?.padding ?? 0) * props.theme.margin}px;
  ${props =>
    props.collapse &&
    media[props.collapse](`
  display: none;
  `)}
  ${props =>
    props.src
      ? `
          background-image: url(${props.src});
          background-size: cover;
          background-position: center;
        `
      : ''}
`;

const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${props => getSelectedBackground(props)};
  align-items: ${props => (props.align ? props.align : 'none')};
  justify-content: ${props => (props.justify ? props.justify : 'none')};
  max-width: ${props => props.theme.maxWidth}px;

  ${media.desktop(`
    margin: 0 auto;
  `)}
  ${media.smallDesktop(`
    margin: 0 120px;
  `)}
  ${media.tablet(`
    margin: 0 48px;
  `)}
  ${media.mobile(`
    margin: 0 32px;
  `)}
  ${props =>
    props.collapse &&
    media[props.collapse](`
  display: none;
  `)};
  ${props =>
    props.show &&
    media[props.show](`
  display: initial;
  `)};
`;

const GridCol = styled.div`
  text-align: ${props => (props.text ? 'center' : 'left')};
  width: ${props => `${(100 * props.size) / 12}%`};
  align-self: ${props => (props.self ? 'center' : '')};
  justify-content: ${props => (props.justify ? props.justify : 'none')};
  ${props =>
    props.collapse &&
    media[props.collapse](`
  display: none;
  `)};
  ${props =>
    props.show &&
    media[props.show](`
  display: initial;
  `)};
  ${props =>
    props.src
      ? `
          background-image: url(${props.src});
          background-size: ${props.contain ? 'contain' : 'cover'};
          background-position: center;
          background-repeat: no-repeat;
        `
      : ''}
`;

const Hero = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.theme.heroSize}px;
  background-color: ${props => getSelectedBackground(props)};
  ${props =>
    props.top
      ? `
      position: relative;
      top: -80px;
    `
      : ``}
`;

const ReversedColumn = styled(GridRow)`
  ${media.mobile(`
    flex-flow: column-reverse;
    text-align: center
  `)}
`;

export {
  media,
  Page,
  FullPage,
  Row,
  Col,
  Hero,
  RouterPage,
  GridRow,
  GridCol,
  ReversedColumn
};
