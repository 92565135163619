import styled from 'styled-components';
import theme from 'Theme';
import { Body } from 'Components/Text';
import ReactSelect from "react-select";


export const StyledFilterButton = styled.div`
  background-color: ${props => props.theme.colors.lightestBeige};
  border-radius: 30px;
  display: flex;
  height: 40px;
  width: fit-content;
  align-items: center;
  padding: 0px 15px 0px 10px;
  position: relative;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const OpenDropdown = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Dropdown = styled.div`
    position: absolute;
    top: 40px;
    background-color: white;
    padding: 5px;
    border: solid 1px ${props => props.theme.colors.mediumBeige};
    width: 280px;
    z-index: 1;
    height: ${props => (props.isDate || props.isMulti ? '' : props.length * 48 + 48)}px;
    ${props => (props.isDate ? 'width: fit-content;' : '')}
    ${props => (!props.isMulti ? 'max-height: fit-content;' : '')}
    ${props => (!props.isMulti ? 'max-height: 348px' : '')}
`;

export const DateDropdown = styled.div`
  position: relative;
  position: absolute;
  top: 40px;
  background-color: white;
  padding: 5px;

  z-index: 10;
`;

export const BodyDetail = styled(Body)`
  margin: -4px 8px 0px 0px;
`;

const getIconColor = (filterLabel, theme) => {
  if (filterLabel === 'service') {
    return `fill: ${theme.colors.darkBlue}`;
  } else {
    return `fill: ${theme.colors.grey}`;
  }
};

export const FilterTitle = styled.div`
  display: flex;
  color: ${props => props.theme.colors.grey};
  align-items: center;
  padding: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  path {
    ${props => getIconColor(props.filterLabel, props.theme)}
  }

  span {
    margin-left: 10px;
  }
`;

export const CloseContainer = styled.div`
  border-left: 1px solid ${props => props.theme.colors.mediumBeige};
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-left: 10px;
  cursor: pointer;

  path {
    fill: ${props => props.theme.colors.feedback.error.default};
  }
`;


export const overrideSelect = (isMulti) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      border: 'none',
      marginTop: 3,
      borderRadius: 2,
      boxShadow: 'none',
      position: "unset"
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    input: (provided, { isFocused }) => ({
      ...provided,
      minWidth: '150px'
    }),
    control: (provided, { isFocused, isDisabled, ...state }) => ({
      ...provided,
      borderRadius: 2,
      height: !isMulti ? 48 : "unset",
      minHeight: !isMulti ? "unset" : 48,
      backgroundColor: isDisabled ? theme.colors.lightBeige : theme.colors.white,
      color: theme.colors.grey,
      boxShadow: 'none',
      borderColor: theme.colors.mediumBeige,
      '&:hover': {
        borderColor: theme.colors.mediumBeige
      }
    }),
    option: (provided, state) => {
      const color = state.isDisabled ? theme.colors.grey : theme.colors.darkBlue;
      const backgroundColor = theme.colors.white;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        color,
        transition,
        backgroundColor,
        '&:hover': {
          backgroundColor: theme.colors.lightBeige
        }
      };
    }
  };
}


///StyledSelect, SelectContainer, selectStyles

export const SelectContainer = styled.div`
  ${props => props.isFullWidth && 'width: 100%;'}
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  color: ${props =>
    props.error
      ? props.theme.colors.feedback.error.default
      : props.theme.colors.darkBlue};
  > p {
    margin: ${props => props.theme.margin / 2}px 0;
  }
`;
export const selectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    filter: "drop-shadow(0px 4px 13px rgba(193, 188, 183, 0.3))",
    border: `1px solid ${theme.colors.mediumBeige}`,
    marginTop: 3,
    borderRadius: 2
  }),
  indicatorSeparator: () => ({
    display: "none"
  }),
  input: (provided, { isFocused }) => ({
    ...provided,
    minWidth: "150px",

  }),
  control: (provided, { isFocused, isDisabled, selectType, ...state }) => {
    return {
    ...provided,
    borderRadius: 2,
    height: state.selectProps?.selectType === "team" ? 55 : 48,
    backgroundColor: isDisabled ? theme.colors.lightBeige : theme.colors.white,
    color: theme.colors.grey,
    boxShadow: "none",
    borderColor: isFocused ? theme.colors.darkBlue : theme.colors.mediumBeige,
    "&:hover": {
      borderColor: isFocused ? theme.colors.darkBlue : theme.colors.mediumBeige
    }

    }
  },
  option: (provided, state) => {
    const color = state.isDisabled ? theme.colors.grey : theme.colors.darkBlue;
    const backgroundColor = theme.colors.white;
    const transition = "opacity 300ms";

    return {
      ...provided,
      color,
      transition,
      backgroundColor,
      "&:hover": {
        backgroundColor: theme.colors.lightBeige
      }
    };
  }
};

export const StyledSelect = styled(ReactSelect)`
  color: ${props =>
    props.error
      ? props.theme.colors.feedback.error.default
      : props.theme.colors.mediumBeige};
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  input {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;

