import styled from 'styled-components';
import { media } from 'Components/Layout';

// Provider SET PASSWORD
export const StyledLogoHolder = styled.div`
  text-align: center;
`;

export const StyledResetContainer = styled.div`
  background-color: ${props => props.theme.colors.lightBeige};
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 85px;
  .logo {
    text-align: center;
  }
  ${media.mobile(`    
    width: auto;
    height: 740px;
  `)};
`;

export const StyledHeaderText = styled.div`
  flex-flow: column;
  text-align: center;
  h2 {
    margin-top: 40px;
    margin-bottom: 0;
  }
`;

export const StyledCard = styled.div`
  max-width: 564px;
  max-height: ${props => (props.error ? '549px' : '469px')};
  border-radius: 16px;
  padding: 64px;
  box-shadow: 0;
  background-color: #fff;
  color: #171f46;
  justify-content: none;
  margin: 0 auto;
  ${media.mobile(`padding: 10px; `)}
  input {
    ${media.mobile(`min-width: auto; `)}
    min-width 484px;
  }
`;

export const StyledErrorHolder = styled.div`
  background-color: ${props => props.theme.colors.brand.red};
  border-radius: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 16px 0px;
  justify-content: center;
  p {
    color: ${props => props.theme.colors.white};
  }
`;
