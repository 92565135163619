import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { capitalize } from 'lodash';
import queryString from 'query-string';
import {
  actions as citiesActions,
  selectors as citiesSelectors
} from 'redux/cities';

const useCities = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = queryString.parse(history?.location?.search);
  const defaultCity = capitalize(params.city || 'Lisboa');
  const { getCitiesListing, updateSelectedCity } = citiesActions;
  const {
    getSelectedCity,
    getCities,
    getLoading,
    getError,
    getLoaded
  } = citiesSelectors;

  const cities = useSelector(
    state => getCities(state)?.data || [],
    shallowEqual
  );

  const selectedCity = useSelector(
    state => getSelectedCity(state) || null,
    shallowEqual
  );

  const city = useMemo(() => {
    if (selectedCity) {
      return selectedCity;
    } else {
      if (defaultCity && cities?.length) {
        return cities?.find(
          c => c.attributes.name.toUpperCase() === defaultCity.toUpperCase()
        );
      }
    }
  }, [cities, defaultCity, selectedCity]);

  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const dispatchUpdateCity = useCallback(
    (city, subRoute) => {
      if (city?.label) {
        const newCity = cities.find(c => c?.attributes?.name === city.label);

        if (newCity) {
          updateSelectedCity(dispatch, newCity);
          history.push(
            subRoute || window.location.pathname + '?city=' + city.label
          );
        }
      }
    },
    [cities, dispatch, history, updateSelectedCity]
  );

  const dispatchGetCitiesListing = useCallback(() => {
    getCitiesListing(dispatch);
  }, [dispatch, getCitiesListing]);

  // useEffect(() => {
  //   if (!loaded) {
  //     dispatchGetCitiesListing();
  //   }
  // }, [dispatchGetCitiesListing, loaded]);

  return {
    dispatchGetCitiesListing,
    dispatchUpdateCity,
    cities,
    city,
    error,
    loading,
    loaded
  };
};

export default useCities;
