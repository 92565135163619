import React from 'react';
import PropTypes from 'prop-types';

import { ButtonText } from 'Components/Text';
import { Icon } from 'ui-55';
import StyledAnchor, { IconAnchor } from './style';

const Anchor = ({
  isDisabled,
  btnType,
  text,
  isSmall,
  icon,
  action,
  isFullWidth,
  children,
  type,
  href,
  target
}) => {
  if (text) {
    return (
      <StyledAnchor
        fullWidth={isFullWidth}
        btnType={btnType}
        type={type}
        disabled={isDisabled}
        small={isSmall}
        href={href}
        target={target}
      >
        {children}
        {text && <ButtonText>{text}</ButtonText>}
        {icon && <Icon name={icon} />}
      </StyledAnchor>
    );
  }
  if (icon) {
    return (
      <IconAnchor
        btnType={btnType}
        disabled={isDisabled}
        small={isSmall}
        href={href}
        target={target}
      >
        <Icon name={icon} />
      </IconAnchor>
    );
  }
  return <StyledAnchor />;
};

Anchor.propTypes = {
  isDisabled: PropTypes.bool,
  action: PropTypes.func,
  isSmall: PropTypes.bool,
  type: PropTypes.string,
  href: PropTypes.string,
  isFullWidth: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  btnType: PropTypes.oneOf([
    'primary',
    'secondary',
    'terceary',
    'transparent',
    'borded'
  ]),
  icon: PropTypes.string
};

Anchor.defaultProps = {
  type: 'primary'
};

export default Anchor;
