import { ACTION_TYPES } from './actions'
import { get } from 'lodash'

const initialState = {
  content: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.id]: {
            ...(state.content.content?.[action.payload.id] ?? {}),
            loading: true
          }
        }
      };
    case ACTION_TYPES.GET_CONTENT_FAIL:
      return {
        ...state,
        content: {
          ...state.content,
          [action.payload.id]: {
            ...(state.content.content?.[action.payload.id] ?? {}),
            loading: false,
            loaded: true,
            error: action.error
          }
        }
      };
    case ACTION_TYPES.GET_CONTENT_SUCCESS:
      return {
        ...state,
        content: {
          // ...state.content,
          [action.payload.id]: {
            ...(state.content.content?.[action.payload.id] ?? {}),
            loading: false,
            loaded: true,
            data: get(action, 'payload.data.data'),
          },
        },
      }
    default:
      return state
  }
}
