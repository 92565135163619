import React from 'react';
import { useTranslate } from '../../../features/polyglot';
import { useHistory } from 'react-router-dom';
import { GridRow, GridCol } from 'Components/Layout';
import { Heading } from 'Components/Text';
import Button from 'Components/Button';
import { FAQHeaderContainer } from './styles';

const FAQTab = () => {
  const t = useTranslate('become.FAQ');
  const history = useHistory();
  const handleNavigation = () => history.push('/frequently-asked-questions');

  return (
    <FAQHeaderContainer>
      <GridRow justify='center'>
        <GridCol text='center'>
          <Heading size={2}>{t('title')}</Heading>
          <Button
            isFullWidth
            btnType='borded'
            text={t('button')}
            action={handleNavigation}
          />
        </GridCol>
      </GridRow>
    </FAQHeaderContainer>
  );
};

export default FAQTab;
