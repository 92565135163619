import instance from './instance';

const postProviderForm = data => {
  return instance.post('provider/providers', { provider: data });
};

const updateProviderForm = data => {
  return instance.patch(`/provider/providers/update_form`, {
    provider: data
  });
};

const updateProviderContacts = (data, id) => {
  return instance.patch(`/provider/provider_contacts/${id}`, {
    provider_contact: data
  });
};

export default { postProviderForm, updateProviderForm, updateProviderContacts };
