import instance from './instance';

const postLogin = ({ userType, email, password }) => {
  if (instance.defaults.headers) {
    delete instance.defaults.headers.common['authorization'];
    delete instance.defaults.headers['authorization'];
  }

  return instance.post(`${userType}/sign_in`, {
    [userType]: {
      email,
      password
    }
  });
};

const postResetPassword = ({ userType, email }) => {
  return instance.post(`${userType}/reset_password`, {
    [userType]: {
      email
    }
  });
};

const patchNewPassword = ({ userType, token, password }) => {
  return instance.patch(`${userType}/password`, {
    [userType]: {
      reset_password_token: token,
      password
    }
  });
};

const userLogout = userType => {
  return instance.delete(`${userType}/sign_out`);
};

const createClientAccount = payload => {
  return instance.post(`client/sign_up`, {
    client: payload
  });
};

const updateUser = (userType, payload) => {
  return instance.patch(`${userType}/update_me`, {
    [userType]: payload
  });
};

export default {
  createClientAccount,
  userLogout,
  postLogin,
  postResetPassword,
  patchNewPassword,
  updateUser
};
