import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import appointmentServices from 'api/appointments';

import MultibancoModal from 'Pages/Account/renders/MultibancoModal';

import { Loader } from 'ui-55';

import { PaymentPage } from './styles';

const Payment = () => {
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [renderMB, setRenderMB] = useState(false);

  const handleInvoice = useCallback(
    invoice => {
      const { hosted_invoice_url, payment_flow_type } = invoice;
      if (payment_flow_type === 'invoicing') {
        window.open(hosted_invoice_url);
        history.push('/');
      }
      if (payment_flow_type === 'multibanco') {
        const customMBInvoice = {
          attributes: {
            entity: invoice?.entity,
            reference: invoice?.reference,
            total_price_with_tax: invoice?.total_price_with_tax
          }
        };
        setRenderMB(customMBInvoice);
      }
      setLoading(false);
    },
    [history]
  );

  useEffect(() => {
    if (location?.search) {
      appointmentServices
        .triggerPayment(location.search)
        .then(res => {
          if (res && res.data) {
            const invoice = res?.data?.data?.attributes;
            handleInvoice(invoice);
          }
        })
        .catch(e => {
          const invoice = e?.response?.data?.meta?.invoice;
          if (invoice) {
            handleInvoice(invoice);
          } else {
            setRenderMB(false);
            return history.push('/server-error');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaymentPage>
      {loading ? (
        <Loader />
      ) : (
        <>
          {renderMB && (
            <MultibancoModal
              data={renderMB}
              toggleModal={() => {
                setRenderMB(false);
                history.push('/');
              }}
            />
          )}
          <Loader />
        </>
      )}
    </PaymentPage>
  );
};

export default Payment;
