import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useTranslate } from '../../features/polyglot';
import { resetServiceForm } from 'redux/serviceForm/actions';
import { resetProviderForm } from 'redux/provider/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { GridCol, GridRow } from 'Components/Layout';
import { Heading, Body } from 'Components/Text';
import Button from 'Components/Button';

import { Container, StyledContentHolder } from './styles';

const ThankYouPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const name = location?.state?.name ?? null; //only for specialist Form
  const request = location?.state?.originPage ?? 'serviceRequest';
  const params = queryString.parse(history?.location?.search);

  const t = useTranslate(`thankYouPage.${request}`);

  useEffect(() => {
    resetProviderForm(dispatch);
    resetServiceForm(dispatch);
  }, [dispatch]);

  useEffect(() => {
    ReactGA.event({
      category: 'registo',
      action: 'Formulário Submetido',
      nonInteraction: true
    });
    if (params.isService) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-648392957/vn30CPOb9fUBEP3hlrUC',
        transaction_id: ''
      });
      if (params.service_slug === 'limpezas') {
        window.gtag('event', 'conversion', {
          send_to: 'AW-648392957/7fhPCNvWrPwBEP3hlrUC'
        });
      }
    } else {
      window.gtag('event', 'conversion', {
        send_to: 'AW-648392957/VCvyCOjzwvQBEP3hlrUC'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <GridRow>
          <GridCol size={2} />
          <StyledContentHolder size={10}>
            {name && (
              <Body style={{ marginBottom: '0px' }}>{`${t(
                'namedTitle'
              )}${name}`}</Body>
            )}
            <Heading style={{ marginTop: '8px' }} size={2}>
              {t('title')}
            </Heading>
            <Body>{t('body')}</Body>
            <Button text={t('button')} action={() => history.replace('/')} />
          </StyledContentHolder>
          <GridCol size={2} />
        </GridRow>
      </Container>
    </>
  );
};

export default ThankYouPage;
