import styled from 'styled-components';

import { GridRow, GridCol } from 'Components/Layout';
import { Link } from 'Components/Text';
import { media } from 'Components/Layout';

const Navbar = styled(GridRow)`
  height: 80px;
  display: ${props => (props.hide ? 'none' : 'flex')};
  background-color: ${props => props.theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 10;
  a {
    margin-right: ${props => props.theme.margin * 2}px;
  }
  ${media.smallDesktop(`
    display: none;
  `)}
`;

export const MobileNavbar = styled(GridRow)`
  ${({ openMenu, theme }) =>
    media.smallDesktop(`
    z-index: 10;
    background-color: ${theme.colors.white};
    position: fixed;
    width: 100%;
    top: 0;
    margin: 0;
    margin-right: ${openMenu ? '10px' : '0'};


    padding: ${theme.margin}px;
    padding-bottom: ${theme.margin / 2}px;
    height: ${openMenu ? '100vh' : '50px'};
    display: ${props => (props.hide ? 'none' : 'flex')};
    flex-direction: ${openMenu ? 'column' : 'row'};
    justify-content: space-between;
  `)}

  ${media.desktop(`
    display: none;
  `)}
`;

export const LeftSideNavbar = styled.div`
  flex: 1;
`;

export const RightSideNavbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  button {
    flex: 1;
  }
`;

const LeftSection = styled(GridCol)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${props => props.theme.margin * 1.5}px;
`;
const RightSection = styled(GridCol)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${props => props.theme.margin * 1.5}px;

  a {
    color: ${props => props.theme.colors.brand.orange} !important;
  }
`;

const IconLink = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: ${props => props.theme.margin / 2}px;
  }
  &.city-filter {
    margin-right: 0;
  }
`;

export { Navbar as StyledNavbar, RightSection, LeftSection, IconLink };

export const ServiceCityFilter = styled.div`
  .filter-text {
    margin: 0;
  }
  span {
    margin: 0 !important;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: flex-start;
  > span {
    margin: 28px 0 0 0;
  }
`;

// USER
export const StyledUserContainer = styled.span`
  cursor: pointer;
  display: flex;
  background-color: white;
  > svg {
    margin-left: 8px;
    width: 14px;
  }
`;
