import styled from 'styled-components';
import { media } from 'Components/Layout';
import theme from 'Theme';
import { Link, Body } from 'Components/Text';

const getColorFromStatus = (theme, status) =>
  ({
    casa: theme.muted.blue,
    acompanhamento: theme.muted.green,
    aulas: theme.muted.yellow,
    reparações: theme.muted.blue
  }[status]);

export const NoMarginBody = styled(Body)`
  margin: 0;
`;

export const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${media.tablet(`
    flex-direction: column-reverse;
    background: ${theme.colors.white};

    h1 {
      font-size: 32px;
      text-align: left;
    }
    h2 {
      font-size: 16px;
      text-align: left;
    }
    p {
      font-size: 16px;
      text-align: left;
    }
  `)};
`;

export const BackgroundContainerLeft = styled.div`
  width: 56%;
  height: 870px;
  background: ${props => getColorFromStatus(props.theme.colors, props.bgColor)};
  ${({ collapsed }) =>
    media.tablet(`
    width: 100%;
    height: ${collapsed ? '320px' : 'auto'};
    margin-bottom: 132px;
  `)};
`;

export const BackgroundContainerRight = styled.div`
  width: 44%;
  background-image: ${props => `url(${props.img})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: initial;
  height: 704px;
  ${media.tablet(`
    width: 100%;
    height: 40vh;
  `)};
`;

export const InfoContainer = styled.div`
  position: absolute;
  top: 94px;
  left: 0;
  width: 100vw;
  .service-section {
    width: 50%;
    ${media.tablet(`width: 100%;`)}
  }
  ${({ collapsed }) =>
    media.tablet(`
    overflow: ${collapsed ? 'hidden' : ''};
    text-overflow: ellipsis;
    position: initial;
    width: 100%;
    div > div {
      margin: 0;
      overflow: ${collapsed ? 'hidden' : 'auto'};
    > h1 {
      margin-top: 24px;
      margin-bottom: 0;
    }
    > p {
      margin: 0;
      text-overflow: ellipsis;
      white-space: ${collapsed ? 'nowrap' : 'auto'};
      overflow: ${collapsed ? 'hidden' : 'auto'};
    }
    }
    .vatInfo {
      margin-top: 5px !important;
      margin-bottom: 28px;
    }
  `)};
  .vatInfo {
    margin-top: -10px;
    margin-bottom: 32px;
  }
`;

export const InfoPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${media.tablet(`
    text-align: center;
    width: 100%;
    h2 {
      margin-top: 6px;
      margin-right: 4px;
      margin-bottom: 0;
    }
    p, h1 {
      margin: 0;
    }
  `)}
`;

export const ReadMoreLink = styled(Link)`
  margin-top: 14px;
  margin-bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  color: ${theme.colors.brand.orange};
  &: visited {
    color: ${theme.colors.brand.orange};
  }
`;
