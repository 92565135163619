import { useCallback, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions, selectors } from 'Redux/auth';

const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loginUser,
    logoutUser,
    resetPassword,
    signUpClient,
    updateByUserType
  } = actions;
  const {
    getAuthUser,
    getLoading,
    getError,
    getPwResetSuccess,
    getLoaded
  } = selectors;

  const user = useSelector(state => getAuthUser(state), shallowEqual);
  const userIsRegistered = useMemo(() => Boolean(user?.attributes?.full_name), [
    user
  ]);
  const authError = useSelector(state => getError(state), shallowEqual);
  const authLoading = useSelector(state => getLoading(state), shallowEqual);
  const authLoaded = useSelector(state => getLoaded(state), shallowEqual);
  const resetHadSuccess = useSelector(
    state => getPwResetSuccess(state),
    shallowEqual
  );
  // const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const dispatchLoginUser = useCallback(
    (userType, email, password, callback) => {
      loginUser(dispatch, { userType, email, password, callback });
    },
    [dispatch, loginUser]
  );

  const dispatchLogoutUser = useCallback(
    (email, password, callback) => {
      logoutUser(dispatch, { email, password, callback });
    },
    [dispatch, logoutUser]
  );

  const dispatchResetUserPassword = useCallback(
    (userType, dispatch, payload) => {
      resetPassword(dispatch, { userType, email: payload });
    },
    [resetPassword]
  );

  const dispatchClientSignUp = useCallback(
    (payload, callback) => {
      signUpClient(dispatch, payload, callback);
    },
    [dispatch, signUpClient]
  );
  const redirectTo = useCallback(() => history.push('/'), [history]);

  const clientSignUp = useCallback(
    async (payload, redirect) => {
      dispatchClientSignUp(payload, !redirect ? redirectTo : null);
    },
    [dispatchClientSignUp, redirectTo]
  );

  const signInAndRedirect = useCallback(
    async (userType, email, pw, redirect) => {
      if (email && pw) {
        dispatchLoginUser(userType, email, pw, !redirect ? redirectTo : null);
      }
    },
    [dispatchLoginUser, redirectTo]
  );
  const gotoSignInPage = useCallback(() => history.push('/login'), [history]);

  const dispatchUpdateUser = useCallback(
    (userType, payload) => {
      updateByUserType(dispatch, userType, payload);
    },
    [dispatch, updateByUserType]
  );
  /* useEffect(() => {
    if (!user) {
      clearStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchLogoutUser, gotoSignInPage, user]); */

  return {
    user,
    userIsRegistered,
    authError,
    authLoaded,
    authLoading,
    redirectTo,
    signInAndRedirect,
    clientSignUp,
    dispatchLogoutUser,
    resetPassword: dispatchResetUserPassword,
    updateUser: dispatchUpdateUser,
    gotoSignInPage,
    resetHadSuccess
  };
};

export default useAuth;
