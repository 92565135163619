import React, { useState } from 'react';

import { useTranslate } from '../../features/polyglot';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Icon } from 'ui-55';

import { NavLink } from 'Components/Text';
import Logo from 'Components/Logo';
import Button from 'Components/Button';
import BurgerButton from 'Components/BurgerButton';
import NavbarMenu from 'Components/NavbarMenu';
import CityFilter from 'Components/CityFilter';
import DropDownMenu from 'Components/DropDownMenu';

import useAuth from 'Hooks/useAuth';
import useWindowMeasures from 'Hooks/useWindowMeasures';

import {
  StyledNavbar,
  RightSection,
  LeftSection,
  LeftSideNavbar,
  RightSideNavbar,
  MobileNavbar,
  StyledUserContainer
} from './style';
import { LanguageSelect } from 'features/languages/components/LanguageSelect';


const Navbar = props => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const routeIsSpecialistForm = pathname === '/specialist-form' || false;
  const { user, dispatchLogoutUser } = useAuth();
  const { width } = useWindowMeasures();

  const [openMenu, setOpenMenu] = useState(false);
  const [openUserMenu, setopenUserMenu] = useState(false);
  const t = useTranslate('nav');
  const userDropDownOptions = [
    {
      label: t('myAccount'),
      action: () => history.push('/account'),
      hasIcon: { name: 'User' }
    },
    {
      label: t('settings'),
      action: () => history.push('/account/details'),
      hasIcon: { name: 'Settings' }
    },
    {
      label: t('help'),
      action: () => (window.location.href = 'mailto:geral@55mais.pt'),
      hasIcon: { name: 'help-circle' }
    },
    {
      label: t('exit'),
      action: () => {
        dispatchLogoutUser();
        history.replace('/');
      }
    }
  ];

  const accountRender = user => {
    return (
      <>
        <StyledUserContainer
          onClick={() => setopenUserMenu(!openUserMenu)}
          align='center'
        >
          <Avatar size='small' user={user?.attributes} hasText={width > 767} />
          {width > 767 && (
            <Icon
              style={{ alignSelf: 'center' }}
              name={openUserMenu ? 'chevron-up' : 'chevron-down'}
            />
          )}
          <DropDownMenu
            isOpen={openUserMenu}
            onClose={() => setopenUserMenu(false)}
            menuOptions={userDropDownOptions}
          />
        </StyledUserContainer>
      </>
    );
  };

  return (
    <>
      <StyledNavbar>
        <LeftSection size={8}>
          <NavLink to='/'>
            <Logo />
          </NavLink>

          <NavLink to='/services' activeClassName='link-selected'>
            {t('services')}
          </NavLink>

          <NavLink to='/about' activeClassName='link-selected'>
            {t('aboutUs')}
          </NavLink>
          <LanguageSelect />



        </LeftSection>
        {!routeIsSpecialistForm && <CityFilter />}
        <RightSection size={4}>

          {!routeIsSpecialistForm && user ? (
            accountRender(user)
          ) : (
            <>
              <NavLink to='/become' activeClassName='link-selected'>
                {t('becomeTalent')}
              </NavLink>
              {/* <NavLink to='/register'>{t('registerAs')}</NavLink> */}
              <Button
                text={t('enter')}
                action={() => {
                  history.push('/login');
                }}
              />
            </>
          )}
        </RightSection>
      </StyledNavbar>

      <MobileNavbar className={'navbar'}>
        <LeftSideNavbar>
          <NavLink to='/'>
            <Logo />
          </NavLink>
        </LeftSideNavbar>

        <RightSideNavbar>
          {user ? (
            accountRender(user)
          ) : (
            <Button
              btnType='primary'
              text={t('loginNavbarButton')}
              action={() => {
                history.push('/login');
              }}
            />
          )}
          <BurgerButton openMenu={openMenu} setOpenMenu={setOpenMenu} />
          <NavbarMenu isMenuOpen={openMenu} setCloseMenu={setOpenMenu} />
        </RightSideNavbar>
      </MobileNavbar>
    </>
  );
};

export default Navbar;
