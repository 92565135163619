import servicesClient from "api/servicesClient";

const REDUCER = "services";

export const ACTION_TYPES = {
  GET_SERVICES_LISTING: `${REDUCER}/GET_SERVICES_LISTING`,
  GET_SERVICES_LISTING_FAIL: `${REDUCER}/GET_SERVICES_LISTING_FAIL`,
  GET_SERVICES_LISTING_SUCCESS: `${REDUCER}/GET_SERVICES_LISTING_SUCCESS`
};

const getServicesListing = dispatch => {
  dispatch({
    type: ACTION_TYPES.GET_SERVICES_LISTING
  });
  servicesClient
    .getServiceListing()
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_SERVICES_LISTING_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_SERVICES_LISTING_FAIL,
        error: "Error getting services from the server"
      });
    });
};

export { getServicesListing };
