export const serviceList = [{ notFound: true }];

export const tabs = [
  {
    label: 'todos',
    name: 'Todos'
  },
  {
    label: 'acompanhamento',
    name: 'Acompanhamento'
  },
  {
    label: 'casa',
    name: 'Casa'
  },
  {
    label: 'aulas',
    name: 'Aulas'
  },
  {
    label: 'reparações',
    name: 'Reparações'
  }
];
