import styled from 'styled-components';
import { media } from 'ui-55';

export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  transition: all 0.3s;
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: ${props =>
    props.isOpen ? '0px 4px 13px rgba(193, 188, 183, 0.3);' : 'none'};
  border: ${props =>
    props.isOpen ? '1px solid #f8f7f7' : '0px solid #f8f7f7'};
  height: ${props => (props.isOpen ? 'auto' : '0px')};
  width: fit-content;
  ${media.smallDesktop(`
    right: 45px;
  `)}
`;

export const ListContainer = styled.div`
padding: 5px;
display: ${props => (!props.isOpen ? 'none' : 'inline-block')};
opacity: ${props => (!props.isOpen ? 0 : 1)};
align-items: center;

svg,
button {
  margin: 0px;
  padding: 5px;
}

span {
  color: ${props => props.theme.colors.darkBlue};
}
`;

export const ClickOutsideContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  background-color: ${props => props.theme.colors.darkBlue};
  opacity: 0;
`;
