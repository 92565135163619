import React from 'react';

import { AboutHeaderGrid, AboutHeaderHero } from './styles';
const Header = ({ content }) => {
  return (
    <AboutHeaderHero top>
      <AboutHeaderGrid justify='space-between'>{content}</AboutHeaderGrid>
    </AboutHeaderHero>
  );
};

export default Header;
