import { ACTION_TYPES } from './actions';

const initialState = {
  isSubmitting: false,
  wasSuccessful: false,
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.POST_PROVIDER_FORM:
      return {
        ...state,
        isSubmitting: true
      };
    case ACTION_TYPES.POST_PROVIDER_SUCCESSFUL:
      return {
        ...state,
        isSubmitting: false,
        wasSuccessful: true
      };
    case ACTION_TYPES.POST_PROVIDER_FAILED:
      return {
        ...state,
        isSubmitting: false,
        wasSuccessful: false,
        errors: action.errors
      };
    case ACTION_TYPES.UPDATE_PROVIDER_FORM:
      return {
        ...state,
        isSubmitting: true
      };
    case ACTION_TYPES.UPDATE_PROVIDER_FORM_SUCESSFUL:
      return {
        ...state,
        isSubmitting: false,
        wasSuccessful: true
      };
    case ACTION_TYPES.UPDATE_PROVIDER_FORM_FAILED:
      return {
        ...state,
        isSubmitting: false,
        wasSuccessful: false,
        errors: action.errors
      };
    case ACTION_TYPES.UPDATE_PROVIDER_CONTACTS:
      return {
        ...state,
        isSubmitting: true
      };
    case ACTION_TYPES.UPDATE_PROVIDER_CONTACTS_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        wasSuccessful: true
      };
    case ACTION_TYPES.UPDATE_PROVIDER_CONTACTS_FAILED:
      return {
        ...state,
        isSubmitting: false,
        wasSuccessful: false,
        error: action?.error
      };
    case ACTION_TYPES.RESET_PROVIDER_FORM:
      return initialState;
    default:
      return state;
  }
};
