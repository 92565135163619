import styled from 'styled-components';
import { Row, Col, media } from 'Components/Layout';
import { Heading, Body } from 'Components/Text';

export const ServiceListContainer = styled(Row)`
  padding-bottom: ${props => props.theme.margin * 5}px;
  justify-content: flex-start;
  > a {
    ${media.tablet(` margin: 6px;`)};
    ${media.mobile(` margin: 16px 0px;`)};
    margin-top: ${props => props.theme.margin / 2}px;
  }
`;

export const ServiceCityFilter = styled.div`
  .filter-text {
    margin: 0;
  }
  span {
    margin: 0 !important;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FilterContainer = styled(Row)`
  padding-top: ${props => props.theme.margin * 3}px;
  justify-content: flex-start !important;
  button {
    margin: 0 ${props => props.theme.margin / 2}px;
  }
  ${({ theme }) =>
    media.mobile(`
    flex-flow: row wrap;
    align-items: center;
    > div {
      width: 100%;
      margin-bottom: ${theme.margin}px;
      > div {
        margin-right: ${theme.margin}px;
        margin-bottom: ${theme.margin}px;
        width: 100%;
      }
      > svg {
        margin: -20px 8px;
      }
    }
`)}
  align-items: center;
  margin: 24px auto;
  justify-content: space-between;
`;

export const SubFilterRow = styled(Row)`
  justify-content: space-between;
  flex: 3;
  .service-list-filter__indicator {
    margin-right: 5px;
    margin-top: 5px;
  }
  > * {
    max-width: 172px;
  }
  ${({ theme }) =>
    media.mobile(`
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    > * {
      max-width: 48%;
    }
`)}
`;

const serviceCardWidth = {
  smallDesktop: 245,
  tablet: 320,
  default: 373.3
};

export const ServiceCard = styled.div`
  ${media.smallDesktop(
    `width: ${serviceCardWidth.smallDesktop}px; margin-right:6px`
  )}
  ${media.tablet(`width:${serviceCardWidth.tablet}px; margin-right:0px;`)}
  ${media.mobile(`width: 100%; margin-right:0px;`)};
  width: ${serviceCardWidth.default}px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: ${props => props.theme.margin}px;
  text-decoration: none;
  margin-right: ${card =>
    (card.index + 1) % 3 === 0 ? 0 : card.theme.margin}px;
`;

export const SearchInputWrapper = styled(Col)`
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  align-items: center;
  margin-right: ${props => props.theme.margin * 1.5}px;
  ${({ theme }) =>
    media.mobile(`
      margin-right: 0;
    `)}
  > svg {
    margin: -10px ${props => props.theme.margin / 2}px;
    vertical-align: middle;
  }
  > div > input {
    ${media.smallDesktop(`width: auto`)};
    width: 270px;
    text-indent: 20px;
    padding: ${props => props.theme.margin - 5}px;
  }
`;

export const NotFoundServiceCard = styled(ServiceCard)`
  > div {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: ${props =>
      `${props.theme.margin * 3.5}px ${props.theme.margin * 1.5}px`};
    background-color: ${props => props.theme.colors.brand.orange};
    > a {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 60%;
    }
    > div.top-half {
      > h2 {
        margin: 0 auto;
        color: ${props => props.theme.colors.white};
        margin-bottom: 32px;
      }
      > p {
        margin: 0 auto;
        color: ${props => props.theme.colors.white};
        margin-bottom: ${props => `${props.theme.margin}px`};
      }
    }
  }
`;

export const PropertyImage = styled.div`
  background-color: ${props => props.theme.colors.white};
  ${media.smallDesktop(`width: ${serviceCardWidth.smallDesktop}px;`)}
  ${media.tablet(`width:${serviceCardWidth.tablet}px;`)}
  ${media.mobile(`width: 100%;`)}
  width: ${serviceCardWidth.default}px;

  ${media.smallDesktop(`height: ${serviceCardWidth.smallDesktop}px;`)}
  ${media.tablet(`height:${serviceCardWidth.tablet}px;`)}
  height: ${serviceCardWidth.default}px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
`;

export const PropertyDescription = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  text-align: flex-start;
  padding: 0 ${props => props.theme.margin}px;
  > p {
    height: ${props => props.theme.margin * 9.5}px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  ${media.tablet(`border-radius: 0px;`)};
`;

export const ServiceHeading = styled(Heading)`
  margin: 22px 0 0 0;
`;

export const ServiceBody = styled(Body)`
  margin-top: 5px;
`;

export const NotFoundBody = styled(Body)`
  margin-top: 32px;
  height: unset;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: flex-start;
  > span {
    margin: 28px 0 0 0;
  }
`;
