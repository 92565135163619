import { getInvoices, getInvoicesByAppointment } from 'API/invoices';
const REDUCER = 'invoices';

export const ACTION_TYPES = {
  GET_USER_INVOICES: `${REDUCER}/GET_USER_INVOICES`,
  GET_USER_INVOICES_SUCCESS: `${REDUCER}/GET_USER_INVOICES_SUCCESS`,
  GET_USER_INVOICES_FAILED: `${REDUCER}/GET_USER_INVOICES_FAILED`,
  GET_INVOICES_BY_APPOINTMENT: `${REDUCER}/GET_INVOICES_BY_APPOINTMENT`,
  GET_INVOICES_BY_APPOINTMENT_SUCCESS: `${REDUCER}/GET_INVOICES_BY_APPOINTMENT_SUCCESS`,
  GET_INVOICES_BY_APPOINTMENT_FAILED: `${REDUCER}/GET_INVOICES_BY_APPOINTMENT_FAILED`
};

const getUserInvoices = (dispatch, userType) => {
  dispatch({ type: ACTION_TYPES.GET_USER_INVOICES });
  getInvoices(userType)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_USER_INVOICES_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_USER_INVOICES_FAILED,
        error: e?.response
      });
    });
};

const getByAppointment = (dispatch, appointmentId) => {
  dispatch({
    type: ACTION_TYPES.GET_INVOICES_BY_APPOINTMENT
  });
  getInvoicesByAppointment(appointmentId)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_INVOICES_BY_APPOINTMENT_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_INVOICES_BY_APPOINTMENT_FAILED,
        error: e?.response
      });
    });
};
export { getUserInvoices, getByAppointment };
