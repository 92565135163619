import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslate } from '../../../features/polyglot';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import kc from 'lodash.kebabcase';
import { useDispatch, useSelector } from 'react-redux';
import { submitServiceForm } from 'Redux/serviceForm/actions';
import { Body, ErrorText, Heading, SmallBody } from 'Components/Text';
import Button from 'Components/Button';
import { GridCol, GridRow } from 'Components/Layout';
import Loader from 'Components/Loader';
import List from 'Components/List';
import Form from 'Components/UIForm';
import { CheckBoxGroup } from 'ui-55';
import {
  ErrorMessageHolder,
  FormContainer,
  GreyHeading,
  InfoContainer,
  TermsAndConditionsContainer,
  WhatToExpect
} from './styles';

import useCities from 'Hooks/useCities';
import usePolymorph from 'Hooks/usePolymorph';
import useAuth from 'Hooks/useAuth';
import { useWindowSizes } from 'Hooks/useWindowMeasures';
import { toast } from 'react-toastify';
import CityFilterService from 'Components/CityFilterService';
import { selectLocale } from '../../../features/polyglot/polyglotSlice';

const ServiceDetailForm = ({ service, loaded }) => {
  const locale = useSelector(selectLocale);
  const [questionsTranslated, setQuestionsTranslated] = useState({});
  const dispatch = useDispatch();
  const f = useTranslate('formsTermsAndConditions');
  const transResponse = useTranslate('responses');
  const translate = useTranslate('servicesPage');
  const translateForm = useTranslate('serviceForm');
  const translateLabels = useTranslate('serviceForm.labels');
  const translateFAQ = useTranslate('formDetails.FAQ');
  const history = useHistory();
  const messageRef = useRef();
  const { city } = useCities();

  const { isTablet } = useWindowSizes();
  const [checkBoxError, setCheckBoxError] = useState(false);
  const [answers, setAnswers] = useState({
    district: kc(city?.attributes?.name)
  });

  const [submitObj, setSubmitObj] = useState({});
  const {
    signInAndRedirect,
    clientSignUp,
    authError,
    authLoaded,
    authLoading,
    user
  } = useAuth();

  useEffect(() => {
    setAnswers({ district: kc(city?.attributes?.name) });
  }, [city.attributes.name]);

  useEffect(() => {
    const updatedCheckedTerms = checkedTerms.map(term => {
      if (term.key === 'terms') {
        return {
          ...term,
          question: `${f('checkboxText') + ' <a href="/terms-and-conditions" target="_blank">' + f('checkboxTextTermsAndCondition') + '</a> ' + f('checkboxTextAnd') + ' <a href="/privacy-policy" target="_blank"> ' + f('checkboxTextPrivacyPolicy') + '</a>'}`
        };
      } else if (term.key === 'newsletter') {
        return {
          ...term,
          question: f('acept')
        };
      }
      return term;
    });

    setCheckedTerms(updatedCheckedTerms);
  }, [f, checkedTerms]);


  const [checkedTerms, setCheckedTerms] = useState([
    {
      key: 'terms',
      question: `${f('checkboxText') + ' <a href="/terms-and-conditions" target="_blank">' + f('checkboxTextTermsAndCondition') + '</a> ' + f('checkboxTextAnd') + ' <a href="/privacy-policy" target="_blank"> ' + f('checkboxTextPrivacyPolicy') + '</a>'}`,
      isSelected: false
    },
    {
      key: 'newsletter',
      question: f('acept'),
      isSelected: false
    }
  ]);

  const id = useMemo(() => service.data?.id, [service]);

  const additionalInfo = useMemo(() => service.included[0]?.attributes, [
    service
  ]);
  const questions = useMemo(() => additionalInfo?.questions, [additionalInfo]);
  const whatToExpectList = useMemo(() => additionalInfo?.what_to_expect, [
    additionalInfo
  ]);
  const howItWorksList = useMemo(() => additionalInfo?.how_it_works, [
    additionalInfo
  ]);

  let { wasSuccessful, isSubmitting } = useSelector(
    state => state.serviceForm
  );

  useEffect(() => {
    if (authLoaded) {
      if (authError) {
        if (authError?.email?.[0] === 'asBeenTaken') {
          toast.error(transResponse('asBeenTaken'));
        } else if (authError) {
          toast.error(authError);
        }
      } else {
        if (Object.keys(submitObj).length > 0) {
          submitServiceForm(dispatch, submitObj);
        }
      }
    }
  }, [authError, authLoaded, dispatch, submitObj, transResponse]);

  const userAuthentication = useCallback(
    (payload, submitObj) => {
      const password = payload?.password;
      const email = payload?.email;
      if (payload['login-buttons'] === 'guest') {
        delete submitObj.lead.form_answers.login;
        return submitServiceForm(dispatch, submitObj);
      }
      if (payload['login-buttons'] === 'login') {
        delete submitObj.lead.form_answers.guest;
        signInAndRedirect('client', email, password, true);
        setSubmitObj(submitObj);
        if (user && user?.attributes?.email) {
          submitServiceForm(dispatch, submitObj);
        }
      }
      if (payload['login-buttons'] === 'signup') {
        clientSignUp(
          {
            email: payload?.email,
            password: payload?.password,
            full_name: payload?.['first-name'] + ' ' + payload?.['last-name'],
            phone_number: payload?.['telephone'],
            nif: payload?.['nif'] || '',
            language_code: locale,
            client_type: payload['company-status']
          },
          true
        );
        setSubmitObj(submitObj);
      }
    },
    [clientSignUp, dispatch, signInAndRedirect]
  );

  useEffect(() => {
    if (wasSuccessful) {
      history.push(
        '/thankYou?isService=true&service_slug=' +
        kc(service?.data?.attributes?.name)
      );
    }
  }, [history, isSubmitting, transResponse, wasSuccessful, service]);

  const handleServiceSubmit = useCallback(
    payload => {
      if (isSubmitting || wasSuccessful) return; // don't want to handle more requests if one request was already successful

      isSubmitting = true;
      const newPayload = { ...payload };
      const serviceType = payload['service-type'];
      if (payload && !payload?.['is-urgent']) {
        newPayload['is-urgent'] = false;
      }

      if (user?.attributes?.email) {
        newPayload.email = user.attributes?.email;
        newPayload.login = { email: user.attributes?.email };
        newPayload['login-buttons'] = 'login';
      }
      if (user?.attributes?.fullName) {
        const nameSplit = user.attributes?.fullName?.split?.(' ');
        newPayload['first-name'] = nameSplit?.[0];
        newPayload['last-name'] = nameSplit?.[1];
      }

      const submitObj = {
        lead: {
          is_urgent: payload?.['is-urgent'],
          accepted_terms: checkedTerms[0]?.isSelected,
          accepted_newsletter: checkedTerms[1]?.isSelected,
          city_id: city.id,
          service_id: id,
          service_type: serviceType || 0,
          form_answers: newPayload
        }
      };
      if (!submitObj.lead.accepted_terms) {
        return setCheckBoxError(true);
      }

      userAuthentication(newPayload, submitObj);

    },
    [user, checkedTerms, city.id, id, userAuthentication, isSubmitting]
  );

  const handleCheckBoxSelection = vals => {
    setCheckedTerms(vals);
    setCheckBoxError(false);
  };
  const hiddenFields = ['district'];
  if (user && user?.attributes?.email && user?.type === 'client') {
    questions.questions.forEach(q => {
      if (q.key === 'login-buttons') {
        q.value = '';
      }
    });
    hiddenFields.push('login-buttons');
  }


  useEffect(() => {
    const makeTranslate = (label) => {
      return (label === ""
        || label === " "
        || label === undefined
        || label === null) ?
        "" : translateLabels(label);
    }
    const updatedQuestions = {
      ...questions,
      questions: questions.questions.filter(
        (question) => question.key !== "chosen-language"
      ).map(question => ({
        ...question,
        urgentPrices: {
          ...question.urgentPrices,
          body: translateLabels(question?.urgentPrices?.body),
          extras: translateLabels(question?.urgentPrices?.extras),
          headerText: translateLabels(question?.urgentPrices?.headerText)
         },

        placeholder: ((question.options !== null && question.options !== undefined && question.key !== "cloth-type") || question.widget === "offer-type") ? translateLabels("Selecionar") : null,
        children: question.children?.map((child) => {
          return {
            ...child,
            placeholder: child.key === 'meat-dish' ? translateLabels("Selecionar") : child?.placeholder,
            label: makeTranslate(child.label),
            extras: translateLabels(child.extras),
            body: translateLabels(child.body),
            options: child.options?.map((option) => {
              return { ...option, label: makeTranslate(option.label) };
            }),
          };
        }),
        options: question.options?.map((option) => {
          return { ...option,
            placeholder: translateLabels("Selecionar"),
            label: makeTranslate(option.label) };
        }),
        label: makeTranslate(question.label)
      }))
    };
    setQuestionsTranslated(updatedQuestions);
  }, [locale]);

  const formRef = useRef();
  const serviceForm = useCallback(
    () =>
      answers.district === kc(city?.attributes?.name) && (
        <GridCol size={isTablet ? 12 : 6}>
          {questions && loaded ? (
            <FormContainer ref={formRef}>

              <CityFilterService />

              <Form
                isDisabled={isSubmitting || wasSuccessful}
                btnLabel={translate('requestService')}
                hiddenFields={hiddenFields}
                bg='alt'
                answers={answers}
                onError={err => console.error(err)}
                formFields={questionsTranslated}
                onSubmit={values => handleServiceSubmit(values)}
              >
                <Heading size={isTablet ? 12 : 3}>
                  {translate('requestService')}
                </Heading>
                <Body>{translate('requestHeader')}</Body>
              </Form>
              <TermsAndConditionsContainer>
                <SmallBody
                  style={{ margin: '15px' }}
                  dangerouslySetInnerHTML={{ __html: f('services') + ' <a href="/privacy-policy" target="_blank">' + f('servicesPolicyPrivacy') + '</a>.' }}
                ></SmallBody>
                <CheckBoxGroup
                  action={vals => handleCheckBoxSelection(vals)}
                  list={checkedTerms}
                />
                <ErrorMessageHolder>
                  {checkBoxError && (
                    <ErrorText ref={messageRef}>* {f('error')}</ErrorText>
                  )}
                </ErrorMessageHolder>
              </TermsAndConditionsContainer>
              <InfoContainer justify='center'>
                <GridCol text='center'>
                  <Heading bottom='0' align='center' size={2}>
                    {translateFAQ('title')}
                  </Heading>
                  <Button
                    isFullWidth
                    btnType='borded'
                    text={translateFAQ('button')}
                    action={() => history.push('/frequently-asked-questions')}
                  />
                </GridCol>
              </InfoContainer>
            </FormContainer>
          ) : (
            <Loader />
          )}
        </GridCol>
      ),
    [
      answers,
      authLoading,
      checkBoxError,
      checkedTerms,
      city.attributes.name,
      f,
      handleServiceSubmit,
      history,
      isSubmitting,
      isTablet,
      loaded,
      questions
    ]
  );

  const pageElements = {
    'service-what-to-expect': ({ listContent, title }) => (
      <List hasFullWidthLi data={listContent}>
        <Heading size={3}>{title}</Heading>
      </List>
    ),
    'service-how-it-works': ({ listContent, title, description }) => (
      <List
        hasFullWidthLi
        padding='20'
        justify='flex-start'
        hasIcon
        isBorded
        data={listContent}
      >
        <GridCol>
          <GreyHeading size={isTablet ? 12 : 6}>{title}</GreyHeading>
          <Heading size={2}>{description}</Heading>
        </GridCol>
      </List>
    ),
    title: () => <></>
  };

  const extractor = {
    'service-what-to-expect': (props, element, renderer) => {
      props.title = translateForm('what_to_expect.title');
      props.listContent = getElementsByName(element, 'what-to-expect-list')
        ?.sort((e, f) => e.order - f.order)
        .map(el => el?.elementable?.value);
    },
    'service-how-it-works': (props, element) => {
      props.title = translateForm('service-how-it-works');
      props.description = translateForm('how-it-works-description');

      props.listContent = getElementsByName(element, 'how-it-works-list')
        ?.sort((e, f) => e.order - f.order)
        .map(el => el?.elementable?.value);
    }
  };

  const {
    parsedContent,
    getElementValueByName,
    getElementsByName
  } = usePolymorph({
    id: service?.data?.attributes?.content_key,
    pageElements,
    extractor,
    skipFetch: true
  });

  const whatToExpect = () => (
    <GridCol size={isTablet ? 10 : 4} className='what-to-expect'>
      {additionalInfo && (
        <WhatToExpect>
          {(whatToExpectList && parsedContent['service-what-to-expect']) || ''}
          {(howItWorksList && parsedContent['service-how-it-works']) || ''}
        </WhatToExpect>
      )}
    </GridCol>
  );

  return additionalInfo ? (
    isTablet ? (
      <GridCol justify='center' className={'detailbottom'}>
        {whatToExpect()}
        {serviceForm()}
        <GridCol size={0} />
      </GridCol>
    ) : (
      <GridRow>
        {serviceForm()}
        <GridCol size={2} />
        {whatToExpect()}
      </GridRow>
    )
  ) : (
    <p>loading bottom</p>
  );
};

ServiceDetailForm.propTypes = {
  service: PropTypes.object
};
export default ServiceDetailForm;
