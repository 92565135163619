import React from 'react';
import { RightContainer } from './styles';
import { Heading, Body } from 'Components/Text';
import { useTranslate } from '../../features/polyglot';

import { SubTitlesContainer } from './styles';

const TermsOfServicePage = () => {
  const t = useTranslate('footerPages.TermsOfService');

  return (
    <RightContainer>
      <Heading size={1} className='title'>
        {t('title')}
      </Heading>
      <Heading style={{ margin: 0 }} size={3} className='description'>
        {t('firstSection.title')}
      </Heading>
      <Body>{t('firstSection.firstText')}</Body>
      <Body
        dangerouslySetInnerHTML={{ __html: t('firstSection.secondText')+' <a target="_blank" href="/termsAndConditions">'+t('firstSection.secondTextHere')+'</a> '+t('firstSection.secondText2') }}
      />
      <Body>{t('firstSection.thirdText')}</Body>
      <SubTitlesContainer>
        <Heading size={3}>{t('secondSection.title')}</Heading>
        <Body>{t('secondSection.firstText')}</Body>
        <Body>{t('secondSection.secondText')}</Body>
        <Body>{t('secondSection.thirdText')}</Body>
        <Body>{t('secondSection.fourthText')}</Body>

        <Heading size={3}>{t('thirdSection.title')}</Heading>
        <Body>{t('thirdSection.firstText')}</Body>
        <Body>{t('thirdSection.secondText')}</Body>
        <Body>{t('thirdSection.thirdText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: '<ul>'+
            '<li>'+t('thirdSection.thirdTextOptionsIten1')+'</li>'+
            '<li>'+t('thirdSection.thirdTextOptionsIten2')+'</li>'+
            '</ul>'
          }}
        />
        <Body>{t('thirdSection.fourthText')}</Body>

        <Heading size={3}>{t('fourthSection.title')}</Heading>
        <Body>{t('fourthSection.firstText')}</Body>
        <Body>{t('fourthSection.secondText')}</Body>
        <Body>{t('fourthSection.thirdText')}</Body>
        <Body
          dangerouslySetInnerHTML={{ __html: t('fourthSection.fourthText')+' <a target="_blank" href="/frequently-asked-questions">'+t('fourthSection.fourthTextHere')+'</a>' }}
        />
        <Body>{t('fourthSection.fifthText')}</Body>
        <Body>{t('fourthSection.sixthText')}</Body>
        <Body>{t('fourthSection.seventhText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: '<ul>'+
            '<li>'+t('fourthSection.seventhTextOptionsIten1')+'</li>'+
            '<li>'+t('fourthSection.seventhTextOptionsIten2')+'</li>'+
            '</ul>'
          }}
        />
        <Body>{t('fourthSection.eighthText')}</Body>

        <Heading size={3}>{t('fifthSection.title')}</Heading>
        <Body>{t('fifthSection.firstText')}</Body>
        <Body>{t('fifthSection.secondText')}</Body>

        <Heading size={3}>{t('sixthSection.title')}</Heading>
        <Body underline>{t('sixthSection.firstText')}</Body>
        <Body>{t('sixthSection.secondText')}</Body>
        <Body>{t('sixthSection.thirdText')}</Body>
        <Body>{t('sixthSection.fourthText')}</Body>
        <Body>{t('sixthSection.fifthText')}</Body>
        <Body underline>{t('sixthSection.sixthText')}</Body>
        <Body>{t('sixthSection.seventhText')}</Body>
        <Body
          dangerouslySetInnerHTML={{ __html: t('sixthSection.eighthText') }}
        />
        <Body>{t('sixthSection.ninethText')}</Body>
        <Body>{t('sixthSection.tenthText')}</Body>

        <Heading size={3}>{t('seventhSection.title')}</Heading>
        <Body
          dangerouslySetInnerHTML={{ __html: t('seventhSection.firstText')+' <a href="/terms-and-conditions">'+t('seventhSection.here')+'</a>'+t('seventhSection.firstText2') }}
        />
        <Body
          dangerouslySetInnerHTML={{
            __html: '<ul>'+
            '<li>'+t('seventhSection.firstTextOptionsIten1')+'</li>'+
            '<li>'+t('seventhSection.firstTextOptionsIten2')+'</li>'+
            '<li>'+t('seventhSection.firstTextOptionsIten3')+'</li>'+
            '<li>'+t('seventhSection.firstTextOptionsIten4')+'</li>'+
            '<li>'+t('seventhSection.firstTextOptionsIten5')+'</li>'+
            '</ul>'
          }}
        />
        <Body>{t('seventhSection.secondText')}</Body>
        <Body>{t('seventhSection.thirdText')}</Body>
        <Body>{t('seventhSection.fourthText')}</Body>

        <Heading size={3}>{t('eighthSection.title')}</Heading>
        <Body>{t('eighthSection.firstText')}</Body>
        <Body>{t('eighthSection.secondText')}</Body>

        <Heading size={3}>{t('ninethSection.title')}</Heading>
        <Body>{t('ninethSection.firstText')}</Body>
        <Body>{t('ninethSection.secondText')}</Body>
        <Body>{t('ninethSection.thirdText')}</Body>
        <Body>{t('ninethSection.fourthText')}</Body>
      </SubTitlesContainer>
    </RightContainer>
  );
};

export default TermsOfServicePage;
