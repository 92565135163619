import instance from './instance';



const appointmentsFilters = [
  'awaiting_specialist',
  'awaiting_visit_payment',
  'awaiting_visit',
  'awaiting_meeting',
  'awaiting_service_payment',
  'awaiting_conclusion',
  'complete',
  'rejected',
  'cancelled'
];

const openAppointmentsFilters = [
  'awaiting_specialist',
  'awaiting_visit_payment',
  'awaiting_visit',
  'awaiting_meeting',
  'awaiting_service_payment',
  'awaiting_conclusion'
];
const closedAppointmentsFilters = [
  'complete',
  'rejected',
  'cancelled'
];


const getAppointments = (userType, pageNumber, anotherFilter) => {
  let qs = `${userType}/appointments?page=${pageNumber}`;
  let newFilter = [...appointmentsFilters];
  if(anotherFilter !== null && anotherFilter !== undefined) {
    if(anotherFilter?.status?.length >= 1) {
      newFilter = appointmentsFilters.filter(filterItem => anotherFilter.status.includes(filterItem));
    }
    if(newFilter.length === 0) {
      newFilter = appointmentsFilters;
    }
    if(anotherFilter?.date?.end_date !== null && anotherFilter?.date?.end_date !== undefined) {
      qs += `&end_date=${anotherFilter?.date?.end_date}`;
    }
    if(anotherFilter?.date?.start_date !== null && anotherFilter?.date?.start_date !== undefined) {
      qs += `&start_date=${anotherFilter?.date?.start_date}`;
    }
  }
  newFilter.forEach(filter => (qs += `&status[]=${filter}`));
  return instance.get(qs);
};


const getOpenAppointments = (userType, pageNumber, anotherFilter) => {
  let qs = `${userType}/appointments?page=${pageNumber}`;
  let newFilter = [...openAppointmentsFilters];
  if(anotherFilter !== null && anotherFilter !== undefined) {
    if(anotherFilter?.status?.length >= 1) {
      newFilter = openAppointmentsFilters.filter(filterItem => anotherFilter.status.includes(filterItem));
    }
    if(newFilter.length === 0) {
      newFilter = openAppointmentsFilters;
    }
    if(anotherFilter?.date?.end_date !== null && anotherFilter?.date?.end_date !== undefined) {
      qs += `&end_date=${anotherFilter?.date?.end_date}`;
    }
    if(anotherFilter?.date?.start_date !== null && anotherFilter?.date?.start_date !== undefined) {
      qs += `&start_date=${anotherFilter?.date?.start_date}`;
    }
  }
  newFilter.forEach(filter => (qs += `&status[]=${filter}`));
  return instance.get(qs);
};

const getClosedAppointments = (userType, pageNumber, anotherFilter) => {
  let newFilter = [...closedAppointmentsFilters];
  let qs = `${userType}/appointments?page=${pageNumber}`;
  if(anotherFilter !== null && anotherFilter !== undefined) {
    if(anotherFilter?.status?.length >= 1) {
      newFilter = closedAppointmentsFilters.filter(filterItem => anotherFilter.status.includes(filterItem));
    }
    if(newFilter.length === 0) {
      newFilter = closedAppointmentsFilters;
    }
    if(anotherFilter?.date?.end_date !== null && anotherFilter?.date?.end_date !== undefined) {
      qs += `&end_date=${anotherFilter?.date?.end_date}`;
    }
    if(anotherFilter?.date?.start_date !== null && anotherFilter?.date?.start_date !== undefined) {
      qs += `&start_date=${anotherFilter?.date?.start_date}`;
    }
  }

  newFilter.forEach(filter => (qs += `&status[]=${filter}`));
  return instance.get(qs);
};

const getAppointment = (userType = 'client', id) => {
  return instance.get(`${userType}/appointments/${id}`);
};

const triggerPayment = query => {
  return instance.get(`client/payment${query}`);
}

export default {
  getAppointment,
  getAppointments,
  getOpenAppointments,
  getClosedAppointments,
  triggerPayment
};
