import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-flow: column-nowrap;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-height: 100vh;
  background-color: ${props => props.theme.colors.lightBeige};
    @media only screen and (max-width: 375px) {
      maxwidth: 100%;
    }
  }
`;

const MessageContainer = styled.div`
  height: 389px;
  width: 564px;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 16px;
  > p {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  > button {
    margin-bottom: 2%;
  }
  @media only screen and (max-width: 1024px) {
    height: 389px;
    width: 514px;
  }
  @media only screen and (max-width: 768px) {
    height: 389px;
    width: 604px;
    margin-left: 10%;
    margin-right: 10%;
  }
  @media only screen and (max-width: 375px) {
    height: 469px;
    width: 311px;
  }
`;

const TitleMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > h3 {
    align-self: center;
    margin-bottom: 0;
    margin-top: 0;
  }
  @media only screen and (max-width: 375px) {
    > h3 {
      text-align: center;
    }
  }
`;

export { Container, MessageContainer, TitleMessageContainer };
