import React from 'react';
import { useTranslate } from '../../features/polyglot';


import { Avatar, Row, Icon, Badge } from 'ui-55';
import {
  StyledCardContainer,
  StyledCardBody,
  StyledServiceTitle,
  StyledServiceColumns,
  StyledIcon,
  StyledButton,
  StyledCliendContainer,
  StyledMobileArrowHolder,
  StyledBadge
} from './styles';


const RequestListProvider = (props) => {
  const t = useTranslate('accountRequests');
  const b = useTranslate('badges');

  const { client, appointment, service, history, type } = props;
  const calculateProviderPrice = () => {
    if (appointment?.attributes?.override_provider_price) {
      const hours = appointment?.attributes?.override_provider_price * appointment?.attributes?.estimated_hours
      const urgent = appointment?.attributes?.is_urgent ?
        appointment?.attributes?.provider_urgent_price * appointment?.attributes?.estimated_hours : 0
      const kilometers = appointment?.attributes?.km_price * appointment?.attributes?.total_kms
      return (hours + urgent + kilometers) / 100;

    } else {
      return appointment?.attributes?.provider_total_price;
    }
  }



  return (
    <>
      <StyledCardContainer
        justify='flex-end'
        spaced
        key={appointment?.id}
        padding={0}
      >
        <Row>
          <StyledServiceColumns size={3}>
            <StyledServiceTitle>
              {service?.attributes?.name}
            </StyledServiceTitle>
            <StyledMobileArrowHolder
              onClick={() =>
                history.push('request-detail/' + appointment?.id)
              }
            >
              <Icon name='arrow-right' />
            </StyledMobileArrowHolder>
            <StyledServiceColumns size={4}>
              <StyledCliendContainer noWrap>
                <StyledCardBody faded>
                  { t('client')}
                </StyledCardBody>
                  {client?.attributes ? <Avatar user={client?.attributes} hasText />:(<p>N/A</p>)}
              </StyledCliendContainer>
            </StyledServiceColumns>
          </StyledServiceColumns>
          <StyledServiceColumns size={1} collapse={'mobile'}>
            <Row justify={'flex-end'}>
              <Badge
                translate={b}
                status={appointment?.attributes?.status}
                text={appointment?.attributes?.status}
              />
            </Row>
            <Row justify='flex-end'>
              {appointment?.attributes?.start_date && (
                <>
                  <StyledCardBody>{t('since')}</StyledCardBody>
                  <StyledCardBody>
                    {new Date(
                      appointment?.attributes?.start_date
                    ).toLocaleDateString('PT-PT')}
                  </StyledCardBody>
                </>
              )}
            </Row>

          </StyledServiceColumns>
        </Row>

        <Row>
          <StyledServiceColumns size={3}>
            <Row>
              {appointment?.attributes?.total_hours && (
                <StyledCardBody style={{ margin: 0 }}>
                  <StyledIcon width={24} heigth={24} name='Clock' />
                  {appointment?.attributes?.total_hours} {t('hours')}
                </StyledCardBody>
              )}
            </Row>

            <Row>
              {appointment?.attributes?.total_price !== null && (
                <>
                  <StyledCardBody faded>{t('total')}</StyledCardBody>
                    <StyledCardBody>{calculateProviderPrice()} €</StyledCardBody>
                    <StyledCardBody faded>
                      {t('taxIncluded')}
                    </StyledCardBody>
                  </>
              )}
              {(type === 'all' || type === 'closed') && (
                    <>
                      <Row justify='flex-start'>
                        <StyledCardBody>{t('scheduledDay')}</StyledCardBody>
                        <StyledCardBody>
                          {new Date(
                            appointment?.attributes?.delivered_on
                          ).toLocaleDateString('PT-PT')}
                        </StyledCardBody>
                        </Row>
                    </>
                )}
              <StyledBadge
                collapsable
                translate={b}
                status={appointment?.attributes?.status}
                text={appointment?.attributes?.status}
              />
            </Row>
          </StyledServiceColumns>
          {
            (type === 'open' ? (
              <StyledServiceColumns size={1} collapse={'mobile'}>
                  <StyledButton
                    isTotalWidth
                    btnType='borded'
                    text={t('details')}
                    action={() =>
                      history.push('request-detail/' + appointment?.id)
                    }
                  />
              </StyledServiceColumns>
            ): (
                <StyledServiceColumns size={1}>
                  <StyledCardBody>
                    {appointment.attributes?.payment_status === "unresolved" && (<>{t('payment_status.unresolved')}</>)}
                    {appointment.attributes?.payment_status === "paid" && (<>{t('payment_status.paid')}</>)}
                    {appointment.attributes?.payment_status === "payment_cancelled" && (<>{t('payment_status.payment_cancelled')}</>)}
                  </StyledCardBody>
                </StyledServiceColumns>
                ))
          }



        </Row>
      </StyledCardContainer>
    </>)
};


export default RequestListProvider;
