import React from 'react';
import { useTranslate } from '../../features/polyglot';

import usePolymorph from 'Hooks/usePolymorph';

import { GridCol, Col } from 'Components/Layout';
import { Jumbo, Body, Heading, SmallBody } from 'Components/Text';
import Logo from 'Components/Logo';
import Loader from 'Components/Loader';
import Card from 'Components/Card';

import {
  CardContainer,
  SubHeaderTextContainer,
  ImageContainer,
  SpecialistsSection,
  SpecialistItemsListing
} from './styles';

import Header from './Header';
import SubHeader from './SubHeader';
import BottomSection from './BottomSection';

const STATISTICS_LOGOS = [
  {
    logo: 'regEspecialistLogo'
  },
  {
    logo: 'activeEspecialistLogo'
  },
  {
    logo: 'performedServicesLogo'
  },
  {
    logo: 'activeHoursLogo'
  },
  {
    logo: 'totalAmountLogo'
  }
];

const About = () => {
  const tsh = useTranslate('about.subheader');
  const extractor = {
    statistics: (props, element) => {
      props.reviews = (element?.elementable?.elements ?? []).map((el, idx) => {
        const body = getElementValueByName(el, 'statistic-description');
        const amount = getElementValueByName(el, 'statistic-number');
        return {
          ...STATISTICS_LOGOS[idx],
          amount,
          body
        };
      });
    },
    'about-header': (props, element) => {
      props.title = getElementValueByName(element, 'title');
      props.subtitle = getElementValueByName(element, 'subtitle');
      props.description = getElementValueByName(element, 'description');
      props.image = getElementValueByName(element, 'image', 'media_url');
    },
    'history-section': (props, element) => {
      props.history = getElementValueByName(element, 'history');
      props.specialists = getElementValueByName(element, 'specialists');
      props.clients = getElementValueByName(element, 'clients');
      props.img = getElementValueByName(element, 'image', 'media_url');
    },
    'reviews-section': (props, element) => {
      props.title = getElementValueByName(element, 'title');
      props.subtitle = getElementValueByName(element, 'subtitle');
      props.reviews = getElementsByName(element, 'list')
        ?.sort((e, f) => e.order - f.order)
        .map(el => {
          const body = getElementValueByName(el, 'content');
          const signature = getElementValueByName(el, 'author');
          return {
            body,
            signature
          };
        });
    }
  };

  const pageElements = {
    'about-header': ({ title, subtitle, description, image }) => (
      <>
        <GridCol size={5}>
          <Heading size={6}>{title}</Heading>
          <Jumbo size={6}>{subtitle}</Jumbo>
          <Body>{description}</Body>
        </GridCol>
        <GridCol contain src={image}>
          <Logo size={450} color={'aboutCoverEffect'} />
        </GridCol>
      </>
    ),
    'history-section': ({ history, specialists, clients, img }) => (
      <>
        <SubHeaderTextContainer>
          <Body>{history}</Body>
        </SubHeaderTextContainer>
        <CardContainer>
          <Card bg='alt'>
            <Col>
              <Heading size={5}>{tsh('card1.title')}</Heading>
              <Body>{specialists}</Body>
            </Col>
          </Card>
          <Card bg='alt'>
            <Col>
              <Heading size={5}>{tsh('card2.title')}</Heading>
              <Body>{clients}</Body>
            </Col>
          </Card>
        </CardContainer>
        <ImageContainer>
          <img alt='' src={img} />
        </ImageContainer>
      </>
    ),
    statistics: ({ reviews }) => (
      <SpecialistsSection>
        {reviews.map(e => {
          return (
            <SpecialistItemsListing key={e.logo}>
              <Logo color={e.logo} />
              <Heading align='center' size={2}>
                {e.amount}
              </Heading>
              <Body align='center'>{e.body}</Body>
            </SpecialistItemsListing>
          );
        })}
      </SpecialistsSection>
    ),
    'reviews-section': ({ title, subtitle, reviews }) => (
      <>
        <Heading size={6}>{title}</Heading>
        <Heading size={3}>{subtitle}</Heading>
        <CardContainer borded>
          {reviews.map((content, index) => {
            return (
              <Card key={`${content}-${index}`}>
                <Col>
                  <Body>{content.body}</Body>
                  <SmallBody bold>{content.signature}</SmallBody>
                </Col>
              </Card>
            );
          })}
        </CardContainer>
      </>
    )
  };

  const {
    parsedContent,
    getElementValueByName,
    getElementsByName
  } = usePolymorph({
    id: 'about',
    pageElements,
    extractor
  });
  return parsedContent ? (
    <>
      <Header content={parsedContent['about-header']} />
      <SubHeader
        history={parsedContent['history-section']}
        statistics={parsedContent['statistics']}
      />
      <BottomSection content={parsedContent['reviews-section']} />
    </>
  ) : (
    <Loader />
  );
};

export default About;
