import { createSelector } from 'reselect';

const selectInvoicesState = state => state.invoices;

const getInvoices = createSelector(selectInvoicesState, state => {
  return state?.invoices || null;
});

const getLoading = createSelector(selectInvoicesState, state => {
  return state.loading;
});

const getErrors = createSelector(selectInvoicesState, state => {
  return state.errors;
});

export { getInvoices, getErrors, getLoading };
