import styled from 'styled-components';
import { GridRow, Hero, media, GridCol } from 'Components/Layout';

const NAVBAR_HEIGHT = '80px';
export const HeaderContainer = styled(Hero)`
  height: auto;
  padding-top: ${NAVBAR_HEIGHT};
  margin-bottom: -${NAVBAR_HEIGHT};
  background: linear-gradient(
    to right,
    ${props => props.theme.colors.lightBeige} 50%,
    ${props => props.theme.colors.white} 50%
  );
  ${media.tablet(`
    background: white;
  `)}
  ${media.mobile(`
  `)}
`;

export const HeaderGrid = styled(GridRow)`
  ${media.tablet(`
    background: white;
  `)}
  background: linear-gradient(
    to right,
    ${props => props.theme.colors.lightBeige} 50%,
    ${props => props.theme.colors.white} 50%
  );
`;

export const ButtonContainer = styled.div`
  width: 100%;
  position: relative;
  bottom: 0px;
  top: ${props => props.theme.margin * 2}px;
`;

export const StyledFormContainer = styled(GridCol)`
  padding-bottom: 32px;
  ${media.tablet(`
    width 100%;
  `)}
`;

export const DefaultStepContainer = styled.div`
  ${media.mobile(`
    max-width: 253px;
  `)}
`;

export const StyledLogoContainer = styled.div`
  display: ${props => (props.hide ? 'none' : 'flex')};
  height: 80px;
  background-color: ${props => props.theme.colors.white};
  position: sticky;
  top: 0;
  max-width: 1152px;
  z-index: 10;
  margin: 0 auto;
  align-items: center;
`;
