import styled from 'styled-components';

import { Body, media, Col, Row } from 'ui-55';


// renders ProviderAccPreferences ***********************************
export const StyledContainer = styled(Col)`
  width: 100%;
  ${media.tablet(`flex-flow: column;`)}
  ${media.mobile(`padding: 0px;`)};
  button {
    padding: 8px;
  }
`;

export const StyledPickerContainer = styled.div`
  margin: 16px 0px;
  width: 100%;
`;

export const StyledButtonsContainer = styled(Row)`
  display: ${props => (props.hideButtons ? 'none' : 'flex')};
  flex: 1;
  justify-content: space-evenly;

  button {
    min-width: 172px;
  }

  ${media.tablet(`
    button {
      max-height: 40px;
      min-width: 323px;
      margin: 16px 0px;
    }
  `)}

  ${media.mobile(`
    justify-content: space-between;
    button {
      max-height: 40px;
      min-width: 98px;
      margin: 16px 0px;
    }
  `)}
`;

export const ModalTextNewScheduleText = styled(Body)`
  margin: 0px;
  :nth-child(odd) {
    margin-bottom: 6px;
  }
`;

export const StyledHeaderTextContainer = styled(Row)`
  ${media.tablet(`
    background: #f8f7f7;
    height: 56px;
    margin-top: 24px;
    align-content: center;
  `)}
  ${media.mobile(`
    background: #f8f7f7;
    height: 56px;
    margin-top: 24px;
    align-content: center;
  `)}
  flex:1;
  h2 {
    margin: 0px 0px 36px 0px;
    ${media.mobile(`margin: 0px`)};
  }
`;
/* ****************************************************** */
