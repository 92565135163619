import PropTypes from 'prop-types';
import { useTranslate } from '../../../../features/polyglot';
import React, { useState } from 'react';
import useInvoices from 'Hooks/useInvoices';
import { Badge, Button, Col, Icon, SmallBody, Body, Row, Heading } from 'ui-55';
import {
  Dot,
  InvoiceDetailsBody,
  InvoiceHeaders,
  PaymentCol,
  PaymentDetails,
  SmallBodyDetail,
  StyledServiceName,
  NoInvoiceMessage,
  StyledIcon,
  StyledMobileBodyHeaders,
  StyledMobileCols,
  MobileServiceHeader
} from './styles';
import MultibancoModal from '../MultibancoModal';

const MB_STATUS_MAP = {
  charge_succeeded: 'paid',
  charge_pending: 'pending',
  charge_failed: 'void',
}

const Invoices = ({ user, appointment, history }) => {
  const invoiceTrans = useTranslate('invoices');
  const b = useTranslate('badges');

  const { invoices } = useInvoices({
    userType: user?.type,
    appointment
  });

  const [showMultiModal, setShowMultiModal] = useState(false);
  const [modalData, setModalData] = useState(null);


  /* useEffect(() => {
    if (!appointmentsState?.appointments) {
      history.push('/account/my-requests');
    }
  }, [appointmentsState, history]); */

  const formatDateString = (date, short) => {
    const newDate = new Date(date)
      .toUTCString()
      .split(' ')
      .splice(1, short ? 2 : 3)
      .join(' ');

    return newDate;
  };

  const renderInvoicesDetails = () => {
    const invoiceMapping =
      invoices &&
      invoices?.length > 0 &&
      invoices.map((invoice, i) => {
        const isMultibanco = invoice?.attributes?.payment_flow_type === 'multibanco';
        // get attributes
        const chargeStatus = MB_STATUS_MAP[invoice?.attributes?.charge_status];
        const invoiceStatus = isMultibanco
          ? chargeStatus || 'pending'
          : invoice?.attributes?.status;
        const invoiceHours = invoice?.attributes?.total_hours;
        const invoiceTotalPrice = (
          Math.round(invoice?.attributes?.total_price_with_tax) / 100
        ).toFixed(2);

        const bodyColumnsCustomStyle = {
          justify: 'center',
          size: 3,
          padding: 8
        };

        const invoiceURL = invoice?.attributes?.hosted_invoice_url;
        const pdfURL = invoice?.attributes?.stripe_pdf_url;
        const serviceName = invoice?.attributes?.service_name;

        const showMultibancoModal = () => {
          setShowMultiModal(true)
          setModalData(invoice)
        }
        return (
          <React.Fragment key={`invoice-${invoice?.id}`}>
            <InvoiceDetailsBody>
              {!appointment && (
                <Col name='service-name' {...bodyColumnsCustomStyle}>
                  <StyledServiceName>{serviceName}</StyledServiceName>
                </Col>
              )}
              <Col {...bodyColumnsCustomStyle}>
                {invoiceStatus &&
                  <Badge
                    translate={b}
                    text={invoiceStatus === 'pending' ? 'open' : invoiceStatus}
                    status={invoiceStatus === 'pending' ? 'open' : invoiceStatus}
                  />
                }
              </Col>
              <Col {...bodyColumnsCustomStyle}>
                <Row>
                  <StyledIcon name='Clock' />
                  <StyledMobileCols>
                    <StyledMobileBodyHeaders>
                      {!appointment
                        ? invoiceTrans('issueDate')
                        : invoiceTrans('interval')}
                    </StyledMobileBodyHeaders>
                    <SmallBody>
                      {!appointment
                        ? formatDateString(invoice?.attributes?.created_at)
                        : `${formatDateString(
                            invoice?.attributes?.created_at,
                            true
                          )} - ${formatDateString(
                            invoice?.attributes?.invoice_due,
                            true
                          )}`}
                    </SmallBody>
                  </StyledMobileCols>
                </Row>
              </Col>
              <Col {...bodyColumnsCustomStyle}>
                <Row>
                  <StyledIcon name='Hourglass' />
                  <StyledMobileCols>
                    <StyledMobileBodyHeaders>
                      {invoiceTrans('hours')}
                    </StyledMobileBodyHeaders>
                    <SmallBody>
                      {invoiceHours}
                      {invoiceTrans('hours')}
                    </SmallBody>
                  </StyledMobileCols>
                </Row>
              </Col>
              <Col {...bodyColumnsCustomStyle}>
                <Row>
                  <StyledIcon name='Total' />
                  <StyledMobileCols>
                    <StyledMobileBodyHeaders>
                      {invoiceTrans('totalAmmount')}
                    </StyledMobileBodyHeaders>
                    <SmallBody>{invoiceTotalPrice}€</SmallBody>
                  </StyledMobileCols>
                </Row>
              </Col>
              <Col size={4} padding={8} justify='center'>
                {invoiceStatus === 'open' ? (
                  <a
                    name='invoice-url'
                    href={invoiceURL}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <Button
                      text={
                        !appointment
                          ? invoiceTrans('seeMore')
                          : invoiceTrans('paymentDetails')
                      }
                      btnType='borded'
                    />
                  </a>
                ) : (
                  <Row justify='space-between'>
                    {isMultibanco ? (
                      <Button
                        text={invoiceTrans('paymentDetails')}
                        btnType='borded'
                        action={showMultibancoModal}
                      />
                    ) : (
                      <>
                        <SmallBody>{invoiceStatus}</SmallBody>
                        <a
                          name='pdf-url'
                          href={pdfURL}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <Icon name='PDF' />
                        </a>
                      </>
                    )
                    }

                  </Row>
                )}
              </Col>
              {!invoiceStatus === 'open' && (
                <Col
                  size={1}
                  justify='center'
                  padding={bodyColumnsCustomStyle.padding}
                  align='flex-end'
                >
                  <Icon name='PDF' />
                </Col>
              )}
            </InvoiceDetailsBody>
          </React.Fragment>
        );
      });

    const headerColumnsCustomStyle = {
      justify: 'center',
      size: 3,
      padding: 8
    };

    return (
      <>
        <InvoiceHeaders>
          {!appointment && (
            <Col {...headerColumnsCustomStyle}>
              <SmallBody>{invoiceTrans('service')}</SmallBody>
            </Col>
          )}
          <Col {...headerColumnsCustomStyle}>
            <SmallBody>{invoiceTrans('status')}</SmallBody>
          </Col>
          <Col {...headerColumnsCustomStyle}>
            <SmallBody>
              {!appointment
                ? invoiceTrans('issueDate')
                : invoiceTrans('interval')}
            </SmallBody>
          </Col>
          <Col {...headerColumnsCustomStyle}>
            <SmallBody>
              {!appointment
                ? invoiceTrans('totalHours')
                : invoiceTrans('hours')}
            </SmallBody>
          </Col>
          <Col {...headerColumnsCustomStyle}>
            <SmallBody>{invoiceTrans('totalAmmount')}</SmallBody>
          </Col>
          <Col size={4} padding={8}>
            {!appointment && (
              <SmallBody>{invoiceTrans('paymentMethod')}</SmallBody>
            )}
          </Col>
        </InvoiceHeaders>
        {invoiceMapping}
      </>
    );
  };

  const totalInvoicesPaid = (invoices?.filter(invoice => invoice?.attributes?.status === 'paid')?.map(invoice => invoice?.attributes?.total_price)?.reduce((prev, next) => prev + next, 0) / 100).toFixed(2) || 0;
  const totalInvoicesOpen = (invoices?.filter(invoice => invoice?.attributes?.status === 'open')?.map(invoice => invoice?.attributes?.total_price)?.reduce((prev, next) => prev + next, 0) / 100).toFixed(2) || 0;

  const appointmentServiceName =
    invoices &&
    invoices.length > 0 &&
    invoices.find(
      e => e?.relationships?.appointment?.data?.id === appointment?.id
    )?.attributes?.service_name;

  const toggleModal = () => {
    setShowMultiModal(!showMultiModal)
  }

  return (
    <>
      {invoices && invoices.length === 0 ? (
        <NoInvoiceMessage>{invoiceTrans('noInvoices')}</NoInvoiceMessage>
      ) : (
        <>
          <MobileServiceHeader>
            <Button
              btnType='borded'
              icon='arrow-left'
              action={() => history.goBack()}
            />
            <Heading size={5}>{appointmentServiceName}</Heading>
          </MobileServiceHeader>
          <PaymentDetails>
            <PaymentCol size={6}>
              <Dot paid={true} />
              <SmallBodyDetail>{invoiceTrans('totalPaid')}</SmallBodyDetail>
              <Body>{totalInvoicesPaid}€</Body>
            </PaymentCol>
            <PaymentCol size={6}>
              <Dot />
              <SmallBodyDetail>{invoiceTrans('isOpen')}</SmallBodyDetail>
              <Body>{totalInvoicesOpen}€</Body>
            </PaymentCol>
          </PaymentDetails>
          {renderInvoicesDetails()}

          {modalData && showMultiModal &&
            <MultibancoModal data={modalData} toggleModal={toggleModal}/>
          }
        </>
      )}
    </>
  );
};

Invoices.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    attributes: PropTypes.object,
    relationships: PropTypes.object
  }),
  appointment: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.object,
    relationships: PropTypes.object,
    type: PropTypes.string
  }),
  history: PropTypes.object
};

export default Invoices;
