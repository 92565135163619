import styled from "styled-components";
import { Row, media } from "Components/Layout";

export const Container = styled(Row)`
  margin-top: ${props => props.theme.margin}px;
  min-height: 320px;
  align-items: center;
  ${media.mobile(`
  h2 {
    line-height: 43px;
  }
    & > div > div {
      width: 100%;
    }
  `)}
`;
