import React from 'react';
import { BurgerButtonContainer } from './styles';
import PropTypes from 'prop-types';
import { Icon } from 'ui-55';

const BurgerButton = ({ openMenu, setOpenMenu }) => {
  return (
    <BurgerButtonContainer
      openMenu={openMenu}
      onClick={() => setOpenMenu(!openMenu)}
    >
      <Icon name={openMenu ? 'Close' : 'Menu'}></Icon>
    </BurgerButtonContainer>
  );
};

BurgerButton.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  setOpenMenu: PropTypes.func.isRequired
};

export default BurgerButton;
