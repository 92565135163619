import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, MessageContainer, TitleMessageContainer } from './styles';
import Button from 'Components/Button';
import { Body, SubHeading } from 'Components/Text';
import Logo from 'Components/Logo';
import { useTranslate } from '../../features/polyglot';

const Page404 = () => {
  const history = useHistory();
  const t = useTranslate('errorPages.page404');
  const a = useTranslate('errorPages');

  return (
    <Container>
      <MessageContainer>
        <Logo />
        <TitleMessageContainer>
          <SubHeading>{t('firstText')}</SubHeading>
          <SubHeading>{t('secondText')}</SubHeading>
        </TitleMessageContainer>
        <Body>{t('pageNotFoundText')}</Body>
        <Button text={a('buttonText')} action={() => history.push('/')} />
      </MessageContainer>
    </Container>
  );
};

export default Page404;
