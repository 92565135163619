import React from 'react';
import styled from 'styled-components';
import List from 'Components/List';
import { Heading, Body } from 'Components/Text';
import { Row } from 'Components/Layout';

import { Col, Badge, Accordion } from 'ui-55';
import cards from './mockedCards';
// import prices from 'ui-55/src/components/utils/prices';

const StyledHeader = styled.div`
  p {
    margin: 0;
  }
`;

const StyledCard = styled.div`
  padding: 16px;
  flex: 1;
  margin: ${({ theme }) => theme.margin}px ${({ theme }) => theme.margin}px
    ${({ theme }) => theme.margin}px 0;
  border-radius: 16px;
  padding: ${props => props.theme.margin}px;
  box-shadow: ${props => (props.shadow ? props.theme.boxShadow : 0)};
  background-color: ${props => props.theme.colors.lightestBeige};
  color: ${props => props.theme.colors.darkBlue};
  justify-content: center;
  text-align: center;
  p {
    text-align: center;
  }
  h2 {
    text-align: center;
    margin: 0;
  }
  span {
    display: block;
    margin: 8px 60px 24px;
    p {
      line-height: 20px;
      color: #ffffff;
    }
  }
`;

const FootNoteContainer = styled(Row)`
  padding: 10px;
  border-radius: 2px;
  margin-top: ${props => props.theme.margin * 2}px;
  background-color: ${props => props.theme.colors.feedback.warning.background};
`;

const cardsRender = (cards, prices, t, b) => {
  return (
    cards &&
    cards.map((card, index) => {
      const cardList = card.list.map((listItem) => {
        return t(listItem);
      });
      return (
        <>
          {prices[card.key] && (
            <Accordion
              key={card.key}
              title={t(card?.key)}
              isOpen={card.isOpen}
              content={
                <StyledHeader>
                  <Heading size={6}>{t('whatIsIncluded')}</Heading>
                  {card.listTitle && <Body size={6}>{t('listTitle')}</Body>}
                  <List hasFullWidthLi data={cardList} />
                  <Row justify='space-between'>
                    {card.cardHolders &&
                      card.cardHolders.map((holder, index) => {
                        return renderCardHolders(
                          { ...holder, key: card.key },
                          prices,
                          t,
                          b
                        );
                      })}
                  </Row>
                  <Row justify='space-between'>
                    {card.subServices &&
                      card.subServices.map((subService, index) => (
                        <Col key={subService?.title + 'accordion'}>
                          <Heading size={6}>{subService?.title}</Heading>
                          <Row justify='space-between' noWrap>
                            {subService.cardHolders &&
                              subService.cardHolders.map((holder, index) => {
                                return renderCardHolders(
                                  { ...holder, key: subService.key },
                                  prices,
                                  t,
                                  b
                                );
                              })}
                          </Row>
                        </Col>
                      ))}
                  </Row>
                  {card.footNote && (
                    <FootNoteContainer>
                      <Heading size={6}>{t(card.footNote?.title)+' '+t(card.footNote.body)}</Heading><br />

                    </FootNoteContainer>
                  )}
                </StyledHeader>
              }
            />
          )}
        </>
      );
    })
  );
};

const renderCardHolders = (holder, prices, t, b) => {
  const holderPrices = prices[holder.key];
  return (
    holderPrices && (
      <StyledCard bg='alt2'>
        <Body>{t(holder?.title)}</Body>
        <Badge
          text={holder.serviceType}
          category={holder.category}
          transalate={b}
        />
        {holderPrices[holder.serviceType.toLowerCase()] && (
          <Row align='baseline' justify='center'>
            <Heading size={4}>
              {holderPrices[holder.serviceType.toLowerCase()]}
            </Heading>
            <Body>/{holderPrices.unidades}</Body>
          </Row>
        )}
        {holderPrices.km && (
          <Row justify='center'>
            <Body size={4}>{t('kmCost')}</Body>
            <Row align='baseline'>
              <Heading size={6}>{holderPrices.km.split('/')[0]}</Heading>
              <Body>/{holderPrices.km.split('/')[1]}</Body>
            </Row>
          </Row>
        )}
        {/*
      {holder.extraInfo &&
        holder.extraInfo.map((extras, index) => {
          return (
            <Row justify='center' key={index}>
              <Body size={4}>{extras.body}</Body>
              <Row align='baseline'>
                <Heading size={6}>{extras.price}</Heading>
                <Body>{extras.priceRate}</Body>
              </Row>
            </Row>
          );
        })}

      */}
      </StyledCard>
    )
  );
};
const defaultStep = (childButton, prices, t, b) => {
  return (
    <>
      <>
        <Heading size={2}>{t("thanks")}</Heading>
        <Body>
          {t('subtitle_1')}
        </Body>
        {childButton()}
      </>
      <Heading size={4}>{t('title')}</Heading>
      {cardsRender(cards, prices, t, b)}
      <Body>
        {t('subtitle_2')}
      </Body>
      <Body>
        {t('subtitle_3')}
      </Body>
      <Heading size={6}>{t('faq_1')}</Heading>
      <Body>
        {t('subtitle_4')}
      </Body>
    </>
  );
};

export default defaultStep;
