import styled from 'styled-components';
import Slider from 'react-slick';
import { media, Page } from 'Components/Layout';
import theme from 'Theme';

export const Container = styled(Page)`
  ${media.mobile(`
    margin: 0px;
    margin-left: 16px;
  `)}
`;
export const CarouselContainer = styled(Slider)`
  .slick-disabled {
    display: none !important;
  }

  ${media.mobile(`
    .slick-track  {
      display: flex;
    }
    .slick-slide {
      min-width: 245px;
    }
  `)}


  .slick-prev {
    ${media.tablet(`
      display: none !important;
    `)}
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.brand.yellow};
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    top: 55%;
    left: -60px;
    &:before {
      visibility: hidden;
      content:''
    }
  }
  .slick-next {
    ${media.maxNavbar(`
    right: 22px;
    top: 80%;
  `)}
    ${media.tablet(`
      display: none !important;
    `)}
    &:before {
      visibility: hidden;
      content:''
    }
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  top: 55%;

  right: ${props => (props.direction === 'right' ? '-60px' : 'unset')};
  left: ${props => (props.direction === 'left' ? '-60px' : 'unset')};
  /*right: -75px;*/
  border-radius: 50%;
  border: 1px solid ${theme.colors.brand.yellow};
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.tablet(`
      display: block !important;
    `)}
  ${media.mobile(`
  display: flex !important;
  flex-direction: column !important;
  flex: 1;
  align-items: flex-start;
  margin-left: 32px;
  `)};
`;

export const ItemImage = styled.div`
:hover {
  cursor: pointer;
}
${media.smallDesktop(`
  max-width: 245px;
  height: 146px;
`)}
${media.tablet(`
  width: 208px;
  height: 124px;
`)}
${media.mobile(`
  width: 258px;
  height: 154px;
`)}
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 368px;
  height: 220px;
  margin-bottom: ${props => props.theme.margin}px;
`;
