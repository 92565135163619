import React, { useEffect, useState } from 'react';
import { useTranslate } from '../../features/polyglot';
import { useHistory } from 'react-router-dom';

import useAuth from 'Hooks/useAuth';
import useAppointments from 'Hooks/useAppointments';
import { MyRequests } from './renders';
import FilterButtonStatus from 'Components/FilterButtonStatus';
import FilterButtonDate from 'Components/FilterButtonDate';

import {
  StyledTabs,
  StyledCardRow,
  StyledIcon,
  StyledCardBody,
  InnerRow,
  StyledServiceColumns,
  StyledFilterBar
} from './styles';
import {
  Loader,
  Row,
  Body
} from 'ui-55';



const MyServiceTabs = () => {
  const t = useTranslate('account');

  const { user } = useAuth();
  const history = useHistory();
  const [anotherFilter, setAnotherFilter] = useState({ date: null, status: null });

  useAppointments({
    type: 'all',
    user,
    page: 1
  });

  useAppointments({
    type: 'open',
    user,
    page: 1
  });

  const { appointments, loading, setUpdateFilter } = useAppointments({
    type: 'closed',
    user,
    page: 1
  });


  const handleClearStatus = () => {
    setAnotherFilter(prevState => ({
      ...prevState,
      status: null
    }));
    setUpdateFilter({ date: anotherFilter?.date, status: null });
  }

  const handleClearDate = () => {
    setAnotherFilter(prevState => ({
      ...prevState,
      date: null
    }));
    const status = anotherFilter?.status?.map(item => item.original_label);
    setUpdateFilter({ date: null, status: status });
  }

  const handleChangeStatus = (e) => {
    if(e === null || e === undefined) {
      setAnotherFilter(prevState => ({
        ...prevState,
        status: null
      }));
      return;
    }
    const status = e.map(item => item.original_label);
    setAnotherFilter(prevState => ({
      ...prevState,
      status: e
    }));
    setUpdateFilter({ date: anotherFilter?.date, status: status });
  }
  const handleChangeDates = (e) => {
    if(e === null || e === undefined) {
      setAnotherFilter(prevState => ({
        ...prevState,
        date: null
      }));
      return;
    }

    setAnotherFilter(prevState => ({
      ...prevState,
      date: e
    }));
    const status = anotherFilter?.status?.map(item => item.original_label);
    setUpdateFilter({ date: e, status: status });
  }


  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalServices, setTotalServices] = useState(0);

  const [ongoingAmount, setOngoingAmount] = useState(0.0);
  const [ongoingServices, setOngoingServices] = useState(0);

  useEffect(() => {
    let amount = 0.0;
    let services = 0;

    let ongoing_amount = 0.0;
    let ongoing_services = 0;

    if (appointments) {
      for (let i = 0; i < appointments?.['closed-1']?.data.length; i++) {
        if (appointments['closed-1'].data[i].attributes.status === 'complete') {
          amount = amount + appointments['closed-1'].data[i].attributes.provider_total_price
          services = services + 1
        }
      }

      setTotalAmount(amount)
      setTotalServices(services)

      for (let i = 0; i < appointments?.['open-1']?.data.length; i++) {
        ongoing_amount = ongoing_amount + appointments?.['open-1']?.data[i]?.attributes?.provider_total_price
        ongoing_services = ongoing_services + 1
      }
      setOngoingAmount(ongoing_amount)
      setOngoingServices(ongoing_services)
    }

  }, [appointments]);


  return loading ? (
    <Loader />
  ) : (
    <>
      <Row>
        <StyledCardRow justify='space-between' flow='row nowrap'>
          <InnerRow>
            <StyledServiceColumns justify='center' size={1}>
              <StyledIcon align='center' alt name='Total' />
            </StyledServiceColumns>
            <StyledServiceColumns size={4} align='flex-start'>
              <StyledCardBody faded>{t('totalAmount')}:</StyledCardBody>
              <Body>
                {totalAmount.toLocaleString("pt-PT", { style: "currency", currency: "EUR" })}
              </Body>
            </StyledServiceColumns>
          </InnerRow>
          <InnerRow>
            <StyledServiceColumns justify='center' size={1}>
              <StyledIcon align='center' alt name='Total' />
            </StyledServiceColumns>
            <StyledServiceColumns size={4}>
              <StyledCardBody faded>{t('totalServices')}:</StyledCardBody>
              <Body>
                {totalServices}
              </Body>
            </StyledServiceColumns>
          </InnerRow>
        </StyledCardRow>
        <StyledCardRow justify='space-between' flow='row nowrap'>
          <InnerRow>
            <StyledServiceColumns justify='center' size={1}>
              <StyledIcon align='center' alt name='Hourglass' />
            </StyledServiceColumns>
            <StyledServiceColumns size={4} align='flex-start'>
              <StyledCardBody faded>{t('ongoingAmount')}:</StyledCardBody>
              <Body>
                {ongoingAmount.toLocaleString("pt-PT", { style: "currency", currency: "EUR" })}
              </Body>
            </StyledServiceColumns>
          </InnerRow>
          <InnerRow>
            <StyledServiceColumns justify='center' size={1}>
              <StyledIcon align='center' alt name='Hourglass' />
            </StyledServiceColumns>
            <StyledServiceColumns size={4}>
              <StyledCardBody faded>{t('ongoingServices')}:</StyledCardBody>
              <Body>
                {ongoingServices}
              </Body>
            </StyledServiceColumns>
          </InnerRow>
        </StyledCardRow>
      </Row>
      {user.type === 'provider' && (<StyledFilterBar>
        <FilterButtonStatus
          filterOptions={
            [
              { label: 'Aguarda Especialista', value: 3, original_label: 'awaiting_specialist' },
              { label: 'Aguarda Pagamento Visita', value: 4, original_label: 'awaiting_visit_payment' },
              { label: 'Aguarda Visita', value: 5, original_label: 'awaiting_visit' },
              { label: 'Aguarda Entevista', value: 6, original_label: 'awaiting_meeting' },
              { label: 'Aguarda Pagamento Serviço', value: 7, original_label: 'awaiting_service_payment' },
              { label: 'Aguarda Conclusão', value: 8, original_label: 'awaiting_conclusion' },
              { label: 'Completo', value: 9, original_label: 'complete' },
              { label: 'Cancelado', value: 11, original_label: 'cancelled' },
              { label: 'Rejeitado', value: 12, original_label: 'rejected' }
            ]
          }
          selectValue={anotherFilter?.status}
          setSelectValue={(e) => {
            handleChangeStatus(e);
          }}
          onClose={handleClearStatus}
        />
        <FilterButtonDate
          selectValue={anotherFilter?.date}
          onClose={handleClearDate}
          onChange={handleChangeDates}
        />
      </StyledFilterBar>)}
      <StyledTabs
        initialTabIndex={0}
        tabs={[
          {
            name: `${t('requests')} (${
              appointments?.['all-1']?.data
                ? appointments?.['all-1']?.meta["Total-Count"]
                : '0'
            })`,
            children: () => (
              <MyRequests type='all' user={user} history={history} />
            )
          },
          {
            name: `${t('opened')} (${
              appointments?.['open-1']?.data
                ? appointments?.['open-1']?.meta["Total-Count"]
                : '0'
            })`,
            children: () => (
              <MyRequests type='open' user={user} history={history} />
            )
          },
          {
            name: `${t('concluded')} (${
              appointments?.['closed-1']?.data
                ? appointments?.['closed-1']?.meta["Total-Count"]
                : '0'
            })`,
            children: () => (
              <MyRequests type='closed' user={user} history={history} />
            )
          }
        ]}
      />
    </>
  );
};

export default MyServiceTabs;
