import { ACTION_TYPES } from './actions';

const initialState = {
  loading: false,
  loaded: false,
  selectedCity: null,
  error: false,
  cities: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CHANGE_CITY:
      return {
        ...state,
        selectedCity: action.payload
      };
    case ACTION_TYPES.GET_CITIES_LISTING:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GET_CITIES_LISTING_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.GET_CITIES_LISTING_SUCCESS:
      return {
        ...state,
        cities: {
          data: action.payload?.data?.filter(
            e => e?.attributes?.name !== 'Outro'
          )
        },
        citiesWithOther: action.payload.data,
        loading: false,
        loaded: true
      };
    default:
      return state;
  }
};
