import styled from 'styled-components';

import { Body, SmallBody, media, Col, Row, Icon, Badge, Tabs } from 'ui-55';
import Button from 'Components/Button';
import theme from 'Theme';
/* Provider Requests ********************************** */

export const StyledCardContainer = styled.div`
  ${media.mobile(`padding: 16px;`)}
  margin-bottom: ${props => (props.spaced ? 16 : 0)}px;
  width: 100%;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: ${({ theme }) => theme.margin}px;
  justify-content: ${props => props.justify || 'flex-start'};
  padding: ${props => (props.padding ? props.padding : '24')}px;
  display: flex;
  flex-flow: ${props => (props.flow ? props.flow : 'row wrap')};
`;

export const StyledCardBody = styled(SmallBody)`
  background-color: ${props =>
    props.bg ? getBackgroundFromStatus(props.bg) : ''};
  padding: ${props => (props.bg ? '4px 8px' : 0)};
  border-radius: 2px;
  color: ${props =>
    props.faded ? props.theme.colors.grey : props.theme.colors.darkBlue};
  margin-right: ${({ theme }) => theme.margin / 2}px;
  align-self: start;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.grey};
  svg {
    margin-right: ${({ theme }) => theme.margin / 2}px;
    vertical-align: middle;
  }
`;

export const StyledCliendContainer = styled(Row)`
  padding: 0;
  ${media.mobile(`
    flex-flow: column;
    p {
      margin: 0px;
      align-self: initial;
      line-height: 0;
    }
  `)}
`;

export const BackBtn = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  outline: none;
  padding: 0;
  align-items: center;
  border: 0;
  cursor: pointer;
  background: transparent;
  margin-bottom: ${({ theme }) => theme.margin * 1.5}px;
`;
export const StyledServiceTitle = styled(Body)`
  color: ${props =>
    props.isNavigation
      ? props.theme.colors.darkBlue
      : props.theme.colors.brand.orange};
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
  align-self: flex-start !important;
`;
export const StyledBadge = styled(Badge)`
  && {
    p {
      line-height: 16px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    ${'' /* ${media.mobile(`
    position: relative;
    top: 45px;
  `)} */}
    ${({ collapsable }) =>
      collapsable
        ? media.desktop(`
    display: none;
    `)
        : ''}
  }
`;

export const StyledMobileBadgeContainer = styled.span`
  display: none;
  align-self: start;
  ${media.mobile(`
    display: flex;
    justify-content: flex-end;
    width: 45%;
  `)}
`;

export const StyledServiceColumns = styled.div`
  justify-content: ${props => props.justify};
  padding: 0px;
  display: flex;
  flex: ${({ size }) => size};
  flex-direction: column;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  p {
    line-height: 24px;
    margin-bottom: 4px;
    margin-top: 0px;
    white-space: nowrap;
    align-self: center;
  }
  ${props =>
    props.collapse &&
    media[props.collapse](`
  display: none;
  `)}
`;

export const StyledMobileArrowHolder = styled.span.attrs({ role: 'button' })`
  display: none !important;
  ${media.mobile(`
    display: flex !important;
    position: relative;
    bottom: 25px;
    left: 90%;
  `)}
`;

export const StyledIcon = styled(Icon)`
  align-self: ${props => (props.align ? props.align : 'unset')};
  margin-right: ${props => props.right || 0}px;
  width: ${props => props.width || 32}px;
  height: ${props => (props.height ? props.height : 32)}px;
  background-color: ${props =>
    props.alt ? props.theme.colors.lightBeige : ''};
  border-radius: ${props => (props.alt ? '16px' : 'none')};
`;

export const StyledButton = styled(Button)`
  margin: 0px;
  width: 100%;
`;
/**************************************************/
const getBackgroundFromStatus = status => {
  switch (status) {
    case 'received':
    case 'complete':
      return theme.colors.brand.blue;
    case 'analysis':
    case 'await-details':
    case 'reopened':
      return theme.colors.brand.yellow;
    case 'accepted':
      return theme.colors.brand.green;
    case 'rejected':
      return theme.feedback.error.default;
    default:
      return 'white';
  }
};

/* Request Details */
export const StyledHelpContainer = styled(Col)`
  margin-bottom: ${({ theme }) => theme.margin}px;
  background-color: ${props => props.theme.colors.brand.orange};
  border-radius: 2px;
  max-height: 15vh;
  min-width: 150px;
  max-width: 100%;

  h2 {
    color: ${props => props.theme.colors.brand.lightBeige};
  }
  a {
    color: ${props => props.theme.colors.brand.lightBeige};
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const StyledCardRow = styled(Row)`
  ${media.mobile(`padding: 16px;`)}
  margin-bottom: ${props => (props.spaced ? 16 : 0)}px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 2px;
  justify-content: ${props => props.justify || 'flex-start'};
  padding: ${props => (props.padding ? props.padding : '24')}px;
  display: flex;
  flex-flow: ${props => (props.flow ? props.flow : 'row wrap')};
  p {
    margin-top: 4px !important;
    margin-right: 4px !important;
  }
`;

export const InnerRow = styled(Row)`
  flex-direction: column;
`;
export const ButtonRow = styled(Row)`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  ${media.mobile(`
      flex-wrap: wrap;
    `)}
`;

export const FormContainer = styled(Row)`
  border: 1px solid ${props => props.theme.colors.mediumBeige};
  border-radius: 4px;
  align-content: flex-start;
  padding: 24px;
  width: auto;
  margin: 0;
  width: 94%;
  > div {
    max-width: 100%;
  }
  form > button {
    transition: background-color 0.25s ease-in;
    ${({ success, error, theme }) =>
      `background-color:  ${(success &&
        theme.colors.feedback.success.default) ||
        (error && theme.colors.feedback.error.default) ||
        ''}`};
    span {
      ${({ success, error, theme }) =>
        `color:  ${((success || error) && theme.colors.white) || ''}`};
    }
    &: hover {
      ${({ success, error, theme }) =>
        `background-color:  ${(success &&
          theme.colors.feedback.success.hover) ||
          (error && theme.colors.feedback.error.hover) ||
          ''}`};
    }
  }
`;

export const StyledCta = styled(Body)`
  color: ${props => props.theme.colors.brand.orange};
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  margin: 0px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.mediumBeige};
  width: 100%;
`;

export const StyledHeader = styled(Row)`
  > div:first-child {
    margin-right: ${({ theme }) => theme.margin}px;
    min-width: 310px;
  }
  width: 100%;
`;
/* Request Details Client */
export const StyledClientDetails = styled(Row)`
  margin-top: ${props => props.theme.margin * 1.5}px;
  background-color: ${props => props.theme.colors.lightestBeige};

  > div:last-of-type {
    min-height: 81px;
    align-items: center;
    background-color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};
    p:first-of-type {
      text-transform: capitalize;
    }
  }
`;
export const StyledTabs = styled(Tabs)`
  > div {
    ${media.mobile(`overflow: scroll;`)}
    button {
      margin: 0;
      margin-bottom: ${({ theme }) => theme.margin}px;
      margin-right: ${({ theme }) => theme.margin}px;
      margin-top: ${({ theme }) => theme.margin}px;
    }
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledButtonRow = styled(Row)`
  button {
    padding: 10px 0px;
    min-width: 200px;
    ${media.mobile(`
      margin-top: 5px;
      width: 42%;
    `)}
    background-color: ${props => (props.isAccepted ? `#db1e3b` : `#2dca73`)};
    &:hover{
    background-color: ${props => (props.isAccepted ? `#db1e3b` : `#2dca73`)};
    }
    margin-right: ${props => props.theme.margin * 2}px;
    span {
      color: white;
    }
  }
`;

export const StyledProvideAcceptArea = styled(Row)`
  margin: 40px 0px;
  div > div {
    width: 100%;
  }
`;
