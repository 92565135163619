import { ACTION_TYPES } from './actions';

const initialState = {
  appointments: null,
  loading: false,
  isSubmitting: false,
  wasSuccessful: false,
  pageNumber: null,
  errors: null,
  appointmentLoading: null,
  appointmentError: null,
  appointmentForm: [],
  appointment: null,
  filter: { date: null, status: null }
};

export default (state = initialState, action) => {
  switch (action.type) {

    case ACTION_TYPES.UPDATE_FILTER:
      return {
        ...state,
        filter: {
          ...action.payload
        }
      };
    case ACTION_TYPES.GET_USER_APPOINTMENTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          all: true
        }
      };
    case ACTION_TYPES.GET_USER_APPOINTMENTS_SUCCESS:
      const { appointments: newAppointments } = state;
      if (newAppointments) {
        delete newAppointments[`all-${action.pageNumber}`];
      }
      return {
        ...state,
        pageNumber: action.pageNumber,
        appointments: action.pageNumber
          ? {
              [`all-${action.pageNumber}`]: action.payload,
              ...state.appointments
            }
          : {
              all: action.payload,
              ...state.appointments
            },
        loading: {
          ...state.loading,
          all: false
        }
      };
    case ACTION_TYPES.GET_USER_APPOINTMENTS_FAILED:
      return {
        ...state,
        errors: action.errors,
        loading: {
          ...state.loading,
          all: false
        }
      };
    case ACTION_TYPES.GET_USER_OPEN_APPOINTMENTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          open: true
        }
      };
    case ACTION_TYPES.GET_USER_OPEN_APPOINTMENTS_SUCCESS:
      const { appointments: newAppointments2 } = state;
      if (newAppointments2) {
        delete newAppointments2[`open-${action.pageNumber}`];
      }
      return {
        ...state,
        pageNumber: action.pageNumber,
        appointments: action.pageNumber
          ? {
              [`open-${action.pageNumber}`]: action.payload,
              ...state.appointments
            }
          : {
              open: action.payload,
              ...state.appointments
            },
        loading: {
          ...state.loading,
          open: false
        }
      };
    case ACTION_TYPES.GET_USER_OPEN_APPOINTMENTS_FAILED:
      return {
        ...state,
        errors: action.errors,
        loading: {
          ...state.loading,
          open: false
        }
      };
    case ACTION_TYPES.GET_USER_CLOSED_APPOINTMENTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          closed: true
        }
      };
    case ACTION_TYPES.GET_USER_CLOSED_APPOINTMENTS_SUCCESS:
      const { appointments: newAppointments3 } = state;
      if (newAppointments3) {
        delete newAppointments3[`closed-${action.pageNumber}`];
      }
      return {
        ...state,
        pageNumber: action.pageNumber,
        appointments: action.pageNumber
          ? {
              [`closed-${action.pageNumber}`]: action.payload,
              ...state.appointments
            }
          : {
              closed: action.payload,
              ...state.appointments
            },
        loading: {
          ...state.loading,
          closed: false
        }
      };
    case ACTION_TYPES.GET_USER_CLOSED_APPOINTMENTS_FAILED:
      return {
        ...state,
        errors: action.errors,
        loading: {
          ...state.loading,
          closed: false
        }
      };

    case ACTION_TYPES.GET_APPOINTMENT:
      return {
        ...state,
        appointmentForm: null,
        appointmentLoading: true
      };
    case ACTION_TYPES.GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentLoading: false,
        appointmentError: false,
        appointmentForm: {
          documents: action?.payload?.included?.find(e => e.type === 'lead')
            ?.attributes?.documents,
          questions: action.form,
          answers: action.answers,
          id: action.payload?.data?.id
        },
        appointment: action.payload
      };
    case ACTION_TYPES.GET_APPOINTMENT_FAIL:
      return {
        ...state,
        appointmentForm: null,
        appointmentError: true,
        appointmentLoading: false
      };
    default:
      return state;
  }
};
