import styled from 'styled-components';

export const BurgerButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100px;
  z-index: 10;
  cursor: pointer;
  align-items: center;
`;
