import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useTranslate } from '../../features/polyglot';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import useCities from 'Hooks/useCities';

import { GridCol } from 'Components/Layout';
import { Heading, Body } from 'Components/Text';
import Button from 'Components/Button';
import List from 'Components/List';
import useAuth from 'Hooks/useAuth';
import theme from 'Theme';
import {
  HeaderContainer,
  HeaderGrid,
  StyledFormContainer,
  DefaultStepContainer
} from './styles';
import { Form } from 'ui-55';
import { defaultStep } from './steps/index';
import formSteps from 'ui-55/src/components/utils/becomespecialist';
import { updateProviderForm } from 'Redux/provider/actions';
import ResetPassword from 'Pages/ResetPassword';

import { makePrices } from './steps/mockedCards';
import { useSelector } from "react-redux";
import { selectLocale } from '../../features/polyglot/polyglotSlice';

const SpecialistForm = () => {
  const locale = useSelector(selectLocale);
  const t = useTranslate('TalentForm');
  const b = useTranslate('badges');
  const translateForm = useTranslate('forms');
  const [keyForForm, setKeyForForm] = useState(0);
  useEffect(() => {
    setKeyForForm(prevKey => prevKey + 1);
  }, [locale]);

  const stepperInfo = [
    t('steps.personalData'),
    t('steps.contacts'),
    t('steps.address'),
    t('steps.currentProfession'),
    t('steps.services'),
    t('steps.hourPreference'),
    t('steps.payments'),
    t('steps.otherLanguages'),
    t('steps.other')
  ];

  const excludedOptions = [
    {
      key: 'services',
      optionValue: 'gastro_experience'
    }
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const [newPassword, setNewPassword] = useState({
    isSet: null
  });

  const [stepErrors, setStepErrors] = useState(null);

  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const providerParams = queryString.parse(history.location.search);
  const { user } = useAuth();
  const renderDefaultStepButton = () => {
    return (
      <Button
        isTotalWidth
        isDisabled={!user}
        action={() => handleStep('forward')}
        text={t('start')}
      />
    );
  };
  const steptoRender = useMemo(() => {
    if (currentStep > 0) {
      return formSteps.questions[currentStep - 1].questions.map(q => {
        const isExcluded = excludedOptions.find(opt => opt.key === q.key);

        if (!isExcluded) {
          return q;
        }
        return q.options
          ? {
              ...q,
              options: q.options.filter(
                opt => opt.value !== isExcluded.optionValue
              )
            }
          : q;
      });
    }
  }, [currentStep, excludedOptions]);

  const handleStep = useCallback(
    order => {
      if (order === 'back') setCurrentStep(prev => (prev -= 1));
      if (currentStep + 1 === formSteps.length) return;
      if (order === 'forward') {
        setCurrentStep(prev => (prev += 1));
        scrollToRef(formRef);
      }
    },
    [currentStep]
  );
  const getNestedKeys = useCallback(
    (nest, array) =>
      nest.forEach(q => {
        array.push(q.key);
        if (q.questions) {
          getNestedKeys(q.questions, array);
        }
        if (q.children) {
          getNestedKeys(q.children, array);
        }
      }),
    []
  );
  const handleErrors = useCallback(
    errors => {
      if (errors) {
        let isStepValid = true;
        const stepKeys = [];
        getNestedKeys(steptoRender, stepKeys);

        Object.keys(errors || {}).forEach(errorKey => {
          if (stepKeys.indexOf(errorKey) !== -1) {
            isStepValid = false;
          }
        });

        if (isStepValid) {
          handleStep('forward');
          setStepErrors({});
        } else {
          setStepErrors(errors);
        }
      }
    },
    [getNestedKeys, handleStep, steptoRender]
  );
  const handleSubmit = async values => {
    if (currentStep === formSteps.questions.length) {
      const submitObj = {
        password: newPassword.password,
        form_token: providerParams?.form_token ?? false,
        form_answers: values
      };

      updateProviderForm(dispatch, submitObj);
      history.push('/thankYou', {
        originPage: 'providerRequest',
        name: providerParams.name
      });
    } else {
      handleStep('forward', values);
    }
  };

  const scrollToRef = ref =>
    window.scrollTo({
      behavior: 'smooth',
      top: ref.current.offsetTop - 200
    });

  const handlePassword = useCallback(
    v => {
      setNewPassword(v);
      const submitObj = {
        password: v.password,
        form_token: providerParams?.form_token ?? false
      };
      updateProviderForm(dispatch, submitObj);
    },
    [dispatch, providerParams.form_token]
  );

  const { cities } = useCities();
  const userCity = cities.find(c => {
    return c.attributes?.name === user?.attributes?.service_cities?.[0];
  });
  const [prices, setPrices] = useState({});
  useEffect(() => {
    if (userCity?.attributes) {
      setPrices(
        makePrices(
          userCity.attributes?.active_services,
          userCity.attributes?.active_prices,
          t
        )
      );
    }
  }, [userCity, setPrices, t]);

  return (
    <>
      {!newPassword.isSet ? (
        <ResetPassword action={v => handlePassword(v)} />
      ) : (
        <>
          <HeaderContainer top>
            <HeaderGrid justify='space-between'>
              <GridCol collapse={'tablet'} size={4}>
                <Heading size={4}>{t('almost')}</Heading>
                <Body>
                  {t('almost-subtitle')}
                </Body>
                <List
                  hasFullWidthLi
                  indexedList={currentStep}
                  hasIcon
                  data={stepperInfo}
                />
              </GridCol>
              <GridCol collapse={'tablet'} size={1} />
              <GridCol collapse={'tablet'} size={1} />
              <StyledFormContainer ref={formRef} size={5}>
                <GridCol size={1} collapse={'tablet'} />

                {currentStep > 1 && (
                  <Body
                    onClick={() => handleStep('back')}
                    style={{
                      color: `${theme.colors.brand.orange}`,
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}
                  >
                    {`< ${t("step-back")}`}
                  </Body>
                )}
                {currentStep > 0 && (
                  <Heading size={4}>{stepperInfo[currentStep - 1]}</Heading>
                )}
                <>
                  {currentStep > 0 ? (
                    <Form
                      key={keyForForm}
                      locale={locale}
                      translate={translateForm}
                      btnAction={formProps => handleStep('forward', formProps)}
                      btnType={'submit'}
                      errors={stepErrors}
                      submitLabel={
                        currentStep < stepperInfo.length ? 'Seguinte' : 'Enviar'
                      }
                      answers={user?.attributes?.['form_answers']}
                      questions={steptoRender}
                      onSubmit={values => handleSubmit(values)}
                      onError={errors => handleErrors(errors)}
                    />
                  ) : (
                    <DefaultStepContainer key={currentStep}>
                      {defaultStep(renderDefaultStepButton, prices, t, b)}
                    </DefaultStepContainer>
                  )}
                </>
              </StyledFormContainer>
            </HeaderGrid>
          </HeaderContainer>
        </>
      )}
    </>
  );
};

export default SpecialistForm;
