import citiesClient from 'api/citiesClient';

const REDUCER = 'cities';

export const ACTION_TYPES = {
  CHANGE_CITY: `${REDUCER}/CHANGE_CITY`,
  GET_CITIES_LISTING: `${REDUCER}/GET_CITIES_LISTING`,
  GET_CITIES_LISTING_FAIL: `${REDUCER}/GET_CITIES_LISTING_FAIL`,
  GET_CITIES_LISTING_SUCCESS: `${REDUCER}/GET_CITIES_LISTING_SUCCESS`
};

const getCitiesListing = dispatch => {
  dispatch({
    type: ACTION_TYPES.GET_CITIES_LISTING
  });
  citiesClient
    .getCitiesListing()
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_CITIES_LISTING_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_CITIES_LISTING_FAIL,
        error: 'Error getting cities from the server'
      });
    });
};
const updateSelectedCity = (dispatch, value) => {
  dispatch({
    type: ACTION_TYPES.CHANGE_CITY,
    payload: value
  });
};

export { getCitiesListing, updateSelectedCity };
