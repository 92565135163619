import axios from 'axios';
import CONFIG from 'Config';
import normalize from 'json-api-normalizer';
import { get } from 'lodash';

import useStorage from 'Hooks/useStorage';
const { headers, setStorageAuthToken } = useStorage();

const instance = axios.create({
  baseURL: CONFIG.apiOrigin,
  headers
});

const cleanUser = user => {
  const data = normalize(user);
  return data?.[user?.data?.type][Object.keys(data?.[user?.data?.type])[0]];
};

instance.interceptors.response.use(
  res => {
    const authorizationHeader = get(res, 'headers.authorization');
    if (authorizationHeader) {
      instance.defaults.headers.common['authorization'] = authorizationHeader;
      setStorageAuthToken({
        token: authorizationHeader,
        tokenDate: new Date(),
        user: cleanUser(res.data)
      });
    }
    const logoutMetaData = get(res, 'data.meta.logout');
    if (logoutMetaData) {
      delete instance.defaults.headers.common['authorization'];
      delete instance.defaults.headers['authorization'];
      delete instance.defaults.headers['Authorization'];
    }
    return res;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
