import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../../../features/polyglot';
import { Heading, Form, Loader, Accordion, Row } from 'ui-55';
import { FormContainer, StyledBody } from './styles';
import detailsSchema from './detailsSchema';
import { toast } from 'react-toastify';
import useAuth from 'Hooks/useAuth';
import { useSelector } from "react-redux";
import { setLocale, selectLocale } from '../../../../features/polyglot/polyglotSlice';

import { useDispatch } from "react-redux";


const DetailsAccess = ({ user }) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const translateForm = useTranslate("forms");
  const [detailsSchemaTranslated, setDetailsSchemaTranslated] = useState({});
  const translateLabels = useTranslate('accountRequests.labels');
  const [keyForForm, setKeyForForm] = useState(0);
  const t = useTranslate('accountRequests');
  const { updateUser, authLoading, authError } = useAuth();
  useEffect(() => {
    setKeyForForm(prevKey => prevKey + 1);
  }, [locale]);
  const handleSubmit = vals => {

    dispatch(setLocale(vals?.language_code));

    const payload = {
      email: vals?.email,
      phone_number: vals?.telephone,
      full_name: vals?.fullName || vals?.name.concat(' ', vals?.lastName),
      first_name: vals?.name,
      last_name: vals?.lastName,
      country: vals?.country,
      address: vals?.address,
      postal_code: vals?.postalCode,
      nif: vals?.nif,
      district: vals?.district,
      language_code: vals?.language_code,
      district_parish: vals?.district_parish,
      company_status: vals?.company_status
    };
    if (user?.type === 'provider') delete payload['company_status'];
    if (vals['new-email'] && vals['new-email-repeat']) {
      if (vals['new-email'] !== vals['new-email-repeat']) {
        return toast.error('Os emails não coincidem');
      }
      payload.email = vals['new-email'];
    }
    if (vals['new-password'] && vals['new-password-repeat']) {
      if (vals['new-password'] !== vals['new-password-repeat']) {
        return toast.error('As passwords não coincidem');
      }
      payload.password = vals['password'];
      payload['new_password'] = vals['new-password'];
    }
    updateUser(user?.type, payload);
  };

  useEffect(() => {
    if (authError) {
      toast.error(t('error'));
    }
  }, [authError, t]);

  const formAnswers = user => {
    if (user?.type === 'client') {
      const clientStatus = {
        individual: 0,
        business: 1
      };
      const clientAnswers = {
        ...user?.attributes,
        company_status:
          clientStatus[
          user?.attributes?.client_type || user?.attributes?.clientType
          ],
        telephone:
          user?.attributes?.phone_number || user?.attributes?.phoneNumber,
        country: user?.attributes?.country || 1,
        postalCode:
          user?.attributes?.postal_code || user?.attributes?.postalCode,
        name: (
          user?.attributes?.fullName ||
          user?.attributes?.full_name ||
          ''
        ).split(' ')[0],
        lastName: (
          user?.attributes?.fullName ||
          user?.attributes?.full_name ||
          ''
        ).split(' ')[1]
      };
      return clientAnswers;
    } else {
      return !user?.attributes?.form_answers
        ? user?.attributes?.formAnswers
        : user?.attributes?.form_answers;
    }
  };

  const handleError = errors => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? translateForm('required-fields')
          : errors[errorField];
      toast.error(errorMsg);
    }
  };

  useEffect(() => {

    const makeTranslate = (label) => {
      return (label === ""
        || label === " "
        || label === undefined
        || label === null) ?
        "" : translateLabels(label);
    }
    const step = detailsSchema.step.map(e => {
      return {
        ...e, questions: e.questions.map(question => {
          return {
            ...question,
            children: question.children?.map((child) => {
              return {
                ...child,
                label: makeTranslate(child.label),
                extras: translateLabels(child.extras),
                placeholder: makeTranslate(child.placeholder),
                body: translateLabels(child.body),
                options: child.options?.map((option) => {
                  return { ...option, label: makeTranslate(option.label) };
                }),
              };
            }),
            label: makeTranslate(question.label)
          };
        })
      };
    });
    const translated = { ...detailsSchema, step: step };

    setDetailsSchemaTranslated(translated);
  },
    [locale]);


  return (
    <>
      {authLoading ? (
        <Loader />
      ) : (
        <>
          <Heading size={2}>{t('myAccount')}</Heading>
          {detailsSchemaTranslated?.step?.map(e => {
            return (
              <Accordion
                key={e?.key}
                title={t(e?.key)}
                content={
                  <>
                    {e?.stepIndex === 1 && (
                      <Row>
                        <StyledBody alt>{t('mailBody')}</StyledBody>
                        <StyledBody style={{ marginLeft: '8px' }}>
                          {user?.attributes?.email}
                        </StyledBody>
                      </Row>
                    )}
                    <FormContainer>
                      <Form
                        locale={locale}
                        translate={translateForm}
                        questions={e?.questions}
                        answers={formAnswers(user)}
                        hiddenFields={['district_parish']}
                        btnType='submit'
                        submitLabel={t('update')}
                        onError={handleError}
                        onSubmit={handleSubmit}
                      />
                    </FormContainer>
                  </>
                }
              />
            );
          })}
        </>
      )}
    </>
  );
};

DetailsAccess.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    attributes: PropTypes.object,
    relationships: PropTypes.object
  })
};

export default DetailsAccess;
