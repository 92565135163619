import { ACTION_TYPES } from './actions';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  wasSuccessful: false,
  isSubmitting: false,
  form: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SERVICE_FORM_BY_ID:
    case ACTION_TYPES.GET_SERVICE_FORM:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GET_SERVICE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: { [action.serviceName]: true },
        form: action.payload
      };
    case ACTION_TYPES.GET_SERVICE_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: { [action.serviceName]: action.error }
      };
    case ACTION_TYPES.SUBMIT_SERVICE_FORM:
      return {
        ...state,
        isSubmitting: true
      };
    case ACTION_TYPES.SUBMIT_SERVICE_FORM_FAILED:
      return {
        ...state,
        isSubmitting: false,
        wasSuccessful: false,
        error: action.error
      };
    case ACTION_TYPES.SUBMIT_SERVICE_FORM_SUCCESS:
      return {
        ...state,
        wasSuccessful: true
      };
    case ACTION_TYPES.RESET_SERVICE_FORM:
      return initialState;
    default:
      return state;
  }
};
