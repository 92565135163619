import styled from 'styled-components';
import { media } from 'Components/Layout';
import { Row, Heading } from 'ui-55';

export const FormContainer = styled.div`
  position: relative;
  top: -70px;
    margin-bottom: -240px;
  margin-left: 4px;
  button[type='submit'] {
    margin-top: 375px;
    width: 100%;
  }
  ${media.smallDesktop(`
  button[type="submit"] {
      margin-top: 500px;
    }
  `)}
  ${media.tablet(`
    margin-left: 0;
    button[type="submit"] {
      margin-top: 300px;
    }
  `)}
  ${media.mobile(`
    margin-bottom: -440px;
  button[type="submit"] {
      margin-top: 650px;
    }
  `)}
`;

export const GreyHeading = styled(Heading)`
  color: ${props => props.theme.colors.grey};
`;

export const WhatToExpect = styled.div`
  position: relative;
  top: -120px;
  div:first-of-type {
    ul > li {
      margin-bottom: ${props => props.theme.margin * 1.5}px;
      p {
        margin: 0;
      }
    }
  }
  div:nth-of-type(2) {
    ul > li {
      margin-bottom: ${props => props.theme.margin * 1.5}px;
      p {
        margin: 0;
      }
    }
  }
`;

export const TermsAndConditionsContainer = styled.div`
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  background-color: ${props => props.theme.colors.lightBeige};
  position: relative;
  bottom: 450px;
  p {
    max-height: 300px;
    overflow-y: scroll;
  }
  ${media.smallDesktop(`
  bottom: 590px;
  `)}
  ${media.tablet(`
  bottom: 390px;
  p {
    max-height: 120px !important;
  }
  `)}
  ${media.mobile(`
  bottom: 740px;
  `)}
  min-height: 150px;
  a {
    cursor: pointer;
    color: ${props => props.theme.colors.brand.red};
    text-decoration: none;
  }
  div {
    margin: 5px;
  }
  p {
    font-size: 11px;
    text-align: justify;
    max-height: 300px;
  }
`;

export const ErrorMessageHolder = styled.div`
  p {
    margin: 10px;
    position: absolute;
    bottom: -10px;
  }
`;

export const InfoContainer = styled(Row)`
  position: relative;
  bottom: 320px;
  ${media.smallDesktop(`bottom: 470px;`)}
  ${media.tablet(`bottom: 250px;`)}
  ${media.mobile(`bottom: 650px;`)}
`;
