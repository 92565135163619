import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import {
  actions as servicesActions,
  selectors as servicesSelectors
} from 'redux/services';
import {
  actions as serviceDetailActions,
  selectors as serviceDetailSelectors
} from 'redux/serviceForm';
import { useMemo } from 'react';

const useServices = () => {
  const dispatch = useDispatch();
  const { getServicesListing } = servicesActions;
  const { getServices, getLoading, getError, getLoaded } = servicesSelectors;

  const services = useSelector(
    state => getServices(state)?.data || [],
    shallowEqual
  );
  const error = useSelector(state => getError(state));
  const loading = useSelector(state => getLoading(state), shallowEqual);
  const loaded = useSelector(state => getLoaded(state), shallowEqual);

  const dispatchGetServicesListing = useCallback(() => {
    getServicesListing(dispatch);
  }, [dispatch, getServicesListing]);

  useEffect(() => {
    if (!loaded) {
      dispatchGetServicesListing();
    }
  }, [dispatchGetServicesListing, loaded]);

  return {
    services,
    error,
    loading
  };
};

export const useServiceDetail = (id, city) => {
  const dispatch = useDispatch();

  const { getServiceForm } = serviceDetailActions;
  const {
    getService,
    getFormLoading,
    getFormError,
    getFormLoaded
  } = serviceDetailSelectors;
  const serviceName = useMemo(() => [id + city ? `-${city}` : ''], [city, id]);

  const service = useSelector(state => getService(state));

  const error = useSelector(state => getFormError(state));
  const loading = useSelector(state => getFormLoading(state));

  const loaded = useMemo(() => service?.data?.id === id, [id, service]);
  const formLoaded = useSelector(state => getFormLoaded(state)[serviceName]);

  useEffect(() => {
    if ((!formLoaded || !loaded) && id && city) {
      getServiceForm(dispatch, { id, city });
    }
  }, [city, dispatch, formLoaded, getServiceForm, id, loaded]);

  return {
    service,
    serviceName,
    formLoaded,
    error,
    loading,
    loaded
  };
};

export default useServices;
