import React from 'react';
import { RightContainer, SubTitlesContainer } from './styles';
import { Heading, Body } from 'Components/Text';
import List from 'Components/List';
import { useTranslate } from '../../features/polyglot';

const TermsAndConditionsPage = () => {
  const t = useTranslate('footerPages.TermsAndConditions');
  const listArray = ['Lista 1', 'Lista 2', 'Lista 3', 'Lista 4', 'Lista 5'];

  return (
    <RightContainer>
      <Heading className='title' size={1}>
        {t('title')}
      </Heading>
      <Body className='description'>{t('description')}</Body>
      <SubTitlesContainer>
        <Heading size={3}>{t('firstSection.title')}</Heading>
        <Body>{t('firstSection.text')}</Body>

        <Heading size={3}>{t('secondSection.title')}</Heading>
        <Body>{t('secondSection.text')}</Body>

        <Heading size={3}>{t('thirdSection.title')}</Heading>
        <Body>{t('thirdSection.firstText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: `${t('thirdSection.secondText')} <a target="_blank" href="/become">${t('thirdSection.secondTextHere')}</a>.`
          }}
        />
        <Body
          dangerouslySetInnerHTML={{ __html: `${t('thirdSection.thirdText')}  <a target="_blank" href="/services"> ${t('thirdSection.thirdTextHere')} </a>.` }}
        />
        <Body>{t('thirdSection.fourthText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: `<ul class="termsAndConditionsHtml ">
                      <li> ${t('thirdSection.fourthTextOptionsIten1')}</li>
                      <li> ${t('thirdSection.fourthTextOptionsIten2')}</li>
                      <li> ${t('thirdSection.fourthTextOptionsIten3')}</li>
                      <li> ${t('thirdSection.fourthTextOptionsIten4')}</li>
                      <li> ${t('thirdSection.fourthTextOptionsIten5')}</li>
                      </ul>`
          }}
        />

        <Heading size={3}>{t('fourthSection.title')}</Heading>
        <Body>{t('fourthSection.firstText')}</Body>
        <Body
          dangerouslySetInnerHTML={{ __html: ` ${t('fourthSection.secondText')} <a target="_blank" href="/services"> ${t('fourthSection.secondTextHere')}</a>.` }}
        />
        <Body>{t('fourthSection.thirdText')}</Body>
        <Body
          dangerouslySetInnerHTML={{ __html: `${t('fourthSection.fourthText')} <a target="_blank" href="/services">  ${t('fourthSection.fourthTextHere')} </a> ${t('fourthSection.fourthText1')}` }}
        />
        <Body>{t('fourthSection.fifthText')}</Body>
        <Body>{t('fourthSection.sixthText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: `
            <ul>
              <li>${t('fourthSection.sixthTextOptionItem1')}</li>
              <li>${t('fourthSection.sixthTextOptionItem2')}</li>
              <li>${t('fourthSection.sixthTextOptionItem3')}</li>
              <li>${t('fourthSection.sixthTextOptionItem4')}</li>
              <li>${t('fourthSection.sixthTextOptionItem5')}</li>
              </ul>
            `
          }}
        />
        < Body > {t('fourthSection.seventhText')}</Body >
        <Body
          dangerouslySetInnerHTML={{
            __html: `<ul>
                      <li>${t('fourthSection.seventhTextOptionItem1')}</li>
                      <li>${t('fourthSection.seventhTextOptionItem2')}</li>
                    </ul>`
          }}
        />
        <Body>{t('fourthSection.eighthText')}</Body>
        <Body>{t('fourthSection.ninethText')}</Body>
        <Body>{t('fourthSection.tenthText')}</Body>
        <Body>{t('fourthSection.eleventhText')}</Body>
        <Body>{t('fourthSection.twelvethText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: '<ul><li>' + t('fourthSection.twelvethTextOptionsItem1') + '</li><li>' + t('fourthSection.twelvethTextOptionsItem2') + '</li><li>' + t('fourthSection.twelvethTextOptionsItem3') + '</li><li>' + t('fourthSection.twelvethTextOptionsItem4') + '</li></ul>'
          }}
        />

        <Heading size={3}>{t('aquisisionSection.title')}</Heading>
        <Body
          dangerouslySetInnerHTML={{ __html: t('aquisisionSection.firstText') + ' <a href="/terms-of-service">' + t('aquisisionSection.here') + '</a>.' }}
        />
        <Body>{t('aquisisionSection.secondText')}</Body>
        <Body>{t('aquisisionSection.thirdText')}</Body>
        <Body>{t('aquisisionSection.fourthText')}</Body>
        <Body>{t('aquisisionSection.fifthText')}</Body>

        <Heading size={3}>{t('fifthSection.title')}</Heading>
        <Body>{t('fifthSection.firstText')}</Body>
        <Body>{t('fifthSection.secondText')}</Body>
        <Body>{t('fifthSection.thirdText')}</Body>

        <Heading size={3}>{t('sixthSection.title')}</Heading>
        <Body>{t('sixthSection.firstText')}</Body>
        <Body>{t('sixthSection.secondText')}</Body>
        <Body>{t('sixthSection.thirdText')}</Body>

        <Heading size={3}>{t('seventhSection.title')}</Heading>
        <Body>{t('seventhSection.firstText')}</Body>
        <Body>{t('seventhSection.secondText')}</Body>
        <Body>{t('seventhSection.thirdText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: `<ul>
                      <li>${t('seventhSection.thirdTextOptionsItem1')}</li>
                      <li>${t('seventhSection.thirdTextOptionsItem2')} </li>
                    </ul>`
          }}
        />

        <Heading size={3}>{t('eighthSection.title')}</Heading>
        <Body>{t('eighthSection.firstText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html:
            `<ul>
              <li>${t('eighthSection.firstTextOptionsItem1')}</li>
              <li>${t('eighthSection.firstTextOptionsItem2')}</li>
              <li>${t('eighthSection.firstTextOptionsItem3')}</li>
              <li>${t('eighthSection.firstTextOptionsItem4')}</li>
              <li>${t('eighthSection.firstTextOptionsItem5')}</li>
              <li>${t('eighthSection.firstTextOptionsItem6')}</li>
              <li>${t('eighthSection.firstTextOptionsItem7')}</li>
              <li>${t('eighthSection.firstTextOptionsItem8')}</li>
              <li>${t('eighthSection.firstTextOptionsItem9')}</li>
              <li>${t('eighthSection.firstTextOptionsItem10')}</li>
              <li>${t('eighthSection.firstTextOptionsItem11')}</li></ul>`
          }}
        />
        <Body>{t('eighthSection.secondText')}</Body>

        <Heading size={3}>{t('ninethSection.title')}</Heading>
        <Body>{t('ninethSection.firstText')}</Body>
        <Body>{t('ninethSection.secondText')}</Body>
        <Body>{t('ninethSection.thirdText')}</Body>
        <Body>{t('ninethSection.fourthText')}</Body>

        <Heading size={3}>{t('tenthSection.title')}</Heading>
        <Body
          dangerouslySetInnerHTML={{ __html: `${t('tenthSection.firstText')} <a target="_blank" href="/privacy-policy">${t('tenthSection.firstTextPolicyAndCookies')} </a>.` }}
        />

        <Heading size={3}>{t('eleventhSection.title')}</Heading>
        <Body>{t('eleventhSection.firstText')}</Body>
        <Body>{t('eleventhSection.secondText')}</Body>
        <Body>{t('eleventhSection.thirdText')}</Body>
        <Body>{t('eleventhSection.fourthText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: `
            <ul>
              <li>${t('eleventhSection.fourthTextOptionsInten1')}</li>
              <li>${t('eleventhSection.fourthTextOptionsInten2')}</li>
              <li>${t('eleventhSection.fourthTextOptionsInten3')}</li>
              <li>${t('eleventhSection.fourthTextOptionsInten4')}</li>
              <li>${t('eleventhSection.fourthTextOptionsInten5')}</li>
              <li>${t('eleventhSection.fourthTextOptionsInten6')}</li>
              <li>${t('eleventhSection.fourthTextOptionsInten7')}</li>
              <li>${t('eleventhSection.fourthTextOptionsInten8')}</li>
              </ul>`
          }}
        />
        <Body>{t('eleventhSection.fifthText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: `
            <ul>
              <li>${t('eleventhSection.fifthTextOptionsItem1')} </li>
              <li>${t('eleventhSection.fifthTextOptionsItem2')} </li>
              <li>${t('eleventhSection.fifthTextOptionsItem3')} </li>
            </ul>`
          }}
        />
        <Body>{t('eleventhSection.sixthText')}</Body>

        <Heading size={3}>{t('twelfthSection.title')}</Heading>
        <Body>{t('twelfthSection.firstText')}</Body>
        <Body>{t('twelfthSection.secondText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html:
            `<ul>
              <li> ${t('twelfthSection.secondTextOptionsIten1')}</li>
              <li> ${t('twelfthSection.secondTextOptionsIten2')}</li>
              <li> ${t('twelfthSection.secondTextOptionsIten3')}</li>
              <li> ${t('twelfthSection.secondTextOptionsIten4')}</li>
              <ul>`
          }}
        />
        <Body>{t('twelfthSection.thirdText')}</Body>

        <Heading size={3}>{t('thirteenthSection.title')}</Heading>
        <Body>{t('thirteenthSection.firstText')}</Body>

        <Heading size={3}>{t('fourteenthSection.title')}</Heading>
        <Body>{t('fourteenthSection.firstText')}</Body>

        <Heading size={3}>{t('fifteenthSection.title')}</Heading>
        <Body>{t('fifteenthSection.firstText')}</Body>
        <Body>{t('fifteenthSection.secondText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: `<ul>
                      <li>${t('fifteenthSection.secondTextOptionsIten1')}</li>
                      <li>${t('fifteenthSection.secondTextOptionsIten2')}</li>
                    </ul>`
          }}
        />
        <Body>{t('fifteenthSection.thirdText')}</Body>

        <Heading size={3}>{t('sixteenthSection.title')}</Heading>
        <Body>{t('sixteenthSection.firstText')}</Body>

        <Heading size={3}>{t('seventeenthSection.title')}</Heading>
        <Body>{t('seventeenthSection.firstText')}</Body>

        <Heading size={3}>{t('eighteenthSection.title')}</Heading>
        <Body>{t('eighteenthSection.firstText')}</Body>

        <Heading size={3}>{t('nineteenthSection.title')}</Heading>
        <Body>{t('nineteenthSection.firstText')}</Body>

        <Heading size={3}>{t('twentiethSection.title')}</Heading>
        <Body>{t('twentiethSection.firstText')}</Body>

        {false && <List hasIcon data={listArray} />}
      </SubTitlesContainer >
    </RightContainer >
  );
};

export default TermsAndConditionsPage;
