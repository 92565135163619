import React from 'react';
import { RightContainer } from './styles';
import { Heading, Body } from 'Components/Text';
import { useTranslate } from '../../features/polyglot';

import { SubTitlesContainer } from './styles';

const PrivacyPolicyPage = () => {
  const t = useTranslate('footerPages.PrivacyPolicy');

  return (
    <RightContainer>
      <Heading className='title' size={1}>
        {t('title')}
      </Heading>

      <Body
        className='subtitle'
        dangerouslySetInnerHTML={{ __html: t('firstSection.firstText')+' <a target="_blank" href="/">'+t('firstSection.firstText55Site')+'</a> '+t('firstSection.firstText2') }}
      />
      <Body>{t('firstSection.secondText')}</Body>
      <Body>{t('firstSection.thirdText')}</Body>
      <Body
        dangerouslySetInnerHTML={{ __html: t('firstSection.fourthText')+' <a target="_blank" href="/terms-and-conditions">'+t('firstSection.fourthTextHere')+'</a>.' }}
      />
      <SubTitlesContainer>
        <Heading size={3}>{t('secondSection.title')}</Heading>
        <Body>{t('secondSection.firstText')}</Body>
        <Body>{t('secondSection.secondText')}</Body>
        <Body>{t('secondSection.thirdText')}</Body>

        <Heading size={3}>{t('thirdSection.title')}</Heading>
        <Body>{t('thirdSection.firstText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: '<ul>' +
              '<li>' + t('thirdSection.firstTextOptionsIten1') + '</li>' +
              '<li>' + t('thirdSection.firstTextOptionsIten2') + '</li>' +
              '<li>' + t('thirdSection.firstTextOptionsIten3') + '</li>' +
              '<li>' + t('thirdSection.firstTextOptionsIten4') + '</li>' +
              '<li>' + t('thirdSection.firstTextOptionsIten5') + '</li>' +
              '<li>' + t('thirdSection.firstTextOptionsIten6') + '</li>' +
              '</ul>'
          }}
        />
        <Body>{t('thirdSection.secondText')}</Body>
        <Body>{t('thirdSection.thirdText')}</Body>

        <Heading size={3}>{t('fourthSection.title')}</Heading>
        <Body>{t('fourthSection.firstText')}</Body>

        <Heading size={3}>{t('fifthSection.title')}</Heading>
        <Body>{t('fifthSection.firstText')}</Body>
        <Body>{t('fifthSection.secondText')}</Body>

        <Heading size={3}>{t('sixSection.title')}</Heading>
        <Body>{t('sixSection.firstText')}</Body>
        <Body>{t('sixSection.secondText')}</Body>

        <Heading size={3}>{t('seventhSection.title')}</Heading>
        <Body>{t('seventhSection.firstText')}</Body>
        <Body>{t('seventhSection.secondText')}</Body>
        <Body>{t('seventhSection.thirdText')}</Body>
        <Body>{t('seventhSection.fourthText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: '<ul>' +
              '<li>' + t('seventhSection.fourthTextOptionsIten1') + '</li>' +
              '<li>' + t('seventhSection.fourthTextOptionsIten2') + '</li>' +
              '</ul>'
          }}
        />
        <Body>{t('seventhSection.fifthText')}</Body>
        <Body
          dangerouslySetInnerHTML={{
            __html: '<ul>' +
              '<li>' + t('seventhSection.fifthTextOptionsIten1') + '</li>' +
              '<li>' + t('seventhSection.fifthTextOptionsIten2') + '</li>' +
              '</ul>'
          }}
        />
        <Body>{t('seventhSection.sixthText')}</Body>
        <table style={{ width: '100%' }}>
          <tr>
            <th>{t('seventhSection.cookieType')}</th>
            <th>{t('seventhSection.name')}</th>
            <th>{t('seventhSection.goal')}</th>
            <th>{t('seventhSection.duration')}</th>
            <th>{t('seventhSection.own')}</th>
          </tr>
          <tr>
            <td>[.]</td>
            <td>[.]</td>
            <td>[.]</td>
            <td>[.]</td>
            <td>[.]</td>
          </tr>
          <tr>
            <td>[.]</td>
            <td>[.]</td>
            <td>[.]</td>
            <td>[.]</td>
            <td>[.]</td>
          </tr>
        </table>
        <Body
          dangerouslySetInnerHTML={{ __html: t('seventhSection.seventhText') }}
        />
        <Body>{t('seventhSection.eighthText')}</Body>

        <Heading size={3}>{t('eighthSection.title')}</Heading>
        <Body>{t('eighthSection.firstText')}</Body>
        <Body>{t('eighthSection.secondText')}</Body>

        <Heading size={3}>{t('ninethSection.title')}</Heading>
        <Body>{t('ninethSection.firstText')}</Body>

        <Heading size={3}>{t('tenthSection.title')}</Heading>
        <Body>{t('tenthSection.firstText')}</Body>

        <Heading size={3}>{t('eleventhSection.title')}</Heading>
        <Body>{t('eleventhSection.firstText')}</Body>
        <Body>{t('eleventhSection.secondText')}</Body>

        {false && (
          <>
            <Heading size={3}>{t('secondTitle')}</Heading>
            <Body>{t('secondText')}</Body>

            <Heading size={3}>{t('thirdTitle')}</Heading>
            <Body>{t('thirdText')}</Body>

            <Heading size={3}>{t('fourthTitle')}</Heading>
            <Body>{t('fourthText')}</Body>

            <Heading size={3}>{t('fithTitle')}</Heading>
            <Body>{t('fithText')}</Body>
          </>
        )}
      </SubTitlesContainer>
    </RightContainer>
  );
};

export default PrivacyPolicyPage;
