import React, { useEffect, useState, useMemo } from 'react';
import { useTranslate } from '../../../features/polyglot';
import { useHistory, useLocation } from 'react-router-dom';
import FlexSearch from 'flexsearch';
import { components } from 'react-select';

import { TextInput, FullPage, Col, Badge, Icon } from 'ui-55';
import { Page } from 'Components/Layout';
import { Heading, Body } from 'Components/Text';
import Anchor from 'Components/Anchor';
import Tabs from 'Components/Tabs';
import Select from 'Components/Select';
import { tabs } from './mocks';

import useCities from 'Hooks/useCities';
import useServices from 'Hooks/useServices';
import useWindowMeasures from 'Hooks/useWindowMeasures';
import {
  BadgeContainer,
  PropertyImage,
  ServiceCard,
  NotFoundServiceCard,
  PropertyDescription,
  ServiceListContainer,
  SubFilterRow,
  FilterContainer,
  SearchInputWrapper,
  ServiceHeading,
  ServiceBody,
  ServiceCityFilter
} from './styles';

const useQuery = () => new URLSearchParams(useLocation().search);

const NotFoundCard = key => {
  const t = useTranslate('notFoundCard');

  return (
    <NotFoundServiceCard key={key}>
      <PropertyDescription>
        <div className='top-half'>
          <Heading size={5}>{t('heading')}</Heading>
          <Body>{t('body1')}</Body>
          <Body id='secondText'>{t('body2')}</Body>
        </div>
        <Anchor
          isSmall={true}
          text={t('giveAsuggestion')}
          href='mailto:info@55mais.pt'
        />
      </PropertyDescription>
    </NotFoundServiceCard>
  );
};

const ServicesList = () => {
  const b = useTranslate('badges');
  const translateServiceList =  useTranslate('serviceList');
  const history = useHistory();

  const query = useQuery();

  const { services, loading } = useServices();
  const { cities, city } = useCities();

  const citiesMemo = useMemo(() => {
    return cities.map(city => {
      return {
        value: city.id,
        label: city.attributes.name,
        status: city.attributes.status
      };
    });
  }, [cities]);

  const [searchIndex, setSearchIndex] = useState(null);
  const [category, setCategory] = useState(null);
  const [searchValue, setSearchValue] = useState(
    query.get('query') ? query.get('query') : ''
  );

  const filteredServices = useMemo(() => {
    if (!searchIndex) {
      return null;
    }
    let filteredItems = services;
    if (searchValue.length > 2) {
      filteredItems = searchIndex.search(searchValue.toLowerCase());
    }

    if (category) {
      filteredItems =
        category === 'Todos'
          ? filteredItems
          : filteredItems.filter(
              e => e.attributes.parent_category === category.toLowerCase()
            );
    }

    // return filteredItems.length ? filteredItems : [{ notFound: true }];
    return filteredItems.length
      ? filteredItems.filter(svc => {
          const serviceCities = svc?.attributes?.active_cities ?? [];
          return serviceCities.indexOf(Number(city?.id)) !== -1;
        })
      : [];
  }, [searchIndex, category, searchValue, services, city]);

  const t = useTranslate('servicesPage.list');
  const windowMeasures = useWindowMeasures();

  useEffect(() => {
    if (services && !loading) {
      const index = new FlexSearch({
        doc: {
          id: 'id',
          field: [
            'attributes:name',
            'attributes:description',
            'attributes:intro'
          ]
        }
      });

      services.forEach(r => {
        index.add(r);
      });

      setSearchIndex(index);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  const handleSetCategory = cat => {
    setCategory(cat);
  };

  const handleSearchValueChange = event => {
    setSearchValue(event);
  };

  return (
    <FullPage bg='alt'>
      <Page>
        {ServicesListFilter(
          windowMeasures.width < 1025,
          t,
          handleSearchValueChange,
          handleSetCategory,
          query.get('query'),
          citiesMemo
        )}

        {servicesCardListing(b, filteredServices, history, city, translateServiceList)}
      </Page>
    </FullPage>
  );
};

export default ServicesList;

const ServicesListFilter = (
  trigger,
  t,
  searchAction,
  categoryAction,
  defaultQuery,
  citiesMemo
) => {

  const translateBadges = useTranslate('badges');
  const selectOptions = [
    { label: translateBadges('todos'), value: 'Todos' },
    { label: translateBadges('casa'), value: 'Casa' },
    { label: translateBadges('acompanhamento'), value: 'Acompanhamento' },
    { label: translateBadges('aulas'), value: 'Aulas' },
    { label: translateBadges('reparações'), value: 'Reparações' }
  ];

  const { city, dispatchUpdateCity } = useCities();

  const CustomOption = props => {
    return (
      <components.Option {...props}>
        <ServiceCityFilter>
          <p className='filter-text'>{props?.data?.label}</p>
          {props?.data?.subLabel && (
            <BadgeContainer>
              <Badge
                translate={translateBadges}
                text={props?.data?.subLabel}
                category={props?.data?.subLabel}
              />
            </BadgeContainer>
          )}
        </ServiceCityFilter>
      </components.Option>
    );
  };

  return (
    <FilterContainer
      bg='alt'
      justify='flex-start'
      align='flex-start !important'
    >
      <SearchInputWrapper>
        <Icon size={16} style={{ position: 'absolute' }} name='Search' />
        <TextInput
          onChange={searchAction}
          placeholder={t('searchPlaceholder')}
          defaultValue={defaultQuery}
        />
      </SearchInputWrapper>
      <SubFilterRow align='start'>
        <Col size={3} padding={0}>
          <Select
            options={citiesMemo}
            value={{ label: city?.attributes?.name, value: city?.id }}
            onChange={event => dispatchUpdateCity(event, '/services')}
            placeholder={t('selectPlaceholder')}
            classNamePrefix='service-list-filter'
            components={{ Option: CustomOption }}
          />
        </Col>
        <Col size={3} padding={0}>
          {!trigger ? (
            <Tabs
              action={categoryAction}
              justify='flex-end'
              tabs={tabs}
              initialTabIndex={0}
            />
          ) : (
            <Select
              options={selectOptions}
              onChange={event => categoryAction(event.value)}
              placeholder={t('filterPlaceHolder')}
              classNamePrefix='service-list-filter'
            />
          )}
        </Col>
      </SubFilterRow>
    </FilterContainer>
  );
};

const servicesCardListing = (translateBadges, items, history, city, translateServiceList) => {

  const list =
    items &&
    [...items].map((item, index) => {
      const category = item?.attributes?.parent_category;
      const heading = translateServiceList(item?.attributes?.content_key+".name");
      const img = item?.attributes?.cover;
      const body = translateServiceList(item?.attributes?.content_key+".intro");
      if (!body) {
        return '';
      }
      return (
        <ServiceCard
          onClick={() =>
            history.push(`services/${item.id}?city=${city.attributes.name}`)
          }
          key={`${item}-${index}`}
          index={index}
        >
          <PropertyImage src={img} />
          <PropertyDescription>
            <BadgeContainer>
              <Badge text={category} translate={translateBadges} category={category} />
            </BadgeContainer>
            <ServiceHeading size={5}>{heading}</ServiceHeading>
            <ServiceBody>{body}</ServiceBody>
          </PropertyDescription>
        </ServiceCard>
      );
    });
  return (
    <ServiceListContainer bg='alt'>
      {list}
      <NotFoundCard />
    </ServiceListContainer>
  );
};
