import { createSelector } from "reselect";

const selectServicesState = state => state.services;

const getServices = createSelector(selectServicesState, state => {
  return state.services || [];
});
const getLoading = createSelector(selectServicesState, state => {
  return state.loading || false;
});
const getLoaded = createSelector(selectServicesState, state => {
  return state.loaded || false;
});
const getError = createSelector(selectServicesState, state => {
  return state.error || null;
});

export { getServices, getLoading, getError, getLoaded };
