import React from 'react';
import PropTypes from 'prop-types';

import { ButtonText } from 'Components/Text';
import { Icon } from 'ui-55';
import StyledButton, { IconButton } from './style';

const Button = ({
  isDisabled,
  btnType,
  text,
  isSmall,
  icon,
  action,
  isFullWidth,
  isTotalWidth,
  children,
  type,
  hasLeftAlignedIcon
}) => {
  if (text) {
    return (
      <>
        {hasLeftAlignedIcon && icon && <Icon name={icon} />}
        <StyledButton
          fullWidth={isFullWidth}
          totalWidth={isTotalWidth}
          btnType={btnType}
          type={type}
          disabled={isDisabled}
          small={isSmall}
          onClick={action}
        >
          {children}
          {text && <ButtonText>{text}</ButtonText>}
          {!hasLeftAlignedIcon && icon && <Icon name={icon} />}
        </StyledButton>
      </>
    );
  }
  if (icon) {
    return (
      <IconButton
        btnType={btnType}
        disabled={isDisabled}
        small={isSmall}
        onClick={action}
      >
        <Icon name={icon} />
      </IconButton>
    );
  }
  return <StyledButton />;
};

Button.propTypes = {
  isDisabled: PropTypes.bool,
  action: PropTypes.func,
  isSmall: PropTypes.bool,
  type: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isTotalWidth: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  btnType: PropTypes.oneOf([
    'primary',
    'secondary',
    'terceary',
    'transparent',
    'borded'
  ]),
  icon: PropTypes.string,
  hasLeftAlignedIcon: PropTypes.bool
};

Button.defaultProps = {
  type: 'primary'
};

export default Button;
