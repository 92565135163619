import styled from 'styled-components';
import ReactSelect from 'react-select';

import { media } from 'Components/Layout';
import theme from 'Theme';

export const SelectContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  color: ${props =>
    props.error
      ? props.theme.colors.feedback.error.default
      : props.theme.colors.darkBlue};
  > p {
    margin: ${props => props.theme.margin / 2}px 0;
  }
`;
export const selectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    filter: 'drop-shadow(0px 4px 13px rgba(193, 188, 183, 0.3))',
    marginTop: 3,
    borderRadius: 2
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided, { isFocused }) => ({
    ...provided,
    minWidth: '150px'
  }),
  control: (provided, { isFocused, isDisabled, ...state }) => ({
    ...provided,
    borderRadius: 2,
    height: 48,
    backgroundColor: isDisabled ? theme.colors.lightBeige : theme.colors.white,
    color: theme.colors.grey,
    boxShadow: 'none',
    borderColor: isFocused ? theme.colors.darkBlue : theme.colors.mediumBeige,
    '&:hover': {
      borderColor: isFocused ? theme.colors.darkBlue : theme.colors.mediumBeige
    }
  }),
  multiValue: styles => ({
    ...styles,
    color: 'black',
    backgroundColor: theme.colors.lightBeige
  }),

  multiValueLabel: styles => ({
    ...styles,
    color: theme.colors.muted.darkBlue
  }),
  dropdownIndicator: (provided, { selectProps }) => ({
    display: selectProps?.arrowIsHidden ? 'none' : 'initial'
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: theme.colors.darkBlue,
    ':hover': {
      backgroundColor: theme.colors.darkBlue,
      color: theme.colors.lightBeige
    }
  }),
  option: (provided, state) => {
    const color = state.isDisabled ? theme.colors.grey : theme.colors.darkBlue;
    const backgroundColor = theme.colors.white;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      color,
      transition,
      backgroundColor,
      '&:hover': {
        backgroundColor: theme.colors.lightBeige
      }
    };
  }
};

const Select = styled(ReactSelect)`
  color: ${props =>
    props.error
      ? props.theme.colors.feedback.error.default
      : props.theme.colors.mediumBeige};
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  input {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  border: ${props =>
    props.error && `1px solid ${props.theme.colors.feedback.error.default}`};
  .service-list-filter__control,
  .service-list-navbar__control {
    cursor: pointer;
  }
  .service-list-filter__menu,
  .service-list-filter__menu-list {
    width: 300px;
    .service-list-filter__option--is-disabled {
      cursor: not-allowed;
    }
    .service-list-filter__option {
      padding: 12px 12px;
    }
  }
  .service-list-navbar__control {
    border: unset;
    height: unset;
    .service-list-navbar__value-container {
      width: 'auto';
    }
  }
  .service-list-navbar__menu {
    width: 280px;
    left: -32px;
    .service-list-navbar__option--is-disabled {
      cursor: not-allowed;
    }
    .service-list-navbar__option {
      padding: 12px 12px;
    }
  }

  ${media.mobile(`
    .service-list-filter__menu, .service-list-filter__menu-list {
      width: 100vw;
      left: -20px;
    }
    .service-list-navbar__menu  {
      width: 100vw;
      left: -88px;
    }
  `)}
`;

export default Select;

export const SplitSelectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 48%);
  justify-content: space-between;
`;

export const ChildSelectContainer = styled.div``;
