import React from "react";
import { useTranslate } from "../../../features/polyglot";
import { ServicesHeader } from "./styles";
import { Row, GridCol } from "Components/Layout";
import { Heading, Body } from "Components/Text";

const Header = () => {
  const t = useTranslate("servicesPage.header");

  return (
    <ServicesHeader>
      <Row justify="center">
        <GridCol>
          <Heading styles={{ marginBottom: "0px" }} size={1}>
            {t("title")}
          </Heading>
        </GridCol>
        <Row justify="center">
          <GridCol text size={7}>
            <Body collapse>{t("subtitle")}</Body>
          </GridCol>
        </Row>
      </Row>
    </ServicesHeader>
  );
};

export default Header;
