import styled from 'styled-components';

import { GridRow } from 'Components/Layout';

const FooterPage = styled(GridRow)`
  margin-top: 48px;
`;

const RouteContainer = styled.div`
  margin-bottom: 24px;
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  justify-content: space-between;
`;

export const SubTitlesContainer = styled.div`
  h2 {
    margin-top: ${props => props.theme.margin * 3}px;
    margin-bottom: ${props => props.theme.margin}px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 50px;
  .title {
    margin-top: 0px;
    margin-bottom: ${props => props.theme.margin * 3}px;
  }
  .description {
    margin: 0px;
  }

  a {
    color: ${props => props.theme.colors.brand.red};
  }
  table {
    font-family: Muli;
    text-align: ${props => props.align || 'initial'};
    font-style: normal;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    font-size: 14px;
    line-height: 16px;
    color: ${props => props.theme.colors.darkBlue};
  }

  th,
  td {
    padding: 8px 0;
  }
  table,
  th,
  td {
    font-weight: normal;
    text-align: center;
    border: 1px solid black;
    border-collapse: collapse;
  }
  > li {
    margin-bottom: 0;
  }
`;

export { FooterPage, RouteContainer, RightContainer, AccordionContainer };
