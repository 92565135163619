import { createSelector } from 'reselect';

const selectServicesState = state => state.appointments;

const getAppointments = createSelector(selectServicesState, state => {
  return state?.appointments || null;
});

const getLoading = createSelector(selectServicesState, state => {
  return state.loading;
});

const getSubmitState = createSelector(selectServicesState, state => {
  return state.isSubmitting;
});

const getSuccessState = createSelector(selectServicesState, state => {
  return state.wasSuccessful;
});

const getErrors = createSelector(selectServicesState, state => {
  return state.errors;
});

const getAppointment = createSelector(selectServicesState, state => {
  return state.appointment;
});
const getAppointmentError = createSelector(selectServicesState, state => {
  return state.appointmentError;
});
const getAppointmentLoading = createSelector(selectServicesState, state => {
  return state.appointmentLoading;
});
// FORM
const getAppointmentForm = createSelector(selectServicesState, state => {
  return state.appointmentForm || null;
});

export {
  getAppointments,
  getAppointmentError,
  getAppointmentLoading,
  getAppointment,
  getLoading,
  getErrors,
  getSubmitState,
  getSuccessState,
  getAppointmentForm
};
