import styled from 'styled-components';

import { media, GridRow, GridCol } from 'Components/Layout';

const FooterContainer = styled.footer`
  ${media.smallDesktop(`
    padding: 0 120px;
  `)};
  ${media.mobile(`
    padding: unset;
  `)};
  background-color: ${props => props.theme.colors.darkBlue};
`;

const Footer = styled(GridRow)`
  ${media.mobile(`
  flex-flow: column;
   > div {
    width 100%;
    > svg  {
      width: 150px;
    }
  }
  `)};
  ${media.tablet(`
    padding: 40px;
  `)};

  flex-flow: row-nowrap;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
  padding: ${props => props.theme.margin * 2.5}px 0;
  background-color: ${props => props.theme.colors.darkBlue};
  color: ${props => props.theme.colors.white};
  * > a {
    color: ${props => props.theme.colors.white};
    &:visited {
      color: ${props => props.theme.colors.red};
    }
  }
`;

const BottomMargin = styled.div`
  margin-bottom: ${props => props.theme.margin * props.size}px;
`;

const TopMargin = styled.div`
  margin-top: ${props => props.theme.margin * props.size}px;
`;

const FooterText = styled.p`
  color: ${props => props.theme.colors.white};
  font-family: Space Grotesk;
  font-size: 14px;
  line-height: 19px;
  margin: ${props => (props.noMargin ? '0' : 'unset')};
  svg {
    vertical-align: middle;
    margin-right: ${props => props.theme.margin / 2}px;
  }
`;

const FooterLink = styled.a`
  display: block;
  color: ${props => props.theme.colors.white};
  font-family: Space Grotesk;
  font-size: 14px;
  line-height: 19px;
  svg {
    vertical-align: middle;
    margin-right: ${props => props.theme.margin / 2}px;
  }
`;

const CopyrightText = styled.p`
  ${media.mobile(`display: none; margin-top: 0px;`)};
  color: ${props => props.theme.colors.white};
  font-family: Space Grotesk;
  font-size: 12px;
  line-height: 16px;
  margin-top: ${props => props.theme.margin * 2}px;
`;

const LeftSection = styled(GridCol)`
  a {
    margin-bottom: ${props => props.theme.margin * 3}px;
  }
  p {
    margin-bottom: ${props => props.theme.margin * 0.75}px;
  }
`;
const RightSection = styled(GridCol)`
  display: flex;
  flex-flow: column nowrap;
  align-self: flex-start;
  > * {
    margin: ${props => props.theme.margin}px;
  }
`;

const FooterBottomLine = styled.div`
  background: linear-gradient(
    to right,
    #c5e8f2 0%,
    #5cb5e6 80%,
    #ffffff 80%,
    #ffffff 100%
  );
`;

const FooterSocialLine = styled.div`
  ${media.mobile(`
    justify-content: flex-start;
    margin-left: -10px;
  `)}
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  & > button,
  & > a {
    ${media.mobile(`padding: 10px;`)};
    border: none;
    padding: 0px;
    background-color: ${props => props.theme.colors.darkBlue};
    & > svg > path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

export {
  FooterContainer,
  Footer as StyledFooter,
  BottomMargin,
  TopMargin,
  FooterText,
  CopyrightText,
  RightSection,
  LeftSection,
  FooterBottomLine,
  FooterSocialLine,
  FooterLink
};
