import React from 'react';
import usePolymorph from 'Hooks/usePolymorph';

import List from 'Components/List';
import { GridCol } from 'Components/Layout';
import { Heading, Jumbo, Body } from 'Components/Text';
import Logo from 'Components/Logo';

import Header from './Header/Header';
import { CustomHeading } from './HowItWorks/styles';
import { BecomeHeaderGrid } from './Header/styles';
import HowItWorksTab from './HowItWorks/HowItWorksTab';
import BecomeForm from './BecomeForm/BecomeForm';
import FAQTab from './FAQs/FAQTab';

const Become = () => {
  const pageElements = {
    'become-how-it-works': props => (
      <>
        <GridCol contain src={props.image} size={6}>
          <Logo size={575} color={'becomeHeroEffect'} />
        </GridCol>
        <GridCol size={6}>
          <CustomHeading size={6}>{props.title}</CustomHeading>
          <Heading size={2}>{props.subtitle}</Heading>
          <List hasIcon data={props.list} />
        </GridCol>
      </>
    ),
    'become-header': props => (
      <BecomeHeaderGrid justify='space-between'>
        <GridCol size={5}>
          <Heading size={6}>{props.title}</Heading>
          <Jumbo size={6}>{props.subtitle}</Jumbo>
          <Body>{props.description}</Body>
        </GridCol>
        <GridCol contain src={props.image} size={5}>
          <Logo size={450} color={'becomeCoverEffect'} />
        </GridCol>
      </BecomeHeaderGrid>
    )
  };
  const extractor = {
    'become-how-it-works': (props, element) => {
      props.title = getElementValueByName(element, 'become-how-it-works-title');
      props.subtitle = getElementValueByName(
        element,
        'become-how-it-works-subtitle'
      );

      props.image = getElementValueByName(element, 'image', 'media_url');
      props.list = getElementsByName(element, 'become-how-it-works-list')
        ?.sort((e, f) => e.order - f.order)
        ?.map(el => el?.elementable?.value);
    },
    'become-header': (props, element) => {
      props.title = getElementValueByName(element, 'title');
      props.description = getElementValueByName(element, 'description');
      props.image = getElementValueByName(element, 'image', 'media_url');
      props.subtitle = getElementValueByName(element, 'subtitle');
    }
  };
  const {
    parsedContent,
    getElementValueByName,
    getElementsByName
  } = usePolymorph({
    id: 'become',
    pageElements,
    extractor
  });
  return (
    <>
      <Header content={parsedContent['become-header']}/>
      <HowItWorksTab content={parsedContent['become-how-it-works']} />
      <BecomeForm />
      <FAQTab notext />
    </>
  );
};

export default Become;
