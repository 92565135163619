import instance from './instance';

const getInvoices = userType => {
  return instance.get(`${userType}/invoices`);
};

const getInvoicesByAppointment = appointmentId => {
  return instance.get(`client/invoices?appointment_id=${appointmentId}`);
};

export { getInvoices, getInvoicesByAppointment };
