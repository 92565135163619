import { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from 'Redux/invoices';

const useInvoices = ({ userType, appointment }) => {
  const dispatch = useDispatch();
  const { getInvoices, getErrors, getLoading } = selectors;

  const { getUserInvoices, getByAppointment } = actions;
  // SHOW

  const invoices = useSelector(state => getInvoices(state), shallowEqual);
  const invoicesLoading = useSelector(state => getLoading(state), shallowEqual);
  const invoicesErrors = useSelector(state => getErrors(state), shallowEqual);

  useEffect(() => {
    if (appointment) {
      getByAppointment(dispatch, appointment?.id);
    } else {
      getUserInvoices(dispatch, userType);
    }
  }, [appointment, dispatch, getByAppointment, getUserInvoices, userType]);

  return {
    invoices,
    invoicesLoading,
    invoicesErrors
  };
};

export default useInvoices;
