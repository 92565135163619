import styled from 'styled-components';
import theme from 'theme';
import { Col, Heading, SmallBody } from 'ui-55';

import { media } from 'Components/Layout';

const orange = theme.colors.brand.orange;
const grey = theme.colors.grey;

export const StyledModal = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  border-radius: 16px;
  padding: 16px;
  position: fixed;
  left: 25vw;
  background-color: ${props => props.theme.colors.white};
  z-index: 11;
  width: 760px;
  ${media.mobile(`
    left: 0;
    right: 0;
    margin: 0px 16px;
    width: auto
  `)}
`;

export const HeadingDetail = styled(Heading)`
  margin: 0px;
  display: block;
`;

export const SubHeading = styled(Heading)`
  color: ${orange};
  margin: 0px 0px 16px 0px;;
`;

export const RefLine = styled(SmallBody)`
  width: 100%;
`;

export const Key = styled.span`
  color: ${grey};
`;

export const ButtonContainer = styled(Col)`
  max-height: 60px;
`;


export const StyledBackdrop = styled.div`
  position: fixed;
  top: -15vh;
  left: -50vw;
  background: #00000044 !important; 
  width: 150vw;
  height: 200vh;
  overflow: hidden;
  z-index: 10;
`;