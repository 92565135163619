import styled from 'styled-components';
import { Row, media } from 'Components/Layout';
import theme from 'Theme';

export const FAQHeaderContainer = styled(Row)`
  margin-bottom: ${theme.margin * 4}px;
  justify-content: center;
  p,
  h2 {
    text-align: center;
    margin-top: 0px;
  }
  ${media.mobile(`
    padding: 0px;
    div {
      width: 100%;
    }
  `)}
`;
