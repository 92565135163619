import styled from 'styled-components';

export const CityFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${({ theme }) => `${theme.margin / 2}px  auto`};
  border-bottom: ${props =>
    props.isRed ? `1px solid ${props.theme.colors.mediumBeige}` : 'unset'};
  path {
    fill: ${props => props.isRed && props.theme.colors.brand.orange};
  }
  .service-list-navbar__placeholder,
  .service-list-navbar__single-value {
    color: ${props => props.isRed && props.theme.colors.brand.orange};
    font-size: ${props => (props.isRed ? '32px' : '16px')};
    font-weight: ${props => (props.isRed ? 'bold' : 'normal')};
    line-height: ${props => (props.isRed ? '40' : '24px')};
  }
`;

export const ServiceCityFilter = styled.div`
  .filter-text {
    margin: 0;
  }
  span {
    margin: 0 !important;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: flex-start;
  > span {
    margin: 28px 0 0 0;
  }
`;
