import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import {
  StyledResetContainer,
  StyledHeaderText,
  StyledLogoHolder,
  StyledErrorHolder,
  StyledCard
} from './style';

import { TextInput, Col, Row, Loader } from 'ui-55';
import Logo from 'Components/Logo';
import { Heading, Body } from 'Components/Text';
import Button from 'Components/Button';

import { patchNewPassword } from 'Redux/auth/actions';
import useAuth from 'Hooks/useAuth';
import useStorage from 'Hooks/useStorage';

const ResetPassword = ({ action }) => {
  const t = useTranslate('login');
  const dispatch = useDispatch();
  const { error, loading, resetHadSuccess, gotoSignInPage } = useAuth();
  const { clearStorage } = useStorage();
  const history = useHistory();
  const params = queryString.parse(history?.location?.search);

  const [newPassword, setNewPassword] = useState({
    firstField: null,
    secondField: null,
    error: { firstField: null, secondField: null }
  });

  const handleSubmitPassword = () => {
    const minimumLength = 6;

    if (!newPassword.firstField) {
      return setNewPassword(prev => ({
        ...prev,
        error: { firstField: t('required') }
      }));
    }
    if (!newPassword.secondField) {
      return setNewPassword(prev => ({
        ...prev,
        error: { secondField: t('required') }
      }));
    }

    if (newPassword.firstField?.length < minimumLength) {
      return setNewPassword(prev => ({
        ...prev,
        error: { firstField: t('passwordLength') }
      }));
    }

    if (newPassword.secondField?.length < minimumLength) {
      return setNewPassword(prev => ({
        ...prev,
        error: { secondField: t('passwordLength') }
      }));
    }

    if (newPassword.firstField === newPassword.secondField) {
      if (action)
        return action({ isSet: true, password: newPassword.secondField });
      return patchNewPassword(dispatch, {
        token: params?.token,
        userType: params?.userType,
        password: newPassword.secondField
      });
    } else {
      return setNewPassword(prev => ({
        ...prev,
        error: { secondField: t('diferentPasswords') }
      }));
    }
  };

  useEffect(() => {
    if (resetHadSuccess) {
      clearStorage();
      gotoSignInPage();
    }
  }, [clearStorage, gotoSignInPage, resetHadSuccess]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <StyledResetContainer>
          <Row justify='center'>
            <Col size={9} center>
              <StyledCard error={error} padding={4}>
                <StyledLogoHolder>
                  <Logo />
                </StyledLogoHolder>
                <StyledHeaderText>
                  <Heading size={3} center>
                    {t('passwordScreenHeader')}
                  </Heading>
                  <Body>{t('passwordScreenTitle')}</Body>
                  {error && (
                    <StyledErrorHolder>
                      <Body>{t(`${Object.values(error)[0]}`)}</Body>
                    </StyledErrorHolder>
                  )}
                </StyledHeaderText>
                <TextInput
                  label={t('newPassword')}
                  type='password'
                  hasIcon
                  error={newPassword?.error?.firstField}
                  onChange={v =>
                    setNewPassword({ ...newPassword, firstField: v })
                  }
                />
                <TextInput
                  label={t('repeatNewPassword')}
                  type='password'
                  hasIcon
                  error={newPassword?.error?.secondField}
                  onChange={v =>
                    setNewPassword({ ...newPassword, secondField: v })
                  }
                />
                <Row style={{ marginTop: '25px' }} justify='center'>
                  <Button
                    type='submit'
                    text={t('redefinePassword')}
                    isFullWidth
                    action={() => handleSubmitPassword()}
                  />
                </Row>
              </StyledCard>
            </Col>
          </Row>
        </StyledResetContainer>
      )}
    </>
  );
};

ResetPassword.propTypes = {
  action: PropTypes.object
};

export default ResetPassword;
