import React from "react";
import { useTranslate } from '../../features/polyglot';

import {
  StyledCardContainer,
  StyledCardBody,
  StyledServiceColumns,
  StyledHelpContainer,
  StyledHeader
} from './styles';

import {
  Row,
  Heading,
  Avatar,
  Body
} from 'ui-55';



const HeaderRequestDetailsClient = ({user, client, appointment, appointmentForm, service, serviceType, provider}) => {
  const t = useTranslate('accountRequests');

  const calculateProviderHourPrice = () => {
    if (appointment?.attributes?.override_provider_price) {
      return `${appointment?.attributes?.override_provider_price / 100}€ ${t('hour')}`
    } else {
      return `${appointment?.attributes?.provider_hourly_rate}€ ${t('hour')}`
    }
  }

  return (
    <>
      <StyledHeader>
        <StyledServiceColumns size={8} extend>
          <StyledCardContainer>
            <Row align='center'>
              <StyledServiceColumns size={1}>
                {user?.type === 'provider' ? (
                  <Avatar user={client?.attributes} size='large' />
                ) : (
                  <Avatar user={provider?.attributes} size='large' />
                )}
              </StyledServiceColumns>
              <StyledServiceColumns size={6}>
                <Row>
                  <StyledCardBody>
                    {user?.type === 'provider' ? t('client') : t('provider')}
                  </StyledCardBody>
                  {user?.type === 'provider' ? (
                    <Body>{client?.attributes.full_name}</Body>
                  ) : (
                    <Body>{provider?.attributes.full_name}</Body>
                  )}
                </Row>
                <Row>
                  <StyledCardBody>
                    {t('freguesia')}:
                  </StyledCardBody>
                  <Body>{appointmentForm?.answers?.district_parish}</Body>
                </Row>

                {(appointment && user?.type === 'provider' && (appointment.attributes?.status === "complete" || appointment.attributes?.status === "awaiting_visit" || appointment.attributes?.status === "awaiting_meeting" || appointment.attributes?.status === "awaiting_conclusion" || appointment.attributes?.status === "awaiting_visit_payment")) &&
                  <>
                    <Row>
                      <StyledCardBody>
                        {t('address')}:
                      </StyledCardBody>
                      <Body>{client?.attributes?.address}</Body>
                    </Row>
                    <Row>
                      <StyledCardBody>
                        NIF:
                      </StyledCardBody>
                      <Body>{client?.attributes?.nif}</Body>
                    </Row>
                    <Row>
                      <StyledCardBody>
                        Valor total:
                      </StyledCardBody>
                      <Body>{(appointment?.attributes?.provider_total_price)?.toLocaleString("pt-PT", { style: "currency", currency: "EUR" })}</Body>
                    </Row>
                  </>
                }
                <Row>
                  <StyledCardBody>{t('service')}</StyledCardBody>
                  <Body>
                    {user?.type === 'client'
                      ? serviceType
                      : service?.attributes.name}
                  </Body>
                </Row>
                <Row>
                  <StyledCardBody>{t('price')}</StyledCardBody>
                  <Body>
                    {user?.type === 'client'
                      ? `${appointment?.attributes?.hourly_rate}€ ${t('hour')}`
                      : calculateProviderHourPrice()}
                  </Body>
                </Row>
              </StyledServiceColumns>
            </Row>
          </StyledCardContainer>
        </StyledServiceColumns>
        <StyledHelpContainer size={3}>
          <Heading size={6}>{t('needHelp')}</Heading>
          <a href='mailto:info@55mais.pt'>Email: info@55mais.pt</a>
          <a href='tel:+351930556575'>Telefone: (+351) 930 55 65 75</a>
        </StyledHelpContainer>
      </StyledHeader>

    </>)
};


export default HeaderRequestDetailsClient;
