import React, { useEffect, useMemo, useCallback } from 'react';
import { useTranslate } from '../../features/polyglot';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { Page, Col, Row } from 'Components/Layout';
import { Select } from 'ui-55';

import useAuth from 'Hooks/useAuth';
import { useWindowSizes } from 'Hooks/useWindowMeasures';

import {
  Preferences,
  RequestDetails,
  Invoices,
  DetailsAccess
} from './renders';
import MyServicesTabs from './MyServicesTabs';
import {
  StyledUserParagraph,
  StyledContainer,
  StyledDashboardCol
} from './styles';

const Account = () => {
  const t = useTranslate('account');

  const { user } = useAuth();
  const { isMobile } = useWindowSizes();
  const history = useHistory();
  const location = history?.location.pathname;
  const paths = {
    account: '/account',
    myServices: '/my-requests',
    invoices: '/invoices',
    preferences: '/preferences',
    requestDetail: '/request-detail/:id',
    detailsAccess: '/details'
  };
  const handleNavigation = path => history.push(paths.account + path);

  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, []);

  const routes = useMemo(
    () => [
      {
        path: paths.account + paths.myServices,
        component: () => <MyServicesTabs />
      },
      {
        path: paths.account + paths.invoices,
        component: () => <Invoices user={user} history={history} />
      },
      {
        path: paths.account + paths.preferences,
        component: () => <Preferences user={user} />
      },
      {
        path: paths.account + paths.detailsAccess,
        component: () => <DetailsAccess user={user} />
      },
      {
        path: paths.account + paths.requestDetail,
        component: () => <RequestDetails user={user} />,
        hasHelp: true
      },
      {
        component: () => <Redirect to={paths.account + paths.myServices} />
      }
    ],
    []
  );

  // const routesWithHelp = routes
  //   .filter(r => r.hasHelp)
  //   .map(r => r.path.replace(':id', ''));
  // const routeHasHelp = useMemo(
  //   () => routesWithHelp.indexOf(location.replace(/[0-9]/g, '')) !== -1,
  //   [location, routesWithHelp]
  // );
  const menuOptions = useCallback(() => {
    const menu = {
      provider: () => {
        return !isMobile ? (
          <Row style={{ flexFlow: 'column' }}>
            <StyledUserParagraph
              active={
                location === paths.account ||
                location === paths.account + paths.myServices ||
                undefined
              }
              onClick={() => {
                handleNavigation(paths.myServices);
              }}
            >
              {t('myServices')}
            </StyledUserParagraph>
            {/* <StyledUserParagraph
              active={location === paths.account + paths.invoices}
              onClick={() => {
                handleNavigation(paths.invoices);
              }}
            >
              {t('invoices')}
            </StyledUserParagraph> */}
            {
              <StyledUserParagraph
                active={
                  location === paths.account + paths.preferences || undefined
                }
                onClick={() => handleNavigation(paths.preferences)}
              >
                {t('preferences')}
              </StyledUserParagraph>
            }
          </Row>
        ) : (
          <Select
            defaultValue={{
              label: t('myServices'),
              value: 'myServices'
            }}
            onChange={opt => handleNavigation(paths[opt.value])}
            options={[
              {
                label: t('myServices'),
                value: 'myServices'
              },
              /* { label: t('invoices'), value: 'invoices' }, */
              {
                label: t('preferences'),
                value: 'preferences'
              }
            ]}
          />
        );
      },
      client: () => {
        return !isMobile ? (
          <Row style={{ flexFlow: 'column' }}>
            <StyledUserParagraph
              onClick={() => {
                handleNavigation(paths.myServices);
              }}
            >
              {t('requests')}
            </StyledUserParagraph>
            <StyledUserParagraph
              onClick={() => {
                handleNavigation(paths.invoices);
              }}
            >
              {t('invoices')}
            </StyledUserParagraph>
          </Row>
        ) : (
          <Row>
            <Select
              defaultValue={{
                label: t('requests'),
                value: 'requests'
              }}
              onChange={opt => handleNavigation(paths[opt.value])}
              options={[
                {
                  label: t('requests'),
                  value: 'requests'
                },
                {
                  label: t('invoices'),
                  value: 'invoices'
                }
              ]}
            />
          </Row>
        );
      }
    };
    return <Col size={2}>{menu?.[user?.type]?.()}</Col>;
  }, [isMobile]);

  const AppRouter = useCallback(
    ({ component: Component, path, props, exact }) => {
      return (
        <Route
          exact={exact}
          path={path}
          render={() => <Component {...props} />}
        />
      );
    },
    []
  );

  return (
    <Page>
      <StyledContainer>
        {menuOptions()}
        <StyledDashboardCol size={9}>
          <Switch>
            {routes.map((route, index) => {
              return (
                <AppRouter
                  key={route.path + index}
                  path={route.path}
                  isPrivate={route.isPrivate}
                  component={route.component}
                  exact={route.exact}
                />
              );
            })}
            <Route path='*' render={() => <Redirect to='/' />} />
          </Switch>
        </StyledDashboardCol>
        <Col size={1} />
      </StyledContainer>
    </Page>
  );
};

export default Account;
