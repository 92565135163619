import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';
import { Icon } from 'ui-55';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
  StyledFilterButton,
  CloseContainer,
  FilterTitle,
  OpenDropdown,
  DateDropdown,
  BodyDetail,
  ExtendedContainer,
  DatePickerWrapperStyles,
  Clear
} from './style';


export const getFormattedDateString = (date = new Date()) => {
  return date.toLocaleString('pt-PT').replaceAll('/', '-').split(',')[0];
}
export const getDateFromFormatted = (string) => {
  if (!string) return null;
  const split = string.split('-');
  return new Date(split[2], (split[1] - 1), split[0]);
}

const FilterButtonDate = (props) => {
  const [localStart, setLocalStart] = useState(null);
  const [localEnd, setLocalEnd] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState("Todos");
  const {
    onClose,
    onChange,
    selectValue
  } = props;
  const t = useTranslate('filters');
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (selectValue === null) {
      setSelectedLabel("Todos");
    } else {

      setSelectedLabel(selectValue?.start_date+' - '+selectValue?.end_date);
    }
  }, [selectValue]);

  const handleOpen = e => {
    setShowDropdown(!showDropdown);
  };

  const handleClose = e => {
    onClose(e);
    setShowDropdown(false);
    setSelectedLabel("Todos");
  };

  const handleChange = (dates) => {
    const [start, end] = dates;
    if (start) {
      if (end) {
        setLocalEnd(getFormattedDateString(end));
        setShowDropdown(!showDropdown);
        setSelectedLabel(getFormattedDateString(start)+" - "+getFormattedDateString(end));
        const datesLocal = {start_date: getFormattedDateString(start), end_date: getFormattedDateString(end)};
        onChange(datesLocal);
      } else {
        setLocalEnd(null);
      }
      setLocalStart(getFormattedDateString(start));
    }
  }
  const handleClear = () => {
    setLocalEnd(null);
    setLocalStart(null);
    setShowDropdown(false);
    setSelectedLabel("Todos");
  }


  return (
    <StyledFilterButton>
      <FilterTitle>
        <Icon name={'calendar'} />
        <span>{t('start_date')}:</span>
      </FilterTitle>

      <OpenDropdown onClick={handleOpen}>
        <BodyDetail>{selectedLabel}</BodyDetail>
        <Icon name='chevron-down' />
      </OpenDropdown>
      {onClose && (
        <CloseContainer onClick={() => handleClose()}>
          <Icon name='Close' />
        </CloseContainer>
      )}
      <>
        {showDropdown && (
          <DateDropdown>
            <>
              <ExtendedContainer>
                <div>
                  <DatePicker
                    selected={null}
                    startDate={getDateFromFormatted(localStart) || null}
                    endDate={getDateFromFormatted(localEnd) || null}
                    openToDate={getDateFromFormatted(localStart) || new Date()}
                    onChange={handleChange}
                    selectsRange
                    inline
                  />
                </div>

                <DatePickerWrapperStyles />
                <Clear onClick={() => handleClear()}>
                  <Icon name="Close" /> Limpar
                </Clear>
              </ExtendedContainer>
            </>
          </DateDropdown>
        )}
      </>

    </StyledFilterButton>
  );
};

FilterButtonDate.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func
};

FilterButtonDate.defaultProps = {
  // leftIcon: "kanban",
  // rightIcon: "List"
};

export default FilterButtonDate;
