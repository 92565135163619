import React from 'react';
import CardRequestClient from 'Components/CardRequest/CardRequestClient';
import CardRequestProvider from 'Components/CardRequest/CardRequestProvider';

const CardRequest = (props) => {

  return (
    <>
      {props.user?.type === 'provider' ?
        (<CardRequestProvider {...props} />) : 
        (<CardRequestClient {...props} />)}
    </>);
};


export default CardRequest;
