import { useLayoutEffect, useState } from 'react';

const useWindowMeasures = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  const windowMeasures = { width: size[0], height: size[1] };
  return windowMeasures;
};

export const useWindowSizes = () => {
  const windowSize = useWindowMeasures();

  const isMobile = windowSize?.width <= 415;
  const isTablet = windowSize?.width <= 769;
  const isSmallDesktop = windowSize?.width <= 1024;
  const isDesktop = windowSize?.width > 1024;

  return { isMobile, isTablet, isSmallDesktop, isDesktop };
};

export default useWindowMeasures;
