/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectLocale } from '../features/polyglot/polyglotSlice';
import {
  actions as contentActions,
  selectors as contentSelectors
} from 'redux/content';

import { Heading, SmallBody } from 'Components/Text';

const PROJECT_EXTRACTORS = {
  title: (props, element) => {
    props.text = element.elementable.value;
  },
  description: (props, element) => {
    props.text = element.elementable.value;
  }
};
const PROJECT_ELEMENTS = {
  sections: props => <React.Fragment key={props.key}></React.Fragment>,
  title: ({ text }) => <Heading>{text}</Heading>,
  description: ({ text }) => <SmallBody>{text}</SmallBody>
};

const extractProps = (element, name, renderer, extractor = {}) => {
  const props = {};
  const componentsPropExtractor = {
    ...PROJECT_EXTRACTORS,
    ...extractor
  };
  if (componentsPropExtractor[name]) {
    componentsPropExtractor[name](props, element, renderer);
  }
  return props;
};

const getElementValueByName = (element, name, valueKey = 'value') =>
  (element.elementable.elements ?? [])?.find(e => e.name === name)
    ?.elementable?.[valueKey];
const getElementsByName = (element, name) =>
  (element.elementable.elements ?? [])?.find(e => e.name === name)?.elementable
    ?.elements ?? [];

const usePolymorph = ({
  id,
  pageElements,
  extractor,
  skipFetch,
  dependencies = []

}) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const content = useSelector(state => contentSelectors.getContent(state, id));


  const isLoading = useSelector(state =>
    contentSelectors.getContentLoading(state, id)
  );
  const dispatchGetContent = () => {
    contentActions.getContent(dispatch, { id, lang: locale });
  };

  const [parsedContent, setParsedContent] = useState({});

  useEffect(() => {
    contentActions.getContent(dispatch, { id, lang: locale });
  }, [locale]);

  const parseCMSContent = useCallback(() => {
    const ELEMENTS = {
      ...PROJECT_ELEMENTS,
      ...pageElements
    };
    const parsedElements = {};
    const renderItems = [];
    const elements = JSON.parse(JSON.stringify(content.elements));
    const renderElements = (els, container = false) => {
      const containerItems = [];
      if (els) {
        ((Array.isArray(els) && els) || []).forEach(element => {
          const name = element.name;
          const props = {
            key: element.name + '-' + element.id,
            ...extractProps(element, name, renderElements, extractor)
          };
          if (ELEMENTS[name]) {
            if (container) {
              containerItems.push(ELEMENTS[name]?.(props) || '');
            } else {
              if (parsedElements[name]) {
                parsedElements[name] = [
                  parsedElements[name],
                  ELEMENTS[name]?.(props) || ''
                ];
              } else {
                parsedElements[name] = ELEMENTS[name]?.(props) || '';
              }
              renderItems.push(ELEMENTS[name]?.(props) || '');
            }
          }
          const elementableElements =
            element.elementable &&
            (element.elementable?.elements || []).map(el => el.name);
          const elementableHaveProps =
            Object.keys(props).filter(prop =>
              elementableElements.find(p => p === prop)
            ).length > 0;
          if (Object.keys(props).length === 1 || elementableHaveProps) {
            const sortedElements = (element?.elementable?.elements ?? []).sort(
              (a, b) => a.order - b.order
            );
            renderElements(sortedElements, container);
          }
        });
      }
      return containerItems;
    };
    const sortedElements = [...elements];
    renderElements(sortedElements);
    setParsedContent(parsedElements);
    return renderItems;
  }, [pageElements, extractor, content]);

  useEffect(() => {
    if (!content && !skipFetch) {
      dispatchGetContent();
    }
  }, []);

  useEffect(() => {
    if (content) {
      parseCMSContent();
    }
  }, [content, ...dependencies]);

  return {
    parseCMSContent,
    isLoading,
    getElementValueByName,
    getElementsByName,
    parsedContent
  };
};

export default usePolymorph;
