// Main Pages
import ABOUT_COVER from './mainPages/ABOUT_COVER.png';
import ABOUT_HERO from './mainPages/ABOUT_Hero.png';
import BECOME_COVER from './mainPages/BECOME_COVER.png';
import BECOME_HOW_IT_WORKS from './mainPages/BECOME_HOW_IT_WORKS.png';
import HOME_COVER from './mainPages/HOME_COVER.png';
import HOME_HOW_IT_WORKS from './mainPages/HOME_HOW_IT_WORKS.png';

// PARTNERS
import SANTACASA_LOGO from './partners/SANTACASA_LOGO.png';
import AGA_KHAN_LOGO from './partners/AGA_KHAN_LOGO.png';
import MILLENNIUM_BCP_LOGO from './partners/MILLENNIUM_BCP_LOGO.png';
import AGEAS_LOGO from './partners/AGEAS_LOGO.png';
import PORTO_LOGO from './partners/PORTO_LOGO.png';
import MONTEPIO_LOGO from './partners/MONTEPIO_LOGO.png';
import MATOSINHOS_LOGO from './partners/MATOSINHOS_LOGO.png';
import MAIA_LOGO from './partners/MAIA_LOGO.png';
import VALONGO_LOGO from './partners/VALONGO_LOGO.png';
import PAREDES_LOGO from './partners/PAREDES_LOGO.png';
import CASA_DO_IMPACTO_LOGO from './partners/CASA_DO_IMPACTO_LOGO.png';
import CENTRAL_HILL_LOGO from './partners/CENTRAL_HILL_LOGO.png';
import OEIRAS_VALEY_LOGO from './partners/OEIRAS_VALEY_LOGO.jpeg';

// CO-FINANCED

import PORTUGAL_INOVACAO_LOGO from './partners/PORTUGAL_INOVACAO_LOGO.png';
import POISE_LOGO from './partners/POISE_LOGO.png';
import LISBOA2020_LOGO from './partners/LISBOA2020_LOGO.png';
import PORTUGAL_2020_LOGO from './partners/PORTUGAL_2020_LOGO.png';
import UE_LOGO from './partners/UE_LOGO.png';

export const staticImages = {
  mainPage: {
    aboutCover: ABOUT_COVER,
    aboutHero: ABOUT_HERO,
    becomeCover: BECOME_COVER,
    becomeHow: BECOME_HOW_IT_WORKS,
    homeCover: HOME_COVER,
    homeHow: HOME_HOW_IT_WORKS
  },
  partners: {
    default: {
      santaCasa: SANTACASA_LOGO,
      agaKhan: AGA_KHAN_LOGO,
      millenniun: MILLENNIUM_BCP_LOGO,
      ageas: AGEAS_LOGO,
      porto: PORTO_LOGO,
      montepio: MONTEPIO_LOGO,
      matosinhos: MATOSINHOS_LOGO,
      maia: MAIA_LOGO,
      centraHill: CENTRAL_HILL_LOGO,
      valongo: VALONGO_LOGO,
      paredes: PAREDES_LOGO,
      casaImpacto: CASA_DO_IMPACTO_LOGO,
      oeirasValey: OEIRAS_VALEY_LOGO
    },
    coFinanced: {
      portugalInovacao: PORTUGAL_INOVACAO_LOGO,
      poise: POISE_LOGO,
      /* poche: POCH_LOGO, */
      lis2020: LISBOA2020_LOGO,
      pt2020: PORTUGAL_2020_LOGO,
      ue: UE_LOGO
    }
  }
};
