import styled from 'styled-components';

import { media } from 'Components/Layout';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomContainer = styled.div`
  margin: 0;
  margin-left: 5%;
`;

export const ServiceDetailContainer = styled.div`
  margin-top: -80px;
  display: ${({ openMenu }) => (openMenu ? 'none' : '')};
  .detailbottom {
    margin-left: 36px;
  }
  z-index: 9;

  ${media.tablet(`
    overflow: hidden;
    .detailbottom {
      margin-left: 0;
    }

    .what-to-expect {
      margin: 0 auto;
    }
  `)}
`;
