import React, { useState } from 'react';
import { useTranslate } from '../../../features/polyglot';
import { useHistory } from 'react-router-dom';

import CookieBanner from 'Components/CookieBanner';
import IEBanner from 'Components/IEBanner';
import Search from 'Components/Search';
import { HeaderContainer, HeaderGrid, HeaderColumn } from './styles';

const HeaderTab = ({ content }) => {
  const history = useHistory();
  const t = useTranslate('home');
  const b = useTranslate('buttons');

  const localStore = localStorage.getItem('cookies');

  const [searchValue, setSearchValue] = useState('');
  const [showIeBanner, setShowIeBanner] = useState(
    !!window.MSInputMethodContext && !!document.documentMode
  );
  const [showCookieBanner, setShowCookieBanner] = useState(!localStore);

  const handleSearchValueChange = event => {
    setSearchValue(event.target.value);
  };

  const handleSubmitSearch = () => {
    const url = searchValue.length
      ? `services?query=${searchValue}`
      : 'services';

    history.push(url);
  };

  const setCookieBanner = () => {
    localStorage.setItem('cookies', true);
    setShowCookieBanner(false);
  };

  const closeCookieBanner = () => {
    setShowCookieBanner(false);
  };

  return (
    <HeaderContainer top>
      <HeaderGrid justify='space-between'>
        <HeaderColumn size={5}>
          {content?.headerText()}
          <Search
            placeholder={t('button.placeholder')}
            onChange={handleSearchValueChange}
            translate={b}
            onSubmit={handleSubmitSearch}
            searchValue={searchValue}
          />
        </HeaderColumn>
        {content?.headerImage()}
        {showCookieBanner ? (
          <div style={{ position: 'absolute' }}>
            <CookieBanner action={setCookieBanner} close={closeCookieBanner} />
          </div>
        ) : null}
        {showIeBanner ? (
          <IEBanner close={() => setShowIeBanner(false)} />
        ) : (
          <></>
        )}
      </HeaderGrid>
    </HeaderContainer>
  );
};

export default HeaderTab;
