const cards = [
  {
    key: 'chef',
    title: 'Chef em Casa',
    list: [
      'Deslocação a casa do cliente',
      'Cozinhar um prato, entrada ou sobremesa conforme acordado com o cliente.',
      'Arrumação da cozinha no final',
      'Os ingredientes são fornecidos pelo cliente, a não ser que seja acordado o contrário entre ambas as partes'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        price: '6,00€',
        priceRate: '/ hora',
        category: 'casa'
      },
      {
        title: 'talent',
        serviceType: 'premium',
        price: '8,00€',
        priceRate: '/ hora',
        category: 'acompanhamento'
      }
    ]
  },
  {
    key: 'senior',
    title: 'Apoio a Seniores',
    listTitle: 'Dependendo das necessidades:',
    list: [
      'Deslocação a casa da pessoa e companhia (ler um livro, beber um chá, conversar).',
      'Acompanhamento a consultas médicas, análises, banco, correios, ou passeio pelo bairro'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        price: '12,00€',
        priceRate: '/ hora',
        category: 'casa',
        extraInfo: [
          {
            body: 'Utilização da sua viatura durante o serviço',
            price: '0,36',
            priceRate: '/ km'
          }
        ]
      },
      {
        title: 'talent',
        serviceType: 'premium',
        price: '8,00€',
        priceRate: '/ hora',
        category: 'acompanhamento',
        extraInfo: [
          {
            body: 'Utilização da sua viatura durante o serviço',
            price: '0,36',
            priceRate: '/ km'
          }
        ]
      }
    ]
  },
  {
    key: 'gardening',
    title: 'Jardinagem',
    listTitle: 'Dependendo das necessidades:',
    list: [
      'Pequenos trabalhos de jardinagem: rega de plantas (interiores ou exteriores), adubagem das plantas ou conselhos sobre pragas',
      'Trabalhos mais elaborados: podar árvores, cortar relva, plantar novas plantas'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        price: '6,00€',
        priceRate: '/ hora',
        category: 'casa'
      },
      {
        title: 'talent',
        serviceType: 'premium',
        price: '8,00€',
        priceRate: '/ hora',
        category: 'acompanhamento'
      }
    ]
  },
  {
    key: 'child-care',
    title: 'Apoio Familiar a Crianças',
    listTitle: 'Dependendo das necessidades:',
    list: [
      'Acompanhamento em rotinas diárias;',
      'Trabalhos de casa',
      'Deslocações',
      'Explicações',
      'Actividades extra'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        category: 'casa',
        extraInfo: [
          {
            body: 'Acompanhamento',
            price: '5,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Outras Actividades',
            price: '5,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Utilização da sua viatura durante o serviço',
            price: '0,36',
            priceRate: '/ km'
          }
        ]
      },
      {
        title: 'talent',
        serviceType: 'premium',
        category: 'acompanhamento',
        extraInfo: [
          {
            body: 'Acompanhamento',
            price: '7,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Outras Actividades',
            price: '5,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Utilização da sua viatura durante o serviço',
            price: '0,36',
            priceRate: '/ km'
          }
        ]
      }
    ],
    footNote: {
      title: 'note',
      body:
        'child-care-note-content'
    }
  },
  {
    key: 'pet-care',
    title: 'Pet Care (Visitas, passeios)',
    list: [
      'Deslocação a casa do cliente',
      'Dar de comer quando necessário',
      'Fazer companhia / brincar',
      'Limpeza de areias de gatos, se necessário',
      'Ração garantida pelo cliente'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        category: 'casa',
        price: '4,00€',
        priceRate: '/ hora'
      },
      {
        title: 'talent',
        serviceType: 'premium',
        category: 'acompanhamento',
        price: '??',
        priceRate: '/ hora'
      }
    ]
  },
  {
    key: 'pet-sitting',
    title: 'Pet Sitting (Estadia)',
    list: [
      'Estadia em casa do pet sitter',
      'Passeios, alimentação e companhia',
      'Informação contínua (fotografias ou vídeos) durante os dias',
      'Ração e acessórios (cama, brinquedos, etc.) garantidos pelo cliente'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        category: 'casa',
        extraInfo: [
          {
            body: 'Acompanhamento',
            price: '5,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Outras Actividades',
            price: '5,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Utilização da sua viatura durante o serviço',
            price: '0,36€',
            priceRate: '/ km'
          }
        ]
      },
      {
        title: 'talent',
        serviceType: 'premium',
        category: 'acompanhamento',
        extraInfo: [
          {
            body: 'Acompanhamento',
            price: '7,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Outras Actividades',
            price: '7,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Utilização da sua viatura durante o serviço',
            price: '0,36€',
            priceRate: '/ km'
          }
        ]
      }
    ]
  },
  {
    key: 'music-lessons',
    title: 'Aulas de Música',
    list: [
      'Aulas em local a combinar, aproximadamente 1h',
      'Preparação da aula e material de apoio à aula'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        category: 'casa',
        price: '14,00€',
        priceRate: '/ hora'
      },
      {
        title: 'talent',
        serviceType: 'premium',
        category: 'acompanhamento',
        price: '16,50€',
        priceRate: '/ hora'
      }
    ],
    footNote: {
      title: 'note',
      body: 'music-lessons-note-content'
    }
  },
  {
    key: 'repairs',
    title: 'Reparações',
    list: [
      'Reparações a acordar com o cliente',
      'Ferramentas do próprio especialista',
      'Materiais e matéria-prima garantidos pelo cliente'
    ],
    subServices: [
      {
        key: 'small-repairs',
        title: 'Pequenas Reparações',
        isOpen: true,
        cardHolders: [
          {
            serviceType: 'standard',
            category: 'casa'
          },
          {
            serviceType: 'premium',
            category: 'acompanhamento'
          }
        ]
      },
      {
        key: 'special-repairs',
        title: 'Reparações Especializadas',
        isOpen: true,
        cardHolders: [
          {
            serviceType: 'standard',
            category: 'casa'
          },
          {
            serviceType: 'premium',
            category: 'acompanhamento'
          }
        ]
      }
    ]
  },
  {
    key: 'cleaning',
    title: 'Limpezas',
    list: [
      'Limpezas gerais de casas ou de espaços empresariais ',
      'Organização geral de casas ou de  espaços empresariais ',
      'Materiais de limpeza garantidos pelo cliente'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        category: 'casa',
        price: '6,00€',
        priceRate: '/ hora'
      },
      {
        title: 'talent',
        serviceType: 'premium',
        category: 'acompanhamento',
        price: '7,50€',
        priceRate: '/ hora'
      }
    ]
  },
  {
    key: 'language-lessons',
    title: 'Aulas de Línguas',
    list: [
      'Aulas em local a combinar, aproximadamente 1h',
      'Preparação da aula e material de apoio à aula'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        category: 'casa',
        extraInfo: [
          {
            body: 'Básico / Intermédio',
            price: '10,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Avançado (Nativo)',
            price: '15,00€',
            priceRate: '/ hora'
          }
        ]
      },
      {
        title: 'talent',
        serviceType: 'premium',
        category: 'acompanhamento',
        extraInfo: [
          {
            body: 'Básico / Intermédio',
            price: '13,00€',
            priceRate: '/ hora'
          },
          {
            body: 'Avançado (Nativo)',
            price: '18,00€',
            priceRate: '/ hora'
          }
        ]
      }
    ]
  },
  {
    key: 'tailoring',
    title: 'Costura',
    list: [
      'Arranjos de roupa ou trabalhos com tecido a acordar com o cliente',
      'Utilização da máquina de costura da especialista e material básico (agulhas e linhas)',
      'Matéria-prima (tecido, fitas, etc.) garantida pelo cliente'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        category: 'casa',
        price: '6,00€',
        priceRate: '/ hora'
      },
      {
        title: 'talent',
        serviceType: 'premium',
        category: 'acompanhamento',
        price: '6,50€',
        priceRate: '/ hora'
      }
    ]
  },
  {
    key: 'ironing',
    title: 'Passar a Ferro',
    list: [
      'Passar a ferro a roupa indicada pelo cliente',
      'Ferro de engomar garantido pelo cliente'
    ],
    cardHolders: [
      {
        title: 'talent',
        serviceType: 'standard',
        category: 'casa',
        price: '6,00€',
        priceRate: '/ hora'
      },
      {
        title: 'talent',
        serviceType: 'premium',
        category: 'acompanhamento',
        price: '7,50€',
        priceRate: '/ hora'
      }
    ]
  }
];

export default cards;

const nameToKeyDic = {
  'Aulas de Línguas': 'language-lessons',
  Costura: 'tailoring',
  'Passar a Ferro': 'ironing',
  Jardinagem: 'gardening',
  Canalização: 'plumbing',
  Reparações: 'repairs',
  Limpezas: 'cleaning',
  'Aulas de Música': 'music-lessons',
  'Chef em Casa': 'chef',
  'Pet care (Visitas, passeios)': 'pet-care',
  'Experiência Gastronómica': 'gastro-experience',
  'Apoio Familiar a Crianças': 'child-care',
  'Pet sitting (Estadia)': 'pet-sitting',
  'Apoio a Seniores': 'senior'
};

export const makePrices = (activeServices, activePrices, t) => {
  const prices = {
    undefined: {
      unidades: '',
      standard: '',
      premium: '',
      km: ''
    }
  };

  activeServices.forEach(service => {
    const templateCard = cards.find(c => c.key === nameToKeyDic[service.name]);
    const price = activePrices.find(p => p.service_id === service.id);

    if (price && templateCard) {
      const values = {
        unidades: `${t(price.unit)}`,
        standard: `${price.specialist_price_standard?.toFixed(2)} €`,
        premium: `${price.specialist_price_premium?.toFixed(2)} €`,
        km: `${price.km_price?.toFixed(2)}€/km`
      };

      if (templateCard.key === 'repairs') {
        prices['small-repairs'] = values;
        prices['special-repairs'] = values;
      } else {
        prices[templateCard.key] = values;
      }
    }
    // Experiência Gastronómica & Canalização dont have cards
  });

  return prices;
};
