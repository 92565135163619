import React, { useEffect, useState } from 'react';
import { useTranslate } from '../../../../features/polyglot';
import { useParams, useHistory } from 'react-router-dom';
import { updateProviderContacts } from 'Redux/provider/actions';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Loader,
  Heading,
  Icon,
  Body,
  SmallBody,
  Col,
  Button,
  TextInput,
  Form
} from 'ui-55';
import useAppointments from 'Hooks/useAppointments';

import {
  StyledCardBody,
  StyledServiceTitle,
  StyledServiceColumns,
  StyledIcon,
  StyledCardRow,
  BackBtn,
  StyledClientDetails,
  StyledTabs,
  StyledProvideAcceptArea,
  StyledButtonRow,
  InnerRow,
  FormContainer,
  ButtonRow
} from './styles';
import Invoices from '../Invoices';
import HeaderRequestDetailsProvider from 'Components/HeaderRequestDetailsProvider';
import HeaderRequestDetailsClient from 'Components/HeaderRequestDetailsClient';

const AppointmentDetails = ({ user }) => {
  const { id } = useParams();
  const history = useHistory();
  const t = useTranslate('accountRequests');
  const translateForm = useTranslate("forms");
  const dispatch = useDispatch();
  const { appointment, appointmentData, appointmentForm, appointmentLoading } = useAppointments({
    id,
    user
  });

  const providersState = useSelector(state => state?.providers);

  const [estimatedHours, setEstimatedHours] = useState('');

  const [questions, setQuestions] = useState([]);


  const slug = appointmentData?.included?.find(e => e?.type === 'service')
    ?.attributes?.slug;

  const contactedId = appointment?.attributes?.contact?.id;
  const hasProvider = appointment?.relationships?.provider?.data;

  const needsVisitServices = [
    'gardening',
    'electrician',
    'bricolage',
    'plumbing'
  ];

  const service = appointmentData?.included?.find(
    e =>
      e.type === 'service' &&
      appointment?.relationships?.service?.data?.id &&
      e.id
  );

  const serviceType = appointmentData?.included?.find(
    e =>
      e.type === 'lead' && appointment?.relationships?.service?.data?.id && e.id
  )?.attributes['service_type'];

  const client = appointmentData?.included?.find(
    e =>
      e.type === 'client' &&
      appointment?.relationships?.client?.data?.id &&
      e.id
  );

  const provider = appointmentData?.included?.find(
    e =>
      e.type === 'provider' &&
      appointment?.relationships?.provider?.data?.id &&
      e.id
  );

  useEffect(() => {
    let tmpQuestions = [];

    if (appointmentForm && appointmentForm?.questions) {

      let tmpAppointment = {}
      for (let i = 0; i < appointmentForm?.questions.length; i++) {

        tmpAppointment = { ...appointmentForm?.questions[i], isDisabled: true }
        if (appointmentForm?.questions[i].key === "offer-type") {
          tmpAppointment.urgentPrices = []
        }
        if (appointmentForm?.questions[i].key === "files") {
          if(appointmentForm?.answers?.files?.length === 0){
            continue;
          }
        }

        tmpQuestions.push(tmpAppointment)
      }
    }
    setQuestions(tmpQuestions)

  }, [appointmentForm]);



  useEffect(() => {
    if (!providersState?.isSubmitting && providersState?.wasSuccessful) {
      window.location.reload();
    }
  }, [providersState]);

  const renderHours = () => {
    // const startHour = new Date(appointment?.attributes?.start_date).getHours();
    // const endHour = startHour + 2;
    const startHour = appointment?.attributes?.start_hour
    const endHour = appointment?.attributes?.end_hour

    if (endHour) {
      return <Body>{`${startHour} - ${endHour}`}</Body>;
    } else {
      return <Body>{`${startHour}`}</Body>;
    }
  };

  const calculateProviderPrice = () => {
    if (appointment?.attributes?.override_provider_price) {
      const hours = appointment?.attributes?.override_provider_price * appointment?.attributes?.estimated_hours
      const urgent = appointment?.attributes?.is_urgent ?
        appointment?.attributes?.provider_urgent_price * appointment?.attributes?.estimated_hours : 0
      const kilometers = appointment?.attributes?.km_price * appointment?.attributes?.total_kms

      return (hours + urgent + kilometers) / 100
    } else {
      return appointment?.attributes?.provider_total_price
    }
  }
  const renderTotalPrice = (userType) => {
    if (userType === 'client') {
      return (
        appointment?.attributes?.total_price &&
        `${appointment?.attributes?.total_price}€ ${t('plusTax')}`
      )
    } else {
      return (
        appointment?.attributes?.provider_total_price &&
        `${calculateProviderPrice()}€ ${t('taxIncluded')}`
      )
    }
  }
  const renderDetails = (userType, service) => {
    const dateOptions = {
      year: 'numeric',
      month: 'long'
    };

    return (
      <Row>
        <StyledCardRow justify='space-between' flow='row nowrap'>
          <InnerRow>
            <StyledServiceColumns justify='center' size={1}>
              <StyledIcon align='center' alt name='Hourglass' />
            </StyledServiceColumns>
            <StyledServiceColumns size={4} align='flex-start'>
              <StyledCardBody faded>{t('duration')}:</StyledCardBody>
              <Body>
                {appointment?.attributes?.estimated_hours} {t('hours')}
              </Body>
            </StyledServiceColumns>
          </InnerRow>
          <InnerRow>
            <StyledServiceColumns justify='center' size={1}>
              <StyledIcon align='center' alt name='calendar' />
            </StyledServiceColumns>
            <StyledServiceColumns size={4}>
              <StyledCardBody faded>{t('scheduledDay')}:</StyledCardBody>
              <Body>
                {appointment?.attributes?.delivered_on &&
                  new Date(
                    appointment?.attributes?.delivered_on
                  ).toLocaleDateString('PT-PT')}
              </Body>
            </StyledServiceColumns>
          </InnerRow>
        </StyledCardRow>
        <StyledCardRow justify='space-between' flow='row nowrap'>
          <InnerRow>
            <StyledServiceColumns justify='center' size={1}>
              <StyledIcon align='center' alt name='Total' />
            </StyledServiceColumns>
            <StyledServiceColumns size={4}>
              <StyledCardBody faded>{t('total')}:</StyledCardBody>
              <Body>
                {renderTotalPrice(userType)}
              </Body>
            </StyledServiceColumns>
          </InnerRow>
          <InnerRow>
            <StyledServiceColumns justify='center' size={1}>
              <StyledIcon align='center' alt name='Clock' />
            </StyledServiceColumns>
            <StyledServiceColumns size={4}>
              <StyledCardBody faded>{t('scheduledHour')}</StyledCardBody>
              {renderHours()}
            </StyledServiceColumns>
          </InnerRow>
        </StyledCardRow>
        {/* client service detail */}
        {userType === 'client' && (
          <StyledClientDetails>
            <Row>
              <Col size={5} align='flex-start'>
                <SmallBody>{t('month')}</SmallBody>
              </Col>
              <Col size={1} align='flex-end'>
                <SmallBody>{t('hours')}</SmallBody>
              </Col>
            </Row>

            <Row>
              <Col size={5} align='flex-start'>
                <Body className='client-service-month-detail'>
                  {appointment?.attributes?.start_date &&
                    new Date(
                      appointment?.attributes?.start_date
                    ).toLocaleDateString('PT-PT', dateOptions)}
                </Body>
              </Col>
              <Col size={1} align='flex-end'>
                <SmallBody>
                  {appointment?.attributes?.estimated_hours} {t('hours')}
                </SmallBody>
              </Col>
            </Row>
          </StyledClientDetails>
        )}
      </Row>
    );
  };

  const renderTabs = userType => {
    const tabs = {
      client: [
        {
          name: t('requestDetails'),
          children: () => renderDetails(user?.type, service)
        },
        {
          name: t('payments'),
          children: () => (
            <Invoices appointment={appointment} history={history} user={user} />
          )
        }
      ],
      provider: [
        {
          name: t('requestDetails'),
          children: () => renderDetails(user?.type, service)
        }
      ]
    };
    return tabs[userType];
  };
  const handleServiceInteress = acceptance => {
    const payload = {
      id: contactedId,
      available: acceptance,
      estimated_hours: estimatedHours
    };
    updateProviderContacts(dispatch, payload);
  };

  const RequestDetailsProvider = () => {
    return (
      <>
        <BackBtn onClick={() => history.goBack()}>
          <Icon name='chevron-left'></Icon>
          <StyledServiceTitle isNavigation>
            {service?.attributes?.name}
          </StyledServiceTitle>
        </BackBtn>

         <HeaderRequestDetailsProvider
          user={user}
          client={client}
          appointment={appointment}
          appointmentForm={appointmentForm}
          service={service}
          serviceType={serviceType}
        />
        <Row>
          <StyledServiceColumns size={7}>
            <StyledTabs initialTabIndex={0} tabs={renderTabs(user?.type)} />
          </StyledServiceColumns>
        </Row>

        <StyledProvideAcceptArea />

        {user?.type === 'provider' &&
          <FormContainer
            justify='start'
            width="100%"
          >
            <Form
              translate={translateForm}
              onError={() => { }}
              onSubmit={() => { }}
              questions={questions}
              answers={appointmentForm?.answers}
              context='LP'
              hiddenFields={['login-buttons', 'password', 'service-materials', 'service-type', 'urgent-service', 'premium-service']}
              hideSubmit
            />
          </FormContainer>
        }
        {!hasProvider && user?.type === 'provider' && (
          <StyledProvideAcceptArea>
            {needsVisitServices?.includes(slug) && (
              <>
                <Heading size={6}>{t('serviceEstimation')}</Heading>
                <Row>
                  <TextInput
                    defaultValue={
                      appointment?.attributes?.contact?.estimated_hours
                    }
                    type='number'
                    minDate={0}
                    onChange={vals => setEstimatedHours(vals)}
                  />
                </Row>
              </>
            )}
          </StyledProvideAcceptArea>
        )}

        {/* {appointment?.attributes?.contact?.available != null ? (
            (<>{appointment?.attributes?.contact?.available ?
               "Aceito" : "não aceito"}</>)
        ) : (<>Não respondido</>)} */}

        {!hasProvider && user?.type === 'provider' && (
          <StyledProvideAcceptArea>
            <Row>
              <Body>
                {appointment?.attributes?.contact?.available
                  ? t('rejectText')
                  : t('acceptText')}
              </Body>
            </Row>
            <ButtonRow>
              <StyledButtonRow isAccepted={false}>
                <Button
                  text={t('accept')}
                  action={() =>
                    handleServiceInteress(true)
                  }
                />
              </StyledButtonRow>
              <StyledButtonRow isAccepted={true}>
                <Button
                  text={t('reject')}
                  action={() => {
                    handleServiceInteress(false)
                    history.push('/account/my-requests')
                  }}
                />
              </StyledButtonRow>
            </ButtonRow>
          </StyledProvideAcceptArea>
        )}


      </>);
  }

  const RequestDetailsClient = () => {
    return (
      <>
        <BackBtn onClick={() => history.goBack()}>
          <Icon name='chevron-left'></Icon>
          <StyledServiceTitle isNavigation>
            {service?.attributes?.name}
          </StyledServiceTitle>
        </BackBtn>
        <HeaderRequestDetailsClient
          user={user}
          client={client}
          appointment={appointment}
          appointmentForm={appointmentForm}
          service={service}
          serviceType={serviceType}
          provider={provider}
        />
        <Row>
          <StyledServiceColumns size={7}>
            <StyledTabs initialTabIndex={0} tabs={renderTabs(user?.type)} />
          </StyledServiceColumns>
        </Row>

        <StyledProvideAcceptArea />

        {user?.type === 'provider' &&
          <FormContainer
            justify='start'
            width="100%"
          >
            <Form
              onError={() => { }}
              onSubmit={() => { }}
              questions={questions}
              answers={appointmentForm?.answers}
              context='LP'
              hiddenFields={['login-buttons', 'password', 'service-materials', 'service-type', 'urgent-service', 'premium-service']}
              hideSubmit
            />
          </FormContainer>
        }
        {!hasProvider && user?.type === 'provider' && (
          <StyledProvideAcceptArea>
            {needsVisitServices?.includes(slug) && (
              <>
                <Heading size={6}>{t('serviceEstimation')}</Heading>
                <Row>
                  <TextInput
                    defaultValue={
                      appointment?.attributes?.contact?.estimated_hours
                    }
                    type='number'
                    minDate={0}
                    onChange={vals => setEstimatedHours(vals)}
                  />
                </Row>
              </>
            )}
          </StyledProvideAcceptArea>
        )}

        {!hasProvider && user?.type === 'provider' && (
          <StyledProvideAcceptArea>
            <Row>
              <Body>
                {appointment?.attributes?.contact?.available
                  ? t('rejectText')
                  : t('acceptText')}
              </Body>
            </Row>
            <ButtonRow>
              <StyledButtonRow isAccepted={false}>
                <Button
                  text={t('accept')}
                  action={() =>
                    handleServiceInteress(true)
                  }
                />
              </StyledButtonRow>
              <StyledButtonRow isAccepted={true}>
                <Button
                  text={t('reject')}
                  action={() => {
                    handleServiceInteress(false)
                    history.push('/account/my-requests')
                  }}
                />
              </StyledButtonRow>
            </ButtonRow>
          </StyledProvideAcceptArea>
        )}


      </>);
  }
  return !appointmentLoading ? (

    (user.type === 'provider') ? (<RequestDetailsProvider/>): (<RequestDetailsClient/>)
  ) : (
    <Loader />
  );
};

export default AppointmentDetails;
