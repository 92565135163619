import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ButtonText } from 'Components/Text';
import { TextInput } from 'ui-55';
import {useTranslate} from "../../features/polyglot"
import { TabContainer, TabButton, TabGroup, ChildrenContainer } from './style';

const Tabs = ({
  action,
  type,
  childAction,
  justify,
  tabs,
  initialTabIndex
}) => {
  const [selectedTab, setSelectedTab] = useState(initialTabIndex);
  const translateBadges = useTranslate('badges');
  useEffect(() => {
    setSelectedTab(initialTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);
  return (
    <TabContainer>
      <TabGroup justify={justify}>
        {tabs && tabs.length
          ? tabs.map((tab, i) => (
              <TabButton
                type='button'
                isSelected={i === selectedTab}
                onClick={event => {
                  if (action) {
                    action(tab.name);
                  }
                  setSelectedTab(i);
                }}
                key={tabs + tab.name + i}
              >
                <ButtonText>{translateBadges(tab.label)}</ButtonText>
              </TabButton>
            ))
          : ''}
      </TabGroup>
      {tabs &&
        tabs[selectedTab] &&
        tabs[selectedTab].children &&
        renderTabChildren(tabs[selectedTab].children, childAction)}
    </TabContainer>
  );
};

const renderTabChildren = (children, childAction) => {
  const child =
    children &&
    children.map((child, index) => {
      switch (child.type) {
        default:
          return (
            <ChildrenContainer key={child.name}>
              <TextInput
                key={child.name}
                onChange={childAction}
                name={child.name}
                label={child.label}
              />
            </ChildrenContainer>
          );
      }
    });
  return child;
};
Tabs.propTypes = {
  action: PropTypes.func,
  justify: PropTypes.string,
  type: PropTypes.string,
  childAction: PropTypes.func,
  initialTabIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      children: PropTypes.function
    })
  )
};

Tabs.defaultProps = {
  type: 'primary'
};

export default Tabs;
