import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';
import { Body } from 'Components/Text';

import { Icon } from 'ui-55';

import StyledBanner from './style';

const CookieBanner = ({ close }) => {
  const t = useTranslate('cookieBanner');
  return (
    <StyledBanner>
      <div role='presentation' className='close' onClick={close}>
        <Icon name='Close' />
        <Body>{`${t('browserWarning')} `}</Body>
      </div>
      <Body>
        <a target='blank' href='https://www.google.com/chrome/'>
          Recomendado
        </a>
      </Body>
    </StyledBanner>
  );
};

CookieBanner.propTypes = {
  close: PropTypes.func
};

export default CookieBanner;
