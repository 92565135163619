import styled from 'styled-components';
import { media, Hero, GridRow, Page } from 'Components/Layout';
import { SmallBody } from 'components/Text';
import theme from 'Theme';

export const AboutHeaderHero = styled(Hero)`
  background: linear-gradient(
    to right,
    ${theme.colors.brand.lightBlue} 50%,
    ${theme.colors.white} 50%
  );
  ${media.smallDesktop(`
    margin-bottom: ${theme.margin * 5}px;
    height: auto;
    top: 0px;
    & > div {
      margin-top: 20px;
    }
    h1 {
      font-size: 32px;
      line-height: 40px;
    }
    svg {
      width: 300px;
    }
  `)}
  ${media.mobile(`
    background: ${theme.colors.white};
    svg {
      width: 200px;
    }
  `)}
`;

export const AboutHeaderGrid = styled(GridRow)`
  background: linear-gradient(
    to right,
    ${theme.colors.brand.lightBlue} 50%,
    ${theme.colors.white} 50%
  );

  ${media.mobile(`
    flex-direction: column;
    background: ${theme.colors.white};

    & > div {
      width: 100%;
      align-self: center;
    }

    & > div  > div {
      display: flex;
      justify-content: center;
    }

    h1 {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }
    h2 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  `)}
`;

export const SubHeaderContainer = styled(GridRow)`
  ${media.smallDesktop(`
    flex-direction: column;
  `)}
`;

export const CardContainer = styled(GridRow)`
  justify-content: space-evenly;
  & > div {
    width: 368px;
    margin: 0px 15px 0px 0px;
    padding: 20px;
    align-items: flex-start;
    border: ${(props) => (props.borded ? '1px solid #D2CCC6' : 'none')};
    box-sizing: ${(props) => (props.borded ? 'border-box' : 'none')};
    border-radius: ${(props) => (props.borded ? '20px' : '10px')};
    text-align: left;
  }

  ${media.tablet(`
    flex-direction: row;
    & > div {
      width: 266px;
      p {
        font-size: 16px;
        line-height: 24px;
      }
      height: auto;
    }
  `)};

  ${media.mobile(`
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    & > div {
      width: auto !important;
      margin: 5px;
    }
  `)}
`;

export const ImageContainer = styled(GridRow)`
  flex-direction: column;

  ${media.smallDesktop(`
    & > div {
      justify-content: space-evenly;
    }
  `)}

  img {
    margin-top: ${theme.margin * 2}px;
    margin-bottom: ${theme.margin * 5}px;

    align-self: center;
    width: 1150px;
    border-radius: 2px;
    ${media.smallDesktop(`
    width: 785px;
    `)};

    ${media.tablet(`
    width: 672px;

    `)};

    ${media.mobile(`
      width: 250px;
  `)};
  }
`;

export const SpecialistsSection = styled.div`
  > nav {
    margin-top: 0px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  margin: ${theme.margin} 0px;
`;

export const SubHeaderTextContainer = styled.div`
  p {
    text-align: center;
  }
  margin-bottom: ${(props) => props.theme.margin * 3 + 8}px;
`;
export const SpecialistItemsListing = styled.nav`
  width: 368px;
  height: 185px;
  text-align: center;
  margin-top: 25px;
  ${media.smallDesktop(`
      width: auto;
      svg {
        width: 45px;
    }
    h2 {
      margin-top: 12px;
      margin-bottom: 0px;
    }
    p {
      margin-top: 4px;
    }
  `)};

  h2 {
    margin-top: 12px;
    margin-bottom: 0px;
  }
  p {
    margin-top: 4px;
  }
`;

export const BottomSectionContainer = styled(Page)`
  ${media.smallDesktop(`
      div {
        flex-flow: column;
        width: auto;
        margin: 10px 0px;
      }
      & div > div {
        flex-flow: row wrap;
      }
  `)};
  ${media.mobile(`
    text-align: center;

`)};
`;

export const PartnersSection = styled.div`

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  grid-gap: 24px;
  align-items: center;
    img {
      max-width: 172px;
      max-height: 172px;
    }
    ${media.tablet(`
      width: 60px;
    `)};
  }

  ${media.smallDesktop(`
  img {
    width: 100px;
  }
`)};

  ${media.tablet(`
  img {
    width: 80px;
  }
  grid-gap: 2em;
`)};

  ${media.mobile(`
  grid-template-columns: repeat(3, 0fr);
  & > div {
    margin: 0px;
  }
  img {
    width: 60px;
  }
`)};
`;

export const SplitSection = styled.div`
  border-bottom: 2px solid ${theme.colors.mediumBeige};
  max-width: 200px;
  margin: 24px auto !important;
  ${media.mobile(`
    width: 100%;
  `)}
`;
export const CoFinancedText = styled(SmallBody)`
  ${media.mobile(`
    top: 25px;
    font-size: 12px;
  `)}
  margin: 0;
  position: relative;
  top: 50px;
  left: 23%;
`;

export const CoFinancedPartners = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img {
    max-width: 172px;
    max-height: 172px;
    ${media.mobile(`
    width: 60px;
  `)};
  }
  ${media.mobile(`
    margin-bottom: 24px;
  `)}
`;
