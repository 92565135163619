import { ACTION_TYPES } from './actions';
import { ACTION_TYPES as PROVIDER_ACTION_TYPES } from 'Redux/provider/actions';
import useStorage from 'Hooks/useStorage';
const { user } = useStorage();
const initialState = {
  loading: false,
  loaded: false,
  error: null,
  resetSuccess: false,
  user
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_USER:
    case ACTION_TYPES.LOGOUT_USER:
      return {
        ...state,
        loading: true,
        user: null,
        error: null
      };
    case ACTION_TYPES.LOGIN_USER_FAIL:
    case ACTION_TYPES.LOGOUT_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload?.data,
        loading: false,
        loaded: true
      };
    case ACTION_TYPES.LOGOUT_USER_SUCCESS:
      return {
        ...initialState,
        user: null
      };
    case ACTION_TYPES.RESET_USER_PASSWORD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case ACTION_TYPES.RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        resetSuccess: true
      };
    case ACTION_TYPES.RESET_USER_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        resetSuccess: false,
        error: 'failed'
      };
    case ACTION_TYPES.PATCH_USER_NEW_PASSWORD:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.PATCH_USER_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetSuccess: true,
        user: false
      };
    case ACTION_TYPES.PATCH_USER_NEW_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.error
      };
    case ACTION_TYPES.SIGN_UP_CLIENT:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.SIGN_UP_CLIENT_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false
      };
    case ACTION_TYPES.SIGN_UP_CLIENT_FAIL:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action?.errors?.meta
          ? { ...action?.errors?.meta?.message }
          : action?.errors
      };
    case PROVIDER_ACTION_TYPES.UPDATE_PROVIDER_FORM_SUCESSFUL:
      return {
        ...state,
        user: {
          ...action.payload.data,
          ...state.user,
          attributes: action?.payload?.data?.attributes
        }
      };
    case ACTION_TYPES.UPDATE_USER:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: {
          ...action.payload.data,
          ...state.user,
          attributes: action?.payload?.data?.attributes
        }
      };
    case ACTION_TYPES.UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action?.error
      };
    default:
      return state;
  }
};
