import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from 'redux/store';

import './wdyr';
import { GlobalStyles } from 'Theme';

import App from './App';

import * as serviceWorker from './serviceWorker';

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false // enable logs
};
ReactPixel.init(process.env.REACT_APP_FB_PIXEL, options);

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyles />
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
