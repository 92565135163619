import styled from 'styled-components';
import { Col, media, Body } from 'ui-55';

export const StyledBody = styled(Body)`
  color: ${props =>
    props.alt ? props.theme.colors.grey : props.theme.colors.darkBlue};
  ${media.mobile(`margin: 0; margin-left: 0px !important`)};
`;

export const FormContainer = styled(Col)`
  padding: 0;
  > div {
    max-width: 100%;
  }
  form > div > div > div {
    width: 95%;
    ${media.mobile(`width: 100%;`)}
  }

  button[type='submit'] {
    align-self: flex-start;
    padding: 11px;
    margin: 18px 0px 48px;
  }
`;
