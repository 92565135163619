import styled from 'styled-components';
import theme from 'Theme';
import { media } from 'Components/Layout';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.colors.white};
  height: calc(100vh - 4em);
  text-align: left;
  padding: 2rem 2rem;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 60px;

  a {
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    transition: color 0.3s linear;

    ${media.mobile(`
      font-size: 1.5rem;
      text-align: center;
    `)}
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  margin-top: 30%;
  button {
    margin-top: 5%;
    height: 60px;
  }
`;

export const LisbonContainer = styled.div`
  display: flex;
  flex-direction: row;
  svg {
    margin-right: 2%;
    width: 20px;
  }
  a {
    padding-top: 0;
    margin-right: 2%;
  }
`;

export const CustomLink = styled.span`
  cursor: pointer;
  &:hover {
    color: ${theme.colors.brand.lighter};
  }
  margin-top: ${theme.margin}px;
  ${({ color }) =>
    media.tablet(`
    font-family: Space Grotesk;
    font-weight: light;
    font-size: 14px !important;
    padding-top: 10%;
    color: ${theme.colors[color]};

`)};
`;

export const NavModal = styled.div`
  background-color: ${theme.colors.mediumBeige}66;
  display: flex;
  flex-flow: row;
  position: fixed;
  top: 0;
  padding-left: 55%;
  transition: all 0.5s ease-in-out;
  ${({ openMenu }) => `width: ${openMenu ? '100%' : '0%'}`};
  ${({ openMenu }) => `right: ${openMenu ? '0%' : '-100%'}`};
  box-sizing: border-box;
  ${media.tablet(`
    padding-left: 55%;
  `)}

  ${media.mobile(`
    padding-left: 32px;
    transition: all 0.50s ease-in-out;
  `)}
`;
