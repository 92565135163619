import React from 'react';
import { RightContainer } from './styles';
import { Heading } from 'Components/Text';
import { useTranslate } from '../../features/polyglot';

const TransparencyPage = () => {
  const translate = useTranslate('footerPages.Transparency');

  return (
    <RightContainer>
        <Heading size={1} className='title'>
            {translate('title')}
        </Heading>
        <Heading style={{ margin: 0 }} size={3} className='description'>
            {translate('firstSection.title')}
        </Heading>
        <h2>{translate('direction')}</h2>
        <p><b>Elena Durán</b><br/>Presidente</p>
        <p><b>Ana Lia de Mattos</b><br/>Vice-Presidente</p>
        <p><b>João Miguel de Faria Westwood</b><br/>Tesoureiro</p>
        <h2>{translate('generalAssembly')}</h2>
        <p><b>Francisco Bello</b><br/>Presidente</p>
        <p><b>Pablo Parras Durán</b><br/>Primeiro secretário</p>
        <p><b>Filipa Teixeira</b><br/>Segundo-secretário</p>
        <h2>{translate('fiscalBoard')}</h2>
        <p><b>Maria Isabel Westwood</b><br/>Presidente</p>
        <p><b>Cristina Rubina Freitas Fernandes</b><br/>Primeira vogal</p>
        <p><b>Domingos Manel Da Silva do Ó dos Santos</b><br/>Segundo vogal</p>
        <br/><br/><br/>
        <h2>{translate('institutionalDocuments')}</h2>
        <p><a href="/Escritura_publica_estatutos.pdf" download>{translate('publicDeed')}</a></p>
        <p><a href="Publicacao_Portal_da_Justica.pdf" download>{translate('publicationOnTheJusticePortal')}</a></p>
        <h2>{translate('reports')}</h2>
        <p><a href="55maisRELATORIO_ATIVIDADES_e_CONTAS_2018.pdf" download>2018</a></p>
        <p><a href="55maisRELATORIO_ATIVIDADES_e_CONTAS_2019.pdf" download>2019</a></p>
        <p><a href="55maisRELATORIO_ATIVIDADES_e_CONTAS_2020.pdf" download>2020</a></p>
        <p><a href="55mai0sRELATORIO_ATIVIDADES_e_CONTAS_2021.pdf" download>2021</a></p>
        <p><a href="55mai0sRELATORIO_ATIVIDADES_e_CONTAS_2022.pdf" download>2022</a></p>
        <h2>{translate('impactReport')}</h2>
        <p><a href="/55mais_Relatorio_Impacto.pdf" download>{translate('intermediateImpactReport')}</a></p>
    </RightContainer>
  );
};

export default TransparencyPage;
