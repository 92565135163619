import React from "react";
import { Container } from "./styles";

const HowItWorksTab = ({content}) => {
  return (
    <Container align="center">
      {content}
    </Container>
  );
};

export default HowItWorksTab;
