import styled from 'styled-components';
import { media } from 'Components/Layout';

const StyledCookieBanner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.colors.white};
  width: 368px;
  max-height: 395px;
  padding: 24px;
  box-shadow: 0px 4px 13px rgba(193, 188, 183, 0.3);
  border-radius: 16px;
  font-size: 16px;
  line-height: 24px;
  z-index: 99;

  div.close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &:hover {
      cursor: pointer;
    }

    svg {
      width: auto;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: left;
    font-weight: bold;
  }

  a {
    color: ${props => props.theme.colors.brand.orange} !important;
    &.configure-cookies {
      display: block;
      margin-top: 20px;
    }
  }

  div.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 100%;
    }
  }

  ${media.mobile(`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 48px);
    max-height: 100%;
  `)}
`;

export default StyledCookieBanner;
