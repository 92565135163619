import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { selectLocale } from '../../features/polyglot/polyglotSlice';
import { useTranslate } from '../../features/polyglot';
import { Row } from 'Components/Layout';
import { Body, Heading, Link } from 'Components/Text';
import { Col, Form, Icon, Loader, Tabs } from 'ui-55';
import Logo from 'Components/Logo';
import Button from 'Components/Button';

import useStorage from 'Hooks/useStorage';
import useAuth from 'Hooks/useAuth';
import {
  customLinkStyle,
  StyledBackButtoContainer,
  StyledCard,
  StyledErrorHolder,
  StyledFormContainer,
  StyledLinkContainer,
  StyledLoginContainer,
  StyledTabsRow
} from './style';
import { loginSchemas } from './loginSchemas';

const Login = () => {
  const translateForm = useTranslate("forms");
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const history = useHistory();
  const { user } = useStorage();
  const t = useTranslate('login');
  const transResponse = useTranslate('responses');

  const {
    signInAndRedirect,
    clientSignUp,
    authError,
    redirectTo,
    authLoading,
    resetPassword,
    resetHadSuccess
  } = useAuth();

  const [errors, setErrors] = useState(authError);
  const [componentToRender, setComponentToRender] = useState('login');
  const [userType, setUserType] = useState('client');

  useEffect(() => {
    if (authError) {
      setErrors(authError);
    }
    if (user) {
      redirectTo();
    }
  }, [authError, redirectTo, user]);

  const handleClientSignUp = values => {
    const clientSubmitObject = {
      email: values?.email,
      password: values?.password,
      nif: values?.nif,
      full_name: values?.firstname.concat(' ', values?.lastname),
      phone_number: values?.telephone,
      language_code: locale,
      client_type: 'individual' // hardcoded for now since it seems there is no option in this form
    };
    clientSignUp(clientSubmitObject);
  };

  const renderContent = {
    login: () => {
      return (
        <>
          <StyledCard error={authError} screen={componentToRender} padding={4}>
            <Col padding={0} align='center' center>
              <Logo isCenter />
              <Heading size={3} center>
                {t('login')}
              </Heading>
              {errors && (
                <StyledErrorHolder>
                  <Body>{transResponse(`${errors}`)}</Body>
                </StyledErrorHolder>
              )}
            </Col>
            <StyledTabsRow>
              <Tabs
                justify='center'
                initialTabIndex={userType === 'client' ? 0 : 1}
                tabs={[
                  {
                    name: t('client'),
                    children: () => setUserType('client')
                  },
                  {
                    name: t('Talent'),
                    children: () => setUserType('provider')
                  }
                ]}
              />
            </StyledTabsRow>
            <StyledFormContainer screen={componentToRender}>
              <Form
                locale={locale}
                translate={translateForm}
                submitLabel={t('login')}
                errors={errors}
                questions={loginSchemas(t, componentToRender)}
                onSubmit={values =>
                  signInAndRedirect(userType, values.email, values.password)
                }
              />
              <StyledLinkContainer top='-190'>
                <Link
                  name='forgotPw'
                  style={customLinkStyle}
                  onClick={() => {
                    setErrors(null);
                    setComponentToRender('forgotPw');
                  }}
                >
                  {t('forgotPw')}
                </Link>
              </StyledLinkContainer>
              <StyledLinkContainer top='120'>
                <Link
                  style={customLinkStyle}
                  onClick={() => {
                    if (userType === 'client') {
                      setErrors(null);
                      setComponentToRender('createAccount');
                    } else {
                      history.push('./become');
                    }
                  }}
                >
                  {userType === 'client'
                    ? t('createAccount')
                    : t('becomeTalent')}
                </Link>
              </StyledLinkContainer>
            </StyledFormContainer>
          </StyledCard>
        </>
      );
    },
    createAccount: () => {
      return (
        <Col center>
          <StyledCard padding={4}>
            <Logo isCenter />
            <Heading size={3} center>
              {t('login')}
            </Heading>
            {errors &&
              Object.keys(errors).map(
                e =>
                  transResponse(`${errors[e]?.[0]}`) && (
                    <StyledErrorHolder key={'error' + errors[e]?.[0]}>
                      <Body>{transResponse(`${errors[e]?.[0]}`)}</Body>
                    </StyledErrorHolder>
                  )
              )}
            <StyledFormContainer screen={componentToRender}>
              <Form
                locale={locale}
                translate={translateForm}
                submitLabel={t('register')}
                questions={loginSchemas(t, componentToRender)}
                onSubmit={values => handleClientSignUp(values)}
              />
              <StyledLinkContainer top='-230' bottom='40'>
                <Body
                  name='privacyPolicy'
                  dangerouslySetInnerHTML={{ __html: t('privacyPolicy') + ' <a href="/terms-and-conditions">' + t('termsAndCondition') + '</a> ' + t('and') + ' <a href="privacy-policy">' + t('privacyPolicy55') + '</a>' }}
                />
              </StyledLinkContainer>
            </StyledFormContainer>
          </StyledCard>
          <StyledLinkContainer top='16'>
            <Link
              style={customLinkStyle}
              onClick={() => {
                setErrors(null);
                setComponentToRender('login');
              }}
            >
              {t('startSession')}
            </Link>
          </StyledLinkContainer>
        </Col>
      );
    },
    forgotPw: () => {
      return (
        <Col center>
          <StyledCard padding={4}>
            <Row>
              <StyledBackButtoContainer
                role='presentation'
                onClick={() => {
                  setErrors(null);
                  setComponentToRender('login');
                }}
              >
                <Icon className='go-back' name='chevron-left' />
              </StyledBackButtoContainer>
              <Row style={{ margin: '0 auto' }}>
                <Logo style={{ margin: '0 auto' }} isCenter />
              </Row>
            </Row>
            <Heading style={{ textAlign: 'center' }} size={3} center>
              {t('forgotPw')}
            </Heading>
            {!resetHadSuccess && (
              <Body style={{ textAlign: 'center' }}>{t('forgotPwBody')}</Body>
            )}
            {errors && (
              <StyledErrorHolder>
                <Body>{transResponse(`${errors}`)}</Body>
              </StyledErrorHolder>
            )}
            {!resetHadSuccess ? (
              <>
                <Form
                  locale={locale}
                  translate={translateForm}
                  submitLabel={t('redefinePw')}
                  onSubmit={values =>
                    resetPassword(userType, dispatch, values.email)
                  }
                  questions={loginSchemas(t, componentToRender)}
                />
              </>
            ) : (
              <>
                <Body style={{ textAlign: 'center' }}>{t('verifyMail')}</Body>
                <Row justify='center'>
                  <Button
                    type='submit'
                    text={t('login')}
                    action={() => {
                      setErrors(null);
                      setComponentToRender('login');
                    }}
                    isFullWidth
                  />
                </Row>
              </>
            )}
          </StyledCard>
        </Col>
      );
    }
  };

  return (
    <>
      {authLoading ? (
        <Loader />
      ) : (
        <StyledLoginContainer>
          {renderContent[componentToRender]()}
        </StyledLoginContainer>
      )}
    </>
  );
};

export default Login;
