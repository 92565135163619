import servicesClient from 'api/servicesClient';

const REDUCER = 'serviceForm';

export const ACTION_TYPES = {
  GET_SERVICE_FORM_BY_ID: `${REDUCER}/GET_SERVICE_FORM_BY_ID`,
  GET_SERVICE_FORM_SUCCESS: `${REDUCER}/GET_SERVICE_FORM_SUCCESS`,
  GET_SERVICE_FORM_FAILED: `${REDUCER}/GET_SERVICE_FORM_FAILED`,
  SUBMIT_SERVICE_FORM: `${REDUCER}/SUBMIT_SERVICE_FORM`,
  SUBMIT_SERVICE_FORM_FAILED: `${REDUCER}/SUBMIT_SERVICE_FORM_FAILED`,
  SUBMIT_SERVICE_FORM_SUCCESS: `${REDUCER}/SUBMIT_SERVICE_FORM_SUCCESS`,
  RESET_SERVICE_FORM: `${REDUCER}/RESET_SERVICE_FORM`
};

const submitServiceForm = (dispatch, payload) => {
  dispatch({ type: ACTION_TYPES.SUBMIT_SERVICE_FORM, payload });
  servicesClient
    .submitServiceForm(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.SUBMIT_SERVICE_FORM_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(err => {
      dispatch({
        type: ACTION_TYPES.SUBMIT_SERVICE_FORM_FAILED,
        error: err?.response?.data?.meta?.message
      });
    });
};
const getServiceForm = (dispatch, payload) => {
  dispatch({ type: ACTION_TYPES.GET_SERVICE_FORM_BY_ID, payload });
  servicesClient
    .getServiceForm(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_SERVICE_FORM_SUCCESS,
          payload: res.data,
          serviceName: payload.id + payload.city ? `-${payload.city}` : ''
        });
      }
    })
    .catch(err => {
      dispatch({
        type: ACTION_TYPES.GET_SERVICE_FORM_FAILED,
        error: err?.response?.status || err,
        serviceName: payload.id + payload.city ? `-${payload.city}` : ''
      });
    });
};

const resetServiceForm = dispatch => {
  dispatch({ type: ACTION_TYPES.RESET_SERVICE_FORM });
};

export { getServiceForm, submitServiceForm, resetServiceForm };
