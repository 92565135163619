import { createSelector } from "reselect";

const selectServiceForm = state => state.serviceForm;

const getService = createSelector(selectServiceForm, formState => formState.form);
const getFormLoading = createSelector(selectServiceForm, state => {
  return state.loading || false;
});
const getFormLoaded = createSelector(selectServiceForm, state => {
  return state.loaded || false;
});
const getFormError = createSelector(selectServiceForm, state => {
  return state.error || null;
});

export { getService, getFormLoading, getFormError, getFormLoaded };
