import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';

import usePolymorph from 'Hooks/usePolymorph';

import { Jumbo, Body, Heading } from 'Components/Text';
import { GridCol, GridRow } from 'Components/Layout';
import Button from 'Components/Button';
import Logo from 'Components/Logo';
import Loader from 'Components/Loader';
import List from 'Components/List';
import { staticImages } from 'Assets/img';

import { HeaderColumn } from './Header/styles';
import { CustomHeading } from './HowItWorks/styles';
import HeaderTab from './Header/HeaderTab';
import ServicesTab from './ServicesTab/ServicesTab';
import KnowMoreTab from './KnowMore/KnowMoreTab';
import HowItWorksTab from './HowItWorks/HowItWorksTab';
import BecomeSpecialistTab from './BecomeASpecialist/BecomeSpecialistTab';

const Home = () => {
  const history = useHistory();
  const ts = useTranslate('home.services');
  const tb = useTranslate('home.becomeTalent');
  const extractor = {
    'home-header': (props, element) => {
      props.title = getElementValueByName(element, 'title');
      props.description = getElementValueByName(element, 'description');
      props.image = getElementValueByName(element, 'image', 'media_url');
    },
    'who-knows-section': (props, element) => {
      props.title = getElementValueByName(element, 'title');
      props.subtitle = getElementValueByName(element, 'subtitle');
    },
    'home-how-it-works': (props, element) => {
      props.title = getElementValueByName(element, 'title');
      props.description = getElementValueByName(element, 'description');
      props.list = getElementsByName(element, 'list')
        ?.sort((e, f) => e.order - f.order)
        ?.map(el => el?.elementable?.value);
    },
    'become-specialist-section': (props, element) => {
      props.title = getElementValueByName(element, 'title');
      props.subtitle = getElementValueByName(element, 'subtitle');
    }
  };

  const pageElements = {
    'home-header': ({ title, description, image }) => ({
      headerText: () => (
        <>
          <Jumbo>{title}</Jumbo>
          <Body>{description}</Body>
        </>
      ),
      headerImage: () => (
        <HeaderColumn contain src={image} size={5}>
          <Logo size={475} color={'homeCoverEffect'} />
        </HeaderColumn>
      )
    }),
    'who-knows-section': ({ title, subtitle }) => (
      <GridRow bg='alt' justify='center'>
        <GridCol size={7} text='center'>
          <Heading size={2} align='center'>
            {title}
          </Heading>
          <Body align='center'>{subtitle}</Body>
          <Button
            isFullWidth
            btnType='borded'
            text={ts('footer.buttonLabel')}
            action={() => history.push('/about')}
          />
        </GridCol>
      </GridRow>
    ),
    'home-how-it-works': ({ title, description, list }) => (
      <>
        <GridCol contain src={staticImages.mainPage.homeHow} size={6}>
          <Logo size={550} color={'homeHowEffect'} />
        </GridCol>
        <GridCol size={6}>
          <CustomHeading size={6}>{title}</CustomHeading>
          <Heading size={2}>{description}</Heading>
          <List hasIcon data={list} />
        </GridCol>
      </>
    ),
    'become-specialist-section': ({ title, subtitle }) => (
      <GridRow bg='alt' justify='center'>
        <GridCol size={7} text='center'>
          <Heading align='center' size={2}>
            {title}
          </Heading>
          <Body align='center'>{subtitle}</Body>
          <Button
            isFullWidth
            btnType='primary'
            text={tb('buttonLabel')}
            action={() => history.push('/become')}
          />
        </GridCol>
      </GridRow>
    )
  };

  const {
    parsedContent,
    getElementValueByName,
    getElementsByName
  } = usePolymorph({
    id: 'home',
    pageElements,
    extractor
  });
  return parsedContent ? (
    <>
      <HeaderTab content={parsedContent['home-header']} />
      <ServicesTab />
      <KnowMoreTab content={parsedContent['who-knows-section']} />
      <HowItWorksTab content={parsedContent['home-how-it-works']} />
      <BecomeSpecialistTab
        content={parsedContent['become-specialist-section']}
      />
    </>
  ) : (
    <Loader />
  );
};

export default Home;
