import { createSelector } from 'reselect';
import { get } from 'lodash';

const getContentObject = state => state.content.content;

export const getContent = createSelector(
  [state => getContentObject(state), (state, contentId) => contentId],
  (content, contentId) => get(content, `[${contentId}].data`)
);

export const getContentLoading = createSelector(
  [state => getContentObject(state), (state, contentId) => contentId],
  (content, contentId) => get(content, `[${contentId}].loading`)
);

export const getContentError = createSelector(
  [state => getContentObject(state), (state, contentId) => contentId],
  (content, contentId) => get(content, `[${contentId}].error`)
);
