import styled from 'styled-components';
import { Row, media, GridRow } from 'Components/Layout';
import theme from 'Theme';

export const MainContainer = styled(GridRow)`
  ${media.mobile(`margin: 0`)}
`;

export const BecomeFormContainer = styled(Row)`
  margin-top: ${theme.margin * 2}px;
  margin-bottom: ${theme.margin * 4}px;
  justify-content: center;
  flex-flow: row nowrap;
  button[type='submit'] {
    position: relative;
    top: 300px;
    ${media.smallDesktop(`top: 420px;`)}
    ${media.tablet(`top: 475px;`)}
    ${media.mobile(`top: 750px;`)}
  }
`;

export const TermsAndConditionsContainer = styled.div`
  padding: 10px;
  position: relative;
  bottom: 120px;
  a {
    cursor: pointer;
    color: ${props => props.theme.colors.brand.red};
    text-decoration: none;
  }
  div {
    margin: 5px;
  }
  p {
    font-size: 12px;
    text-align: justify;
  }
`;
export const ErrorMessageHolder = styled.div`
  p {
    margin: 0px 10px;
  }
`;
