import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';
import {
  Loader,
  Col,
  Row,
  TextArea,
  StarsRating,
  Icon,
  Heading,
  Body,
  ButtonText,
  Avatar,
  SmallBody
} from 'ui-55';
import Button from 'Components/Button';
import { toast } from 'react-toastify';

import useAppointments from 'Hooks/useAppointments';
import useStorage from 'Hooks/useStorage';
import useRatings from 'Hooks/useRatings';
import useAuth from 'Hooks/useAuth';

import {
  StyledLoginContainer,
  TextAreaContainer,
  StyledAppointmentCard
} from './style';

const Feedback = () => {
  const t = useTranslate('feedback');
  const history = useHistory();
  const { user } = useStorage();
  const { id } = useParams();
  const { handleRatingSubmit } = useRatings();
  const [feedbackFormValues, setFeedbackFormValues] = useState({
    rating: 0,
    comment: ''
  });
  const { appointment, appointmentLoading, appointmentData } = useAppointments({
    id,
    user
  });

  const cb = {
    success: () => {
      toast.success(t('feedbackSuccess'), {
        onClose: () => history.push('/')
      });
    },
    fail: () => {
      toast.error(t('feedbackError'));
    }
  };

  const { authLoading } = useAuth();
  const renderAppointmentCard = () => {
    const service = appointmentData?.included?.find(
      data => data.type === 'service'
    );
    const provider = appointmentData?.included?.find(
      data => data.type === 'provider'
    );
    const serviceName = service?.attributes?.name;
    const providerName = provider?.attributes?.full_name;
    return (
      <StyledAppointmentCard>
        <ButtonText color='brand.orange'>{serviceName}</ButtonText>
        <Row align='center'>
          <SmallBody color='grey'>{t('Talent')}</SmallBody>
          <Avatar user={provider?.attributes}></Avatar>
          <SmallBody>{providerName}</SmallBody>
        </Row>
        <Row align='center'>
          <Icon name='Clock' size={16} color='#88827C' />
          <SmallBody>
            {appointment?.attributes?.total_hours} {t('hours')}
          </SmallBody>
        </Row>
        <Row align='center'>
          <SmallBody color='grey' className='label'>
            {t('total')}
          </SmallBody>
          <SmallBody>{appointment?.attributes?.total_price}€ </SmallBody>
          <SmallBody className='iva-mark' color='grey'>
            {t('iva')}
          </SmallBody>
        </Row>
      </StyledAppointmentCard>
    );
  };
  return (
    <>
      {authLoading || appointmentLoading ? (
        <Loader />
      ) : (
        <StyledLoginContainer>
          <Col>
            <Heading>{t('title')}</Heading>
            <Body>{t('description')}</Body>
            {renderAppointmentCard()}
            <Body>{t('rating')}</Body>
            <Row>
              <StarsRating
                isInteractive
                labels={[t('notSatisfied'), t('extremelySatisfied')]}
                onChangeRating={r =>
                  setFeedbackFormValues({ ...feedbackFormValues, rating: r })
                }
                defaultRating={feedbackFormValues.rating}
              />
            </Row>
            <Body>{t('comment')}</Body>
            <TextAreaContainer>
              <TextArea
                placeholder={t('commentPlaceholder')}
                onChange={r =>
                  setFeedbackFormValues({ ...feedbackFormValues, comment: r })
                }
              />
            </TextAreaContainer>
            <Button
              text={t('send')}
              action={() => handleRatingSubmit(feedbackFormValues, id, cb)}
            />
          </Col>
        </StyledLoginContainer>
      )}
    </>
  );
};

export default Feedback;
