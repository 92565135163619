import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorText, Body } from 'Components/Text';
import StyledSelect, {
  SelectContainer,
  selectStyles,
  SplitSelectContainer,
  ChildSelectContainer
} from './style';

const Select = ({
  error,
  placeholder,
  options = [],
  isOptionDisabled,
  label,
  onChange,
  isDisabled,
  isSearchable,
  isMulti,
  name,
  value,
  defaultValue,
  child,
  arrowIsHidden,
  childAction,
  menuIsOpen,
  classNamePrefix,
  components
}) => {
  // TODO Add different color to placeholder
  const [childLength, setChildLength] = useState(0);
  const [childState, setChildState] = useState({});
  const [err, setErr] = useState(null);

  useLayoutEffect(() => {
    if (error) {
      setErr(error);
    }
  }, [error]);

  return (
    <SelectContainer>
      {label && <Body>{label}</Body>}
      <StyledSelect
        name={name}
        onChange={event => {
          setErr(false);
          child && child.length && event
            ? setChildLength(event.value)
            : onChange(event);
        }}
        isDisabled={isDisabled}
        styles={selectStyles}
        isMulti={isMulti}
        isSearchable={isSearchable}
        options={options}
        arrowIsHidden={arrowIsHidden}
        isOptionDisabled={isOptionDisabled}
        error={err}
        defaultValue={defaultValue}
        placeholder={placeholder}
        menuIsOpen={menuIsOpen}
        classNamePrefix={classNamePrefix}
        value={value}
        components={components}
      />
      {err && <ErrorText>{err}</ErrorText>}
      {renderChildren(
        child,
        childAction,
        childLength,
        childState,
        setChildState
      )}
    </SelectContainer>
  );
};

// TODO ex: dynamic child label
const renderChildren = (children, action, length, state, setState) => {
  // normal split select onChange handler
  const handleChildChange = (
    index,
    event,
    childAction,
    state,
    setState,
    extOpt
  ) => {
    if (extOpt && event.label === state.target) {
      const newLabel = `${event.label}${index}`;
      setState({ ...state, target: newLabel });
    } else if (extOpt) {
      setState({ ...state, target: event.label });
    } else {
      setState(Object.assign({}, state, (state[index] = event.value)));
    }
    childAction(state);
  };

  //!picking two of the same needs work as it holds only one of
  // extra option split select onChange handler
  const handleExtraOption = (index, value, childAction, state, setState) => {
    const key = state && state.target;
    const newState = Object.assign({}, state, (state[key] = value));
    delete newState.target;
    childAction(newState);
  };

  const childs =
    children &&
    Array(length)
      .fill(children)
      .map((child, index) => {
        const hasExtraOptions = Boolean(child[0]?.extraOptions);
        return (
          <>
            <ChildSelectContainer key={`${child}-${index}`}>
              <Select
                label={`${children[0].question} ${index + 1}`}
                options={children[0].options}
                onChange={event =>
                  handleChildChange(
                    index + 1,
                    event,
                    action,
                    state,
                    setState,
                    hasExtraOptions
                  )
                }
              />
            </ChildSelectContainer>

            {hasExtraOptions && (
              <Select
                label={child[0].extraOptions.question}
                options={child[0].extraOptions.options}
                onChange={event =>
                  handleExtraOption(index, event.value, action, state, setState)
                }
              />
            )}
          </>
        );
      });
  return <SplitSelectContainer>{childs}</SplitSelectContainer>;
};

Select.propTypes = {
  arrowIsHidden: PropTypes.bool,
  childAction: PropTypes.func,
  child: PropTypes.array,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOf('number', 'string')
    })
  ),
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

export default Select;
