import styled from 'styled-components';
import { GridRow, media } from 'Components/Layout';

export const ServicesHeader = styled(GridRow)`
  h2 {
    margin-bottom: ${(props) => props.theme.margin}px;
  }
  p {
    margin-bottom: ${(props) => props.theme.margin * 4}px;
    margin-top: 0px;
  }
  ${media.tablet(`
  div {
    width: 100%;
  }
    h2 {
      text-align: center;
      font-size: 32px;
      line-height: 40px;  
    }
  `)}
`;
