import React, { useState, useEffect } from 'react';
import Select from './Select';
import { Icon } from 'ui-55';
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from '../../polyglot';
import { selectLocale, setLocale } from '../../polyglot/polyglotSlice';
import styled from 'styled-components';
import { useGetLanguagesQuery } from '../languageSlice';


export const CityFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${({ theme }) => `${theme.margin / 2}px  auto`};
  border-bottom: ${props =>
    props.isRed ? `1px solid ${props.theme.colors.mediumBeige}` : 'unset'};
  path {
    fill: ${props => props.isRed && props.theme.colors.brand.orange};
  }
  .service-list-navbar__placeholder,
  .service-list-navbar__single-value {
    color: ${props => props.isRed && props.theme.colors.brand.orange};
    font-size: ${props => (props.isRed ? '32px' : '16px')};
    font-weight: ${props => (props.isRed ? 'bold' : 'normal')};
    line-height: ${props => (props.isRed ? '40' : '24px')};
  }
`;

export const ServiceCityFilter = styled.div`
  .filter-text {
    margin: 0;
  }
  span {
    margin: 0 !important;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: flex-start;
  > span {
    margin: 28px 0 0 0;
  }
`;

export const LanguageSelect = ({ isRed }) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const translate = useTranslate('languageSelect');

  const options = {
    page: 1,
    search: "",
    perPage: 10
  };

  const { data, isFetching, error } = useGetLanguagesQuery(options);
  const [selectedLang, setSelectedLang] = useState(null);

  const languageOptions = data?.data
  ?.filter(lang => ['en-uk', 'pt', 'es', 'fr'].includes(lang.attributes.code))
  .map(lang => ({ value: lang.attributes.code, label: translate(lang.attributes.name) }));

  useEffect(() => {
    if (!isFetching && languageOptions) {
      setSelectedLang(languageOptions.find(option => option.value.slice(0, 2) === locale.slice(0,2)));
    }
  }, [isFetching, locale]);
  const handleChange = (event) => {
    setSelectedLang(event);
    const localeCode = event.value;
    dispatch(setLocale(localeCode));
  }

  if(isFetching) {
    return <></>;
  }
  return (
    <CityFilterContainer isRed={isRed}>
      <Icon name='Language' />
      <Select
        placeholder='Language'
        options={languageOptions}
        arrowIsHidden
        onChange={event => handleChange(event)}
        value={selectedLang}
        classNamePrefix='service-list-navbar'
      />
    </CityFilterContainer>
  );
};

