import React from 'react';

import { Accordion } from 'ui-55';

import usePolymorph from 'Hooks/usePolymorph';

import { RightContainer, AccordionContainer } from './styles';

const FAQsPage = () => {
  const pageElements = {
    questions: ({ key, childRender }) => (
      <React.Fragment key={key}>{childRender}</React.Fragment>
    ),
    'faq-section': ({ childRender }) => (
      <AccordionContainer>{childRender}</AccordionContainer>
    ),
    'faq-question': ({ title, content }) => (
      <Accordion title={title} content={content} />
    )
  };

  const extractor = {
    'faq-question': (props, element) => {
      props.title = getElementValueByName(element, 'question-title');
      props.content = getElementValueByName(element, 'question-answer');
    },
    'faq-section': (props, element, renderer) => {
      const sortedElements = [...(element?.elementable?.elements ?? [])].sort(
        (a, b) => a.order - b.order
      );
      props.childRender = renderer(sortedElements, true);
    },
    questions: (props, element, renderer) => {
      const sortedElements = [...(element?.elementable?.elements ?? [])].sort(
        (a, b) => a.order - b.order
      );
      props.childRender = renderer(sortedElements, true);
    }
  };

  const { parsedContent, getElementValueByName } = usePolymorph({
    id: 'faq',
    pageElements,
    extractor
  });

  //const parsedContent = parseCMSContent(CONTENT.data, pageElements, extractor);

  return <RightContainer>{parsedContent['faq-section']}</RightContainer>;
};

export default FAQsPage;
