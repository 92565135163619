import React from 'react';
import { useTranslate } from '../../features/polyglot';
import { useLocation } from 'react-router-dom';
import { Body, Heading } from 'Components/Text';
import { GridRow } from 'Components/Layout';
import { StyledTermsContainer } from './styles';
const FormsTermsAndConditions = () => {
  const searchParams = useLocation().search;
  const f = useTranslate('formsTermsAndConditions');

  return (
    <>
      <GridRow>
        <StyledTermsContainer>
          <Heading size={2}>{f('terms')}</Heading>
          <Body>
            {searchParams === '?client' ? f('clients') : f('Talents')+' <a href="/privacy-policy" target="_blank">'+f('TalentsPolicyPrivacy')+'</a>.'}
          </Body>
        </StyledTermsContainer>
      </GridRow>
    </>
  );
};

export default FormsTermsAndConditions;
