import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslate } from '../../features/polyglot';

import {
  StyledMenu,
  ButtonsContainer,
  LinksContainer,
  CustomLink,
  NavModal
} from './styles';

import theme from 'Theme';

import { GridCol, Row } from 'Components/Layout';
import Divider from 'Components/Divider';
import CityFilter from 'Components/CityFilter';
import { LanguageSelect } from 'features/languages/components/LanguageSelect';

const NavbarRoute = ({ route, closeMenu }) => {
  const history = useHistory();
  const handleLinkClick = () => {
    history.push(route.value);
    closeMenu();
  };
  return (
    <>
      {route.type === 'grey' && (
        <CustomLink color='grey' onClick={handleLinkClick}>
          {route.label}
        </CustomLink>
      )}
      {route.type === 'darkBlue' &&
        (route.hasDivider ? (
          route.hasIcon ? (
            <>
              <LanguageSelect />
              <CityFilter />
              <Divider color={theme.colors.grey} />
            </>
          ) : (
            <>
              <CustomLink color='blue' onClick={handleLinkClick}>
                {route.label}
              </CustomLink>
              <Divider color={theme.colors.grey} />
            </>
          )
        ) : (
          <CustomLink color='blue' onClick={handleLinkClick}>
            {route.label}
          </CustomLink>
        ))}
    </>
  );
};

const NavbarMenu = ({ isMenuOpen, setCloseMenu }) => {
  const hasDivider = true;
  const hasIcon = true;
  const t = useTranslate('nav');

  const routes = [
    {
      label: t('country'),
      value: '/country',
      type: 'darkBlue',
      hasDivider,
      hasIcon
    },
    {
      label: t('services'),
      value: '/services',
      type: 'darkBlue'
    },
    {
      label: t('aboutUs'),
      value: '/about',
      type: 'darkBlue',
      hasDivider
    },
    {
      label: t('faqs'),
      value: '/frequently-asked-questions',
      type: 'grey'
    },
    {
      label: t('termsAndConditions'),
      value: '/terms-and-conditions',
      type: 'grey'
    },
    {
      label: t('privacyPolicy'),
      value: '/privacy-policy',
      type: 'grey'
    }
  ];

  return (
    <NavModal openMenu={isMenuOpen}>
      <StyledMenu openMenu={isMenuOpen}>
        <GridCol size={12}>
          <LinksContainer>
            {routes.map(route => (
              <Row key={route.value + 'route'}>
                <NavbarRoute route={route} closeMenu={setCloseMenu} />
              </Row>
            ))}
          </LinksContainer>

          <ButtonsContainer>
            {/*
            <Button
              btnType="secondary"
              text={t("registerAs")}
              action={() => history.push("/register")}
            />
             <Button
              btnType='primary'
              text={t('login')}
              action={() => history.push('/login')}
            /> */}
          </ButtonsContainer>
        </GridCol>
      </StyledMenu>
    </NavModal>
  );
};

NavbarMenu.propTypes = {
  route: PropTypes.array,
  isMenuOpen: PropTypes.bool,
  label: PropTypes.string,
  setCloseMenu: PropTypes.func,
  type: PropTypes.oneOf(['grey', 'darkBlue']),
  value: PropTypes.string
};

export default NavbarMenu;
