import styled from 'styled-components';
import { Form } from 'formik';

const getSelectedBackground = props => {
  switch (props.bg) {
    case 'alt':
      return props.theme.colors.lightBeige;
    case 'secondary':
      return props.theme.colors.brand.yellow;
    case 'terceary':
      return props.theme.colors.brand.lightBlue;
    default:
      return props.theme.colors.white;
  }
};

export const FormContainer = styled.div`
  padding: 20px;
  z-index: 2;
  background-color: ${props => getSelectedBackground(props)};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  top: ${props => (props.top ? '-80px' : '0')};
  margin: 0 auto;
  max-width: 760px;
  text-align: left;

  button {
    align-self: center;
    margin-bottom: 20px;
    margin-top: 30px;
  }
`;

export const StyledForm = styled(Form)`
  div {
    & > textarea {
      min-height: 161px;
    }
    & > p {
      text-align: left;
    }
  }
`;

export const SplitSelectContainer = styled.div`
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  nav {
    margin-bottom: 15px;
  }
  & > div {
    width: 48%;
  }
`;
