const schemas = t => ({
  login: [
    {
      key: 'email',
      type: 'text',
      widget: 'email',
      required: true
    },
    {
      key: 'password',
      type: 'text',
      widget: 'password',
      hasIcon: true,
      required: true
    }
  ],
  createAccount: [
    {
      key: 'firstname',
      label: t('name'),
      type: 'text',
      required: true
    },
    {
      key: 'lastname',
      label: t('lastname'),
      type: 'text',
      required: true
    },
    {
      key: 'email',
      label: t('email'),
      type: 'text',
      widget: 'email',
      required: true
    },
    {
      key: 'password',
      label: t('password'),
      hasIcon: true,
      type: 'text',
      widget: 'password',
      required: true
    },
    {
      key: 'nif',
      label: t('nif'),
      type: 'text',
      pattern: 'nif',
      required: true
    },
    {
      key: 'telephone',
      label: t('telephone'),
      type: 'telephone',
      required: true
    },
  ],
  forgotPw: [{ key: 'email', label: t('email'), type: 'email', required: true }]
});

export const loginSchemas = (t, page) => {
  return schemas(t)[page];
};
