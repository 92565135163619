import styled from 'styled-components';
import { Link } from 'Components/Text';
import { SmallBody, media, Col, Row, Icon, Tabs } from 'ui-55';

export const StyledFilterBar = styled.div`
display: flex;
flex-wrap: wrap;
margin: 16px 16px 16px 16px;

`;

export const StyledContainer = styled(Row)`
  min-height: 490px;
  ${media.tablet(`flex-flow: column;`)}
  ${media.mobile(`flex-flow: column;`)}
  flex-flow: row;
  padding-top: ${props => props.theme.margin}px;
`;

export const StyledUserParagraph = styled(Link)`
  cursor: pointer;
  padding: 16px 32px;
  color: ${({ theme, active }) => (active ? theme.colors.brand.orange : '')};
`;

export const StyledDashboardCol = styled(Col)`
  margin-bottom: ${({ theme }) => theme.margin * 4}px;
  ${media.mobile(`padding: 0;`)}
`;

export const StyledTabs = styled(Tabs)`
  > div {
    ${media.mobile(`overflow: scroll;`)}
    button {
      margin: 0;
      margin-bottom: ${({ theme }) => theme.margin}px;
      margin-right: ${({ theme }) => theme.margin}px;
      margin-top: ${({ theme }) => theme.margin}px;
    }
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const InnerRow = styled(Row)`
  flex-direction: column;
`;

export const StyledCardRow = styled(Row)`
  ${media.mobile(`padding: 16px;`)}
  margin-bottom: ${props => (props.spaced ? 16 : 0)}px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.mediumBeige};
  box-sizing: border-box;
  border-radius: 2px;
  justify-content: ${props => props.justify || 'flex-start'};
  padding: ${props => (props.padding ? props.padding : '24')}px;
  display: flex;
  flex-flow: ${props => (props.flow ? props.flow : 'row wrap')};
  p {
    margin-top: 4px !important;
    margin-right: 4px !important;
  }
`;

export const StyledServiceColumns = styled.div`
  justify-content: ${props => props.justify};
  padding: 0px;
  display: flex;
  flex: ${({ size }) => size};
  flex-direction: column;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  p {
    line-height: 24px;
    margin-bottom: 4px;
    margin-top: 0px;
    white-space: nowrap;
    align-self: center;
  }
  ${props =>
    props.collapse &&
    media[props.collapse](`
  display: none;
  `)}
`;

export const StyledIcon = styled(Icon)`
  align-self: ${props => (props.align ? props.align : 'unset')};
  margin-right: ${props => props.right || 0}px;
  width: ${props => props.width || 32}px;
  height: ${props => (props.height ? props.height : 32)}px;
  background-color: ${props =>
    props.alt ? props.theme.colors.lightBeige : ''};
  border-radius: ${props => (props.alt ? '16px' : 'none')};
`;

export const StyledCardBody = styled(SmallBody)`
  background-color:  '';
  padding: ${props => (props.bg ? '4px 8px' : 0)};
  border-radius: 2px;
  color: ${props =>
    props.faded ? props.theme.colors.grey : props.theme.colors.darkBlue};
  margin-right: ${({ theme }) => theme.margin / 2}px;
  align-self: start;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.grey};
  svg {
    margin-right: ${({ theme }) => theme.margin / 2}px;
    vertical-align: middle;
  }
`;