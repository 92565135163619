import providerServices from 'api/providerServices';
import useStorage from 'Hooks/useStorage';
import { setLocale } from '../../features/polyglot/polyglotSlice';
const REDUCER = 'provider';

export const ACTION_TYPES = {
  POST_PROVIDER_FORM: `${REDUCER}/POST_PROVIDER_FORM`,
  POST_PROVIDER_SUCCESSFUL: `${REDUCER}/POST_PROVIDER_SUCCESSFUL`,
  POST_PROVIDER_FAILED: `${REDUCER}/POST_PROVIDER_FAILED`,
  UPDATE_PROVIDER_FORM: `${REDUCER}/UPDATE_PROVIDER_FORM`,
  UPDATE_PROVIDER_FORM_SUCESSFUL: `${REDUCER}/UPDATE_PROVIDER_FORM_SUCESSFUL`,
  UPDATE_PROVIDER_FORM_FAILED: `${REDUCER}/UPDATE_PROVIDER_FORM_FAILED`,
  RESET_PROVIDER_FORM: `${REDUCER}/RESET_PROVIDER_FORM`,
  UPDATE_PROVIDER_CONTACTS: `${REDUCER}/UPDATE_PROVIDER_CONTACTS`,
  UPDATE_PROVIDER_CONTACTS_SUCCESS: `${REDUCER}/UPDATE_PROVIDER_CONTACTS_SUCCESS`,
  UPDATE_PROVIDER_CONTACTS_FAILED: `${REDUCER}/UPDATE_PROVIDER_CONTACTS_FAILED`
};

const { setStorageAuthToken, getStorageItem } = useStorage();
const STORAGE_AUTH_TOKEN = getStorageItem('AUTH_TOKEN') || {};
const { user, token, tokenDate } = STORAGE_AUTH_TOKEN;

const postProviderForm = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.POST_PROVIDER_FORM,
    payload
  });
  providerServices
    .postProviderForm(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.POST_PROVIDER_SUCCESSFUL,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.POST_PROVIDER_FAILED,
        errors: e.response.data
      });
    });
};

const updateProviderForm = (dispatch, payload) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_PROVIDER_FORM,
    payload
  });
  providerServices
    .updateProviderForm(payload)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_PROVIDER_FORM_SUCESSFUL,
          payload: res.data
        });
        if (user) {

          if(user?.attributes?.languageCode) {
            dispatch(setLocale(user?.attributes?.languageCode));
          }

          if(user.attributes.data.attributes.language_code) {
            dispatch(setLocale(user.attributes.data.attributes.language_code));
          }

          setStorageAuthToken({
            token,
            tokenDate,
            user: {
              ...user,
              attributes: res.data
            }
          });
        }
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_PROVIDER_FORM_FAILED,
        errors: e?.response?.data
      });
    });
};

const resetProviderForm = dispatch => {
  dispatch({ type: ACTION_TYPES.RESET_PROVIDER_FORM });
};

const updateProviderContacts = (dispatch, payload) => {
  dispatch({ type: ACTION_TYPES.UPDATE_PROVIDER_CONTACTS, payload });
  const id = payload?.id;
  delete payload.id;
  providerServices
    .updateProviderContacts(payload, id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.UPDATE_PROVIDER_CONTACTS_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.UPDATE_PROVIDER_CONTACTS_FAILED,
        error: e?.response
      });
    });
};

export {
  postProviderForm,
  updateProviderForm,
  resetProviderForm,
  updateProviderContacts
};
