import React, { useState, useEffect } from 'react';
import { ErrorText } from 'Components/Text';
import { useTranslate } from '../../features/polyglot';
import { Icon } from 'ui-55';

import {
  StyledFilterButton,
  CloseContainer,
  FilterTitle,
  OpenDropdown,
  Dropdown,
  BodyDetail,
  overrideSelect,
  StyledSelect,
  SelectContainer,
  selectStyles,
} from './style';


const FilterButtonStatus = (props) => {
  const {
    filterOptions,
    onClose,
    selectValue,
    setSelectValue
  } = props;
  const t = useTranslate('filters');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("Todos");

  useEffect(() => {
    if (selectValue === null) {
      setSelectedLabel("Todos");
    } else {
      setSelectedLabel(selectValue[0].label);
      if (selectValue.length > 1) {
        setSelectedLabel(selectValue[0].label + " + " + selectValue.length);
      }
    }
  }, [selectValue]);


  const handleOpen = e => {
    setShowDropdown(!showDropdown);
  };

  const handleClose = e => {
    setShowDropdown(false);
    onClose(e);
    setSelectValue(null);
    setSelectedLabel("Todos");
  };

  const handleSelect = e => {
    setSelectValue(e);
    if (selectValue === null) {
      setSelectedLabel("Todos");
    } else {
      setSelectedLabel(selectValue[0].label);
      if (selectValue.length > 1) {
        setSelectedLabel(selectValue[0].label + " + " + selectValue.length);
      }
    }
    setShowDropdown(!showDropdown);
  };



  return (
    <StyledFilterButton>
      <FilterTitle filterLabel={'status'}>
        <Icon name={'activity'} />
        <span>{t('status')}:</span>
      </FilterTitle>

      <OpenDropdown onClick={handleOpen}>
        <BodyDetail>{selectedLabel}</BodyDetail>
        <Icon name='chevron-down' />
      </OpenDropdown>

      {onClose && (
        <CloseContainer onClick={() => handleClose()}>
          <Icon name='Close' />
        </CloseContainer>
      )}
      <>
        {showDropdown && (
          <Dropdown length={filterOptions?.length} isMulti={true}>

            <SelectContainer isFullWidth={true}>

              <StyledSelect
                isMulti={true}
                onChange={handleSelect}
                isDisabled={false}
                styles={overrideSelect(true) || selectStyles}
                options={filterOptions}
                defaultValue={selectValue}
                defaultMenuIsOpen={true}
                value={selectValue}
              />
              <ErrorText error={null}>{null}</ErrorText>
            </SelectContainer>
          </Dropdown>
        )}
      </>

    </StyledFilterButton>
  );
};


export default FilterButtonStatus;
