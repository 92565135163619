import React from 'react';
import { Badge, Icon } from 'ui-55';
import { useTranslate } from '../../features/polyglot';
import { Heading } from 'Components/Text';
import {
  CarouselContainer,
  ItemContainer,
  ItemImage,
  Container,
  IconContainer
} from './styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PropTypes from 'prop-types';
import { Row } from 'Components/Layout';

import { BREAKPOINTS } from 'Theme';

const CardSlider = ({ list, action }) => {
  const translateServices = useTranslate('serviceList');
  const b = useTranslate('badges');
  const Arrow = direction => {
    return (
      <IconContainer direction={direction}>
        <Icon name={`arrow-${direction}`} />
      </IconContainer>
    );
  };
  const settings = {
    speed: 500,
    infinite: false,
    nextArrow: Arrow('right'),
    prevArrow: Arrow('left'),
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: BREAKPOINTS.smallDesktop,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: BREAKPOINTS.tablet,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: BREAKPOINTS.mobile,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Container>
      <CarouselContainer row slicesPerRow={3} {...settings}>
        {list &&
          list.map((item, index) => {
            return (
              <ItemContainer key={index}>
                <ItemImage onClickCapture={() => action(item)} src={item.img} />
                <Row>
                  <Badge
                    translate={b}
                    text={item.category}
                    category={item.category}
                    onClick={item.action}
                  />
                </Row>
                <Row>
                  <Heading size={6}>{translateServices(`${item.content_key}.name`)}</Heading>
                </Row>
              </ItemContainer>
            );
          })}
      </CarouselContainer>
    </Container>
  );
};

CardSlider.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object.isRequired),
  action: PropTypes.func
};

export default CardSlider;
