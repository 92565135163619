import React, { useMemo } from 'react';
import { useTranslate } from '../../features/polyglot';
import { components } from 'react-select';

import useCities from 'Hooks/useCities';

import Select from 'Components/Select';
import { Badge, Icon } from 'ui-55';

import {
  CityFilterContainer,
  ServiceCityFilter,
  BadgeContainer
} from './style';

const CustomOption = props => {
  const b = useTranslate('badges');
  return (
    <components.Option {...props}>
      <ServiceCityFilter>
        <p className='filter-text'>{props?.data?.label}</p>
        {props?.data?.subLabel && (
          <BadgeContainer>
            <Badge
              translate={b}
              text={props?.data?.subLabel}
              category={props?.data?.subLabel}
            />
          </BadgeContainer>
        )}
      </ServiceCityFilter>
    </components.Option>
  );
};
const CityFilter = ({ isRed, isBottomBorder, isSearchable }) => {
  const { cities, city, dispatchUpdateCity } = useCities();
  const citiesMemo = useMemo(() => {
    return cities.map(city => {
      return {
        value: city.id,
        label: city.attributes.name,
        status: city.attributes.status
      };
    });
  }, [cities]);

  return (
    <CityFilterContainer isRed={isRed}>
      <Icon name='map-pin' />
      <Select
        options={citiesMemo}
        placeholder='Lisboa'
        arrowIsHidden
        onChange={event => dispatchUpdateCity(event)}
        value={city ? { label: city?.attributes?.name, value: city?.id } : null}
        classNamePrefix='service-list-navbar'
        components={{ Option: CustomOption }}
        isSearchable={isSearchable}
      />
    </CityFilterContainer>
  );
};

export default CityFilter;
