import React from 'react';
import { useTranslate } from '../../features/polyglot';
import { useHistory } from 'react-router-dom';

import { GridCol, GridRow, Row } from 'Components/Layout';
import { NavLink } from 'Components/Text';
import Select from 'Components/Select';

import FAQsPage from './FAQsPage';
import TermsAndConditionsPage from './TermsAndConditionsPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsOfServicePage from './TermsOfServicePage';

import { FooterPage, RouteContainer } from './styles';
import TransparencyPage from "./TransparencyPage";

// const t = useTranslate("footerPages.TermsAndConditions");

const FooterRoute = ({ route }) => {
  return (
    <RouteContainer>
      <NavLink to={route.value} activeClassName='link-selected'>
        {route.label}
      </NavLink>
    </RouteContainer>
  );
};

const FooterMenu = ({ type }) => {
  const a = useTranslate('footerPages.FAQ');
  const history = useHistory();
  const routes = [
    {
      label: a('faqs'),
      value: '/frequently-asked-questions'
    },
    {
      label: a('tnc'),
      value: '/terms-and-conditions'
    },
    {
      label: a('termsService'),
      value: '/terms-of-service'
    },
    {
      label: a('pp'),
      value: '/privacy-policy'
    },
    {
      label: a('transparency'),
      value: '/transparency'
    }
  ];
  if (type === 'select') {
    return (
      <GridRow collapse={'desktop'} show={'tablet'}>
        <Select onChange={v => history.push(v.value)} options={routes} />
      </GridRow>
    );
  }
  return (
    <GridCol size={3} collapse={'smallDesktop'}>
      {routes.map(r => (
        <Row key={r.value + 'route'}>
          <FooterRoute route={r} />
        </Row>
      ))}
    </GridCol>
  );
};

const FAQ = () => {
  return (
    <FooterPage justify='center'>
      <FooterMenu />
      <GridCol size={9}>
        <FooterMenu type='select' />
        <FAQsPage />
      </GridCol>
    </FooterPage>
  );
};

const TermsAndConditions = () => {
  return (
    <FooterPage justify='center'>
      <FooterMenu />
      <GridCol size={9}>
        <FooterMenu type='select' />
        <TermsAndConditionsPage />
      </GridCol>
    </FooterPage>
  );
};

const TermsOfService = () => {
  return (
    <FooterPage justify='center'>
      <FooterMenu />
      <GridCol size={9}>
        <FooterMenu type='select' />
        <TermsOfServicePage />
      </GridCol>
    </FooterPage>
  );
};

const PrivacyPolicy = () => {
  return (
    <FooterPage justify='center'>
      <FooterMenu />
      <GridCol size={9}>
        <FooterMenu type='select' />
        <PrivacyPolicyPage />
      </GridCol>
    </FooterPage>
  );
};

const Transparency = () => {
    return (
        <FooterPage justify='center'>
            <FooterMenu />
            <GridCol size={9}>
                <FooterMenu type='select' />
                <TransparencyPage />
            </GridCol>
        </FooterPage>
    );
};

export default { FAQ, TermsAndConditions, PrivacyPolicy, TermsOfService, Transparency };
