import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Button } from 'ui-55';
import {
  StyledModal,
  HeadingDetail,
  SubHeading,
  RefLine,
  ButtonContainer,
  Key,
  StyledBackdrop
} from './styles';

const MultibancoModal = ({ data, toggleModal }) => {
  return (
    <>
      <StyledModal>
        <Row justify='space-between' align='center'>
          <Col size={6}>
            <HeadingDetail size={3}>Detalhes de Pagamento</HeadingDetail>
          </Col>
          <ButtonContainer padding={0}>
            <Button
              icon='Close'
              btnType='transparent'
              action={() => toggleModal()}
            />
          </ButtonContainer>
        </Row>

        <Row>
          <Col size={12}>
            <SubHeading size={6}>Pagar com Multibanco</SubHeading>
            <SubHeading size={6}>Seguem as referências para pagamento com Multibanco:</SubHeading>

            <RefLine>
              <Key>Entidade: </Key>
              {data?.attributes?.entity}
            </RefLine>

            <RefLine>
              <Key>Referência: </Key>
              {data?.attributes?.reference}
            </RefLine>

            <RefLine>
              <Key>Montante: </Key>€
              {(data?.attributes?.total_price_with_tax / 100).toFixed(2)}
            </RefLine>
          </Col>
        </Row>
      </StyledModal>
      <StyledBackdrop />
    </>
  );
};

export default MultibancoModal;

MultibancoModal.propTypes = {
  data: PropTypes.object, // Invoice
  toggleModal: PropTypes.func
};
