import {
  configureStore,
  combineReducers,
  getDefaultMiddleware
} from '@reduxjs/toolkit';
import { enableAllPlugins } from 'immer';
import servicesReducer from './services';
import providersReducer from './provider';
import citiesReducer from './cities';
import serviceFormReducer from './serviceForm';
import AuthReducer from './auth';
import AppointmentsReducer from './appointments';
import InvoicesReducer from './invoices';
import ContentReducer from './content';
import polyglotSlice from '../features/polyglot/polyglotSlice';
import { apiSlice } from '../features/api/apiSlice';

enableAllPlugins();

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  polyglot: polyglotSlice,
  services: servicesReducer,
  serviceForm: serviceFormReducer,
  providers: providersReducer,
  cities: citiesReducer,
  auth: AuthReducer,
  appointments: AppointmentsReducer,
  invoices: InvoicesReducer,
  content: ContentReducer
});

const store = configureStore({
  reducer: rootReducer,
  ...getDefaultMiddleware,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware)
});

export default store;
