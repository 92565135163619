import React, { useState, useMemo } from 'react';
import useCities from 'Hooks/useCities';
import Button from 'Components/Button';
import {
  Select,
  Icon
} from 'ui-55';
import StyledBanner from './style';

const CityFilterService = () => {
  const localStore = localStorage.getItem('modalCityClosed');
  const [isOpen, setIsOpen] = useState(!localStore);//useState();

  const handleToggleModal = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    localStorage.setItem('modalCityClosed', true);
  };

  const { cities, city, dispatchUpdateCity } = useCities();
  const citiesMemo = useMemo(() => {
    return cities.map(city => {
      return {
        value: city.id,
        label: city.attributes.name,
        status: city.attributes.status
      };
    });
  }, [cities]);

  const handleCityChange = event => {
    const selectedCity = event;
    dispatchUpdateCity(selectedCity);
  };

  const selectedCityValue = useMemo(() => {
    if (city) {
      return { label: city.attributes.name, value: city.id };
    }
    return null;
  }, [city]);

  return (
    <>
      {isOpen && (
        <StyledBanner>
          <div role='presentation' className='close' onClick={handleToggleModal}>
            <Icon name='Close' />
          </div>
          <p>
            Por favor, selecione o Município
          </p>
          <Select
            isMini={false}
            options={citiesMemo}
            placeholder={city?.attributes?.name}
            onChange={handleCityChange}
            value={selectedCityValue}
          />
          <div className='links'>
            <Button
              btnType='primary'
              text='Selecionar'
              action={handleToggleModal}
              isFullWidth={true}
            />
          </div>
        </StyledBanner>
      )}
    </>
  );
};

export default CityFilterService;
