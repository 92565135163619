import styled from "styled-components";
import { media, Hero, GridRow } from "Components/Layout";
import theme from "Theme";

export const BecomeHeaderHero = styled(Hero)`
  background: linear-gradient(
    to right,
    ${theme.colors.brand.yellow} 50%,
    ${theme.colors.white} 50%
  );
  ${media.smallDesktop(`
    margin-bottom: ${theme.margin * 5}px;
    height: auto;
    top: 0px;
    & > div {
      margin-top: 20px;
    }
    h1 {
      font-size: 32px;
      line-height: 40px;
    }
    svg {
      width: 300px;
    }
  `)}
  ${media.mobile(`
    background: ${theme.colors.white};
    svg {
      width: 200px;
    }
  `)}
  overflow: hidden;
`;

export const BecomeHeaderGrid = styled(GridRow)`
  background: linear-gradient(
    to right,
    ${theme.colors.brand.yellow} 50%,
    ${theme.colors.white} 50%
  );

  ${media.mobile(`
    flex-direction: column;
    background: ${theme.colors.white};

    & > div {
      width: 100%;
      align-self: center;
    }

    & > div  > div {
      display: flex;
      justify-content: center;
    }

    h1 {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }
    h2 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  `)}
`;
