import styled from 'styled-components';

import { media } from 'Components/Layout';

export const StyledLoginContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  display: flex;
  width: 100%;
  > div {
    max-width: 575px;
    margin: 0 auto ${({ theme }) => theme.margin * 2}px auto;
    align-items: flex-start;
    > button {
      align-self: center;
    }
  }

  ${media.tablet(`
    max-width: 440px;
  `)}
  ${media.mobile(`
    max-width: 311px;
  `)}
`;

export const TextAreaContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margin * 2}px;
`;
export const StyledAppointmentCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: ${({ theme }) => theme.margin * 1.5}px;
  border: 1px solid ${({ theme }) => theme.colors.mediumBeige};
  width: 100%;
  div > span {
    margin: 0px ${({ theme }) => theme.margin}px;
  }
  div > svg,
  .label {
    margin-right: ${({ theme }) => theme.margin}px;
  }
  div > .iva-mark {
    margin-left: ${({ theme }) => theme.margin / 4}px;
  }
`;
