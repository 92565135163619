import styled from 'styled-components';
import { GridRow, media, Row } from 'Components/Layout';
import theme from 'Theme';

export const ServiceTabsContainer = styled(Row)`
  ${media.mobile(` 
    > div > div {
      flex-flow: row wrap;
    }
  `)}
`;
export const ServiceFilterContainer = styled(GridRow)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    margin-bottom: ${theme.margin - 11}px;
  }
  button {
    margin: ${theme.margin / 2}px;
    &:hover {
      cursor: pointer;
    }
  }
  ${media.mobile(`
    h2 {
      text-align: center;
      margin-top: 30%;
      margin-bottom: 10%;
      display: flex;
      flex-direction: row;
      div {
        height: 10px;
      }
    }
    
  `)}
`;

export const ServiceFilterTextContainer = styled(Row)`
  h2 {
    color: ${theme.colors.brand.orange};
  }

  svg {
    margin 0px ${theme.margin + 2}px 0px ${theme.margin - 6}px;
    > path {
      fill: ${theme.colors.brand.orange};
    }
  }
  :after {
    content: "";
    width: 92%;
    margin-left: 8%;
    border-bottom: 1px solid ${theme.colors.mediumBeige};
  }


  ${media.mobile(`
    > div {
      justify-content: center;
    }

    button {
      margin: 0px;
      padding: 5px;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 32px;
    }

  `)}
`;
export const ServiceSilderContainer = styled(GridRow)`
  align-items: center;
  display: block;
`;
