import styled from 'styled-components';

import { media } from 'Components/Layout';

const StyledCookieBanner = styled.div`
  position: fixed;
  right: 24px;
  top: 24px;
  background-color: ${props => props.theme.colors.white};
  width: 368px;
  max-height: 395px;
  padding: 24px;
  box-shadow: 0px 4px 13px rgba(193, 188, 183, 0.3);
  border-radius: 16px;
  font-size: 16px;
  line-height: 24px;
  z-index: 99;

  div.close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &:hover {
      cursor: pointer;
    }
  }

  p {
    margin: 0;
    margin-top: 37px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 37px;
  }

  a {
    color: ${props => props.theme.colors.brand.orange} !important;
    &.configure-cookies {
      display: block;
      margin-top: 20px;
    }
  }

  div.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 100%;
    }
  }

  ${media.mobile(`
    left: 0;
    right: 0;
    width: calc(100% - 48px);
    box-sizing: border-box;
    margin: 0 24px;
    max-height: 100%;
  `)}
`;

export default StyledCookieBanner;
