import styled from "styled-components";
import { Row, media } from "Components/Layout";

export const Container = styled(Row)`
  min-height: 320px;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  ${media.mobile(`
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
    & > div > div {
      width: 100%;
    }
  `)}
`;
