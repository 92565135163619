import React from 'react';
import { useTranslate } from '../../features/polyglot';

import { Row, Col } from 'Components/Layout';
import { Heading } from 'Components/Text';
import {
  PartnersSection,
  BottomSectionContainer,
  CoFinancedPartners,
  SplitSection,
  CoFinancedText
} from './styles';

import { staticImages } from 'Assets/img';
// Partners
const renderPartners = t => {
  return (
    <Col>
      <Row justify='center'>
        <Heading size={4}>{t('partners')}</Heading>
      </Row>
      <PartnersSection>
        {Object.keys(staticImages.partners.default).map(asset => {
          return (
            <img
              alt=''
              key={asset}
              src={staticImages.partners.default[asset]}
            />
          );
        })}
      </PartnersSection>
      <SplitSection />
      <CoFinancedText>{t('cofinanced')}</CoFinancedText>
      <CoFinancedPartners>
        {Object.keys(staticImages.partners.coFinanced).map((asset, index) => {
          return (
            <img
              alt=''
              key={asset}
              src={staticImages.partners.coFinanced[asset]}
            />
          );
        })}
      </CoFinancedPartners>
    </Col>
  );
};

// Bottom Header
const Header = ({ content }) => {
  const t = useTranslate('about.bottomSection');
  return (
    <BottomSectionContainer>
      {content}
      {renderPartners(t)}
    </BottomSectionContainer>
  );
};

export default Header;
