import appointmentServices from 'api/appointments';
import useStorage from 'Hooks/useStorage';
const { getStorageItem } = useStorage();
const REDUCER = 'appointments';

export const ACTION_TYPES = {
  GET_APPOINTMENT: `${REDUCER}/GET_APPOINTMENT`,
  GET_APPOINTMENT_SUCCESS: `${REDUCER}/GET_APPOINTMENT_SUCCESS`,
  GET_APPOINTMENT_FAIL: `${REDUCER}/GET_APPOINTMENT_FAIL`,
  GET_USER_APPOINTMENTS: `${REDUCER}/GET_USER_APPOINTMENTS`,
  GET_USER_APPOINTMENTS_SUCCESS: `${REDUCER}/GET_USER_APPOINTMENTS_SUCCESS`,
  GET_USER_APPOINTMENTS_FAILED: `${REDUCER}/GET_USER_APPOINTMENTS_FAILED`,
  GET_USER_CLOSED_APPOINTMENTS: `${REDUCER}/GET_USER_CLOSED_APPOINTMENTS`,
  GET_USER_CLOSED_APPOINTMENTS_SUCCESS: `${REDUCER}/GET_USER_CLOSED_APPOINTMENTS_SUCCESS`,
  GET_USER_CLOSED_APPOINTMENTS_FAILED: `${REDUCER}/GET_USER_CLOSED_APPOINTMENTS_FAILED`,
  GET_USER_OPEN_APPOINTMENTS: `${REDUCER}/GET_USER_OPEN_APPOINTMENTS`,
  GET_USER_OPEN_APPOINTMENTS_SUCCESS: `${REDUCER}/GET_USER_OPEN_APPOINTMENTS_SUCCESS`,
  GET_USER_OPEN_APPOINTMENTS_FAILED: `${REDUCER}/GET_USER_OPEN_APPOINTMENTS_FAILED`,
  UPDATE_FILTER: `${REDUCER}/UPDATE_FILTER`
};

const getAppointmentDetails = (dispatch, id) => {
  const userType = getStorageItem('AUTH_TOKEN')?.user?.type;
  dispatch({
    type: ACTION_TYPES.GET_APPOINTMENT
  });
  appointmentServices
    .getAppointment(userType, id)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_APPOINTMENT_SUCCESS,
          payload: res.data,
          form: res.data?.included?.find(d => d.type === 'form')?.attributes
            ?.questions?.questions,
          answers: res.data?.included?.find(d => d.type === 'lead')?.attributes
            ?.form_answers
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_APPOINTMENT_FAIL,
        error: 'Error getting Appointment'
      });
    });
};



const updateFilter = (dispatch, pageNumber, anotherFilter) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_FILTER,
    payload: anotherFilter
  });
};

const getUserAppointments = (dispatch, pageNumber, anotherFilter) => {
  const userType = getStorageItem('AUTH_TOKEN')?.user?.type;
  dispatch({
    type: ACTION_TYPES.GET_USER_APPOINTMENTS
  });
  appointmentServices
    .getAppointments(userType, pageNumber, anotherFilter)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_USER_APPOINTMENTS_SUCCESS,
          pageNumber,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_USER_APPOINTMENTS_FAILED,
        // errors: e.response.data
      });
    });
};

const getClosedAppointments = (dispatch, pageNumber, anotherFilter) => {
  const userType = getStorageItem('AUTH_TOKEN')?.user?.type;
  dispatch({ type: ACTION_TYPES.GET_USER_CLOSED_APPOINTMENTS });
  appointmentServices
    .getClosedAppointments(userType, pageNumber, anotherFilter)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_USER_CLOSED_APPOINTMENTS_SUCCESS,
          pageNumber,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_USER_CLOSED_APPOINTMENTS_FAILED,
        // errors: e.response.data
      });
    });
};

const getOpenAppointments = (dispatch, pageNumber, anotherFilter) => {
  const userType = getStorageItem('AUTH_TOKEN')?.user?.type;
  dispatch({ type: ACTION_TYPES.GET_USER_OPEN_APPOINTMENTS });
  appointmentServices
    .getOpenAppointments(userType, pageNumber, anotherFilter)
    .then(res => {
      if (res && res.data) {
        dispatch({
          type: ACTION_TYPES.GET_USER_OPEN_APPOINTMENTS_SUCCESS,
          pageNumber,
          payload: res.data
        });
      }
    })
    .catch(e => {
      dispatch({
        type: ACTION_TYPES.GET_USER_OPEN_APPOINTMENTS_FAILED,
        // errors: e.response.data
      });
    });
};

export {
  getAppointmentDetails,
  getUserAppointments,
  getClosedAppointments,
  getOpenAppointments,
  updateFilter
};
