import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';
import { toast } from 'react-toastify';

import { FormContainer } from './styles';
import { Form } from 'ui-55';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from "react-redux";
import { selectLocale } from '../../features/polyglot/polyglotSlice';

const AppForm = ({
  onSubmit,
  formFields,
  btnLabel,
  bg,
  children,
  isDisabled,
  hiddenFields,
  answers
}) => {
  const locale = useSelector(selectLocale);
  const t = useTranslate('forms');
  const [keyForForm, setKeyForForm] = useState(0);
  const formRef = useRef();
  const scrollToRef = ref => {
    ref.current.scrollIntoView();
  };
  useEffect(() => {
    setKeyForForm(prevKey => prevKey + 1);
  }, [locale]);

  const handleError = errors => {
    if (Object.keys(errors).length) {
      const errorField = Object.keys(errors)[0];
      const errorMsg =
        errors[errorField] === 'Obrigatório'
          ? t('required-fields')
          : errors[errorField];
      toast.error(errorMsg);
      scrollToRef(formRef);
    }
  };

  const handleSubmit = payload => {
    const formAnswers = payload;
    Object.keys(formAnswers).forEach(() => {
      switch (formAnswers['login-buttons']) {
        case 'guest':
          formAnswers['guest'] = {
            firstName: formAnswers['first-name'],
            lastName: formAnswers['last-name'],
            email: formAnswers['email'],
            nif: formAnswers['nif'],
            language_code: locale,
            telephone: formAnswers['telephone']
          };
          break;
        case 'login':
          formAnswers['login'] = {
            email: formAnswers['email'],
            password: formAnswers['password']
          };
          break;
        case 'signup':
          formAnswers['guest'] = {
            firstName: formAnswers['first-name'],
            lastName: formAnswers['last-name'],
            email: formAnswers['email'],
            telephone: formAnswers['telephone'],
            nif: formAnswers['nif'],
            language_code: locale,
            password: formAnswers['password']
          };
          break;
        default:
          return;
      }
    });
    if (onSubmit) {
      onSubmit(payload);
    }
  };
  return (
    <FormContainer ref={formRef} bg={bg}>
      {children}
      <Form
        locale={locale}
        key={keyForForm}
        hiddenFields={hiddenFields}
        isDisabled={isDisabled}
        answers={answers}
        onError={handleError}
        questions={formFields?.questions}
        onSubmit={handleSubmit}
        backgroundColor={bg}
        submitLabel={btnLabel}
        translate={t}
      />
    </FormContainer>
  );
};

export default AppForm;

AppForm.propTypes = {
  formFields: PropTypes.shape({
    questions: PropTypes.array
  }),
  children: PropTypes.node,
  btnLabel: PropTypes.string,
  answers: PropTypes.object,
  hiddenFields: PropTypes.array,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  bg: PropTypes.string,
  isDisabled: PropTypes.bool
};
