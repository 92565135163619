import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';
import useKeypress from 'Hooks/useKeypress';
import { Body } from 'Components/Text';
import { Container, Input, Button } from './style';

const SearchInput = ({
  error,
  placeholder,
  defaultValue,
  label,
  onChange,
  onSubmit,
  searchValue,
  style = 'main'
}) => {
  const t = useTranslate('buttons');

  useKeypress('Enter', onSubmit, [searchValue]);

  return (
    <Container>
      <Input placeholder={placeholder} onChange={onChange} />
      {style === 'main' && (
        <Button onClick={onSubmit}>
          <Body>{t('searchBtn')}</Body>
        </Button>
      )}
    </Container>
  );
};

SearchInput.propTypes = {
  error: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  style: PropTypes.oneOf(['noButton', 'main', 'secondary']),
  onChange: PropTypes.func
};

export default SearchInput;
