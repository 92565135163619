import styled from "styled-components";
import { GridCol } from "Components/Layout";
export const Container = styled.div`
  min-height: 450px;
  margin-top: ${props => props.theme.margin * 3}px;
  button {
    margin-top: ${props => props.theme.margin * 2}px;
  }
`;

export const StyledContentHolder = styled(GridCol)`
  justify-content: center;
  text-align: center;
  p,
  h2 {
    text-align: center;
  }
`;
