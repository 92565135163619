import React, { useEffect, useMemo, useState } from 'react';
import { useTranslate } from '../../../features/polyglot';
import Form from 'Components/UIForm';
import { Body, ErrorText, Heading, SmallBody } from 'Components/Text';
import { Col, Row } from 'Components/Layout';
import Card from 'Components/Card';
import kc from 'lodash.kebabcase';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '../../../features/polyglot/polyglotSlice';
import { postProviderForm } from 'Redux/provider/actions';
import { useHistory } from 'react-router-dom';

import useServices from 'Hooks/useServices';
import { CheckBoxGroup } from 'ui-55';

import { BecomeFormContainer, ErrorMessageHolder, MainContainer, TermsAndConditionsContainer } from './styles';
import { toast } from 'react-toastify';

const BecomeForm = () => {
  const translateLabels = useTranslate('serviceForm.labels');
  const t = useTranslate('become.form');
  const f = useTranslate('formsTermsAndConditions');
  const locale = useSelector(selectLocale);
  const translateForm = useTranslate('forms');
  const dispatch = useDispatch();
  const history = useHistory();

  const [checkBoxError, setCheckBoxError] = useState(false);

  useEffect(() => {
    const updatedCheckedTerms = checkedTerms.map(term => {
      if (term.key === 'terms') {
        return {
          ...term,
          question: `${f('checkboxText') + ' <a href="/terms-and-conditions" target="_blank">' + f('checkboxTextTermsAndCondition') + '</a> ' + f('checkboxTextAnd') + '<a href="/privacy-policy" target="_blank"> ' + f('checkboxTextPrivacyPolicy') + '</a>'}`,
        };
      } else if (term.key === 'newsletter') {
        return {
          ...term,
          question: f('acept')
        };
      }
      return term;
    });

    setCheckedTerms(updatedCheckedTerms);
  }, [f, checkedTerms]);

  const [checkedTerms, setCheckedTerms] = useState([
    {
      key: 'terms',
      question: `${f('checkboxText') + ' <a href="/terms-and-conditions" target="_blank">' + f('checkboxTextTermsAndCondition') + '</a> ' + f('checkboxTextAnd') + ' <a href="/privacy-policy" target="_blank"> ' + f('checkboxTextPrivacyPolicy') + '</a>'}`,
      isSelected: false
    },
    {
      key: 'newsletter',
      question: f('acept'),
      isSelected: false
    }
  ]);

  const { errors, isSubmitting, wasSuccessful } = useSelector(
    state => state.providers
  );

  const GASTRO_EXP_SLUG = 'gastro_experience';
  const cities = useSelector(state => state.cities.citiesWithOther);
  const { services } = useServices();
  const serviceOptions = useMemo(() => {
    return services
      .map(service => {
        return {
          value: service.id,
          label: translateLabels(service.attributes.name),
          slug: service.attributes.slug
        };
      })
      .filter(srv => srv.slug !== GASTRO_EXP_SLUG);
  }, [services, locale]);

  const FIELDS = {
    questions: [
      { type: 'text', key: 'first_name', label: t('name'), required: true },
      {
        type: 'text',
        key: 'last_name',
        label: t('lastName'),
        required: true
      },
      {
        placeholder: translateLabels('Selecionar'),
        type: 'dropdown',
        key: 'district',
        label: t('county'),
        widget: 'district',
        required: true
      },
      { type: 'email', key: 'email', label: t('email'), required: true },
      {
        type: 'text',
        key: 'phone_number',
        label: t('phone'),
        required: true
      },
      { type: 'text', key: 'nif', label: t('nif'), required: true },
      {
        placeholder: translateLabels('Selecionar'),
        type: 'dropdown',
        key: 'service_ids',
        isMulti: true,
        label: t('services'),
        options: serviceOptions,
        required: true
      },

      {
        type: 'text-area',
        key: 'notes',
        placeholder: t('placeholderNote'),
        label: t('notes')
      }
    ]
  };

  const handleSubmit = formFields => {
    const cityID = cities.find(
      city => kc(city.attributes.name) === formFields['district']
    )?.id;

    formFields.city_id = cityID;
    formFields.accepted_terms = checkedTerms[0]?.isSelected;
    formFields.accepted_newsletter = checkedTerms[1]?.isSelected;
    if (!formFields.accepted_terms) {
      return setCheckBoxError(true);
    }
    formFields.language_code = locale;
    postProviderForm(dispatch, formFields);
  };

  const handleCheckBoxSelection = vals => {
    setCheckedTerms(vals);
    setCheckBoxError(false);
  };

  useEffect(() => {
    if ((!isSubmitting, wasSuccessful)) {
      return history.push('/thankYou', { originPage: 'becomeRequest' });
    }
  }, [history, isSubmitting, wasSuccessful]);

  useEffect(() => {
    if (errors && errors?.meta?.message?.email) {
      toast.error('Email já se encontra registado');
    }
  }, [errors]);

  return (
    <MainContainer>
      <BecomeFormContainer>
        <Col size={2} />
        <Col size={8}>
          <Card bg='alt' align='center'>
            <Row justify='center' bg='alt' padding={2}>
              <Heading align='center' size={3}>
                {t('title')}
              </Heading>
              <Body align='center'>{t('subtitle')}</Body>
            </Row>
            <Form
              translate={translateForm}
              locale={locale}
              isDisabled={isSubmitting}
              bg='alt'
              btnLabel={t('signUp')}
              formFields={FIELDS}
              onSubmit={handleSubmit}
            ></Form>
            <TermsAndConditionsContainer>
              <SmallBody
                style={{ margin: '15px' }}
                dangerouslySetInnerHTML={{ __html: f('Talents') + ' <a href="/privacy-policy" target="_blank">' + f('TalentsPolicyPrivacy') + '</a>.' }}
              ></SmallBody>
              <CheckBoxGroup
                action={vals => handleCheckBoxSelection(vals)}
                list={checkedTerms}
              />
              {checkBoxError && (
                <ErrorMessageHolder>
                  <ErrorText>* {f('error')}</ErrorText>
                </ErrorMessageHolder>
              )}
            </TermsAndConditionsContainer>
          </Card>
        </Col>
        <Col size={2} />
      </BecomeFormContainer>
    </MainContainer>
  );
};

export default BecomeForm;
