import styled from 'styled-components';
import { GridRow, GridCol, media, Hero } from 'Components/Layout';
import theme from 'Theme';

export const HeaderContainer = styled(Hero)`
  background: linear-gradient(
    to right,
    ${theme.colors.lightBeige} 50%,
    ${theme.colors.white} 50%
  );
  ${media.smallDesktop(`
    margin-bottom: ${theme.margin * 5}px;
    height: auto;
    top: 0px;
    & > div {
      margin-top: 20px;
    }
    h1 {
      font-size: 32px;
      line-height: 40px;
    }
    svg {
      width: 300px;
    }
  `)}
  ${media.mobile(`
    margin-bottom: 0px;
    background: ${theme.colors.white};
    svg {
      width: 235px;
    }
  `)}
`;

export const HeaderGrid = styled(GridRow)`
  ${media.maxNavbar(`
    margin-top: 60px;
    margin-left: 50px;
    margin-right: 50px;
  `)}
  ${media.smallDesktop(`
    padding: 0px;
  `)}
  ${media.mobile(`
    flex-direction: column;
    background: ${theme.colors.white};
  `)}
`;

export const HeaderColumn = styled(GridCol)`
  ${media.maxNavbar(`
    svg {
      width: auto;
    }
  `)}

  ${media.mobile(`
    width: 100%;
    align-self: center;

    & > h1 {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }
    & > h2 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;

    }
    & > p {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  `)}
`;
