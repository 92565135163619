import React from 'react';
import {
  DropDownContainer,
  ListContainer,
  ClickOutsideContainer
} from './style';
import Button from 'Components/Button';
import PropTypes from 'prop-types';

const DropDownMenu = ({ menuOptions, isOpen, onClose }) => {
  return (
    <>
      {isOpen && onClose && (
        <ClickOutsideContainer
          onClick={e => {
            e.preventDefault();
            onClose();
          }}
        />
      )}
      <DropDownContainer isOpen={isOpen}>
        {renderMenuList(isOpen, menuOptions)}
      </DropDownContainer>
    </>
  );
};

const renderMenuList = (isOpen, menuOptions) => {
  const list = menuOptions.map((item, index) => {
    const icon = item?.hasIcon?.name ?? '';
    return (
      <ListContainer isOpen={isOpen} key={`${item}-${index}`}>
        <Button
          btnType='transparent'
          text={item.label}
          action={item.action}
          icon={icon}
          hasLeftAlignedIcon
        />
      </ListContainer>
    );
  });
  return list;
};

DropDownMenu.propTypes = {
  onClose: PropTypes.func,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      hasIcon: PropTypes.shape({
        name: PropTypes.string
      })
    })
  ),
  isOpen: PropTypes.bool
};
export default DropDownMenu;
