import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

import useCities from 'Hooks/useCities';
import { useServiceDetail } from 'hooks/useServices';

import ServiceDetailTop from './ServiceDetailTop';
import ServiceDetailBottom from './ServiceDetailBottom';

import { Container, ServiceDetailContainer } from './styles';
import Loader from 'Components/Loader';

const ServiceDetail = () => {
  const { id } = useParams();
  const { city } = useCities();
  const { service, loaded, error, formLoaded } = useServiceDetail(id, city?.id);

  if (error) {
    if (error === 404) {
      return <Redirect to='/not-found' />;
    }
    if (error === 500) {
      return <Redirect to='/server-error' />;
    }
  }

  return loaded ? (
    <Container>
      <ServiceDetailContainer>
        <ServiceDetailTop service={service} />
        <ServiceDetailBottom service={service} loaded={formLoaded} />
      </ServiceDetailContainer>
    </Container>
  ) : (
    <Loader />
  );
};

export default ServiceDetail;
