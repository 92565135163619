import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../features/polyglot';

import { Heading, Link, SmallBody } from 'Components/Text';
import Button from 'Components/Button';
import { Icon, Switcher, Row, Col } from 'ui-55';

import StyledBanner, {
  StyledModal,
  StyledBackdrop,
  CookiesCard
} from './style';

const CookieBanner = ({ action, close }) => {
  const t = useTranslate('cookieBanner');
  const [configModal, setConfigModal] = useState(false);
  const [performanceCookiesEnabled, setPerformanceCookiesEnabled] = useState(
    (!window[`ga-disable-${process.env.REACT_APP_GA_KEY}`])
  );
  const handlePerformanceCookieChange = () => {
    const newValue = !performanceCookiesEnabled;
    window[`ga-disable-${process.env.REACT_APP_GA_KEY}`] = newValue;
    setPerformanceCookiesEnabled(newValue);
  };
  return (
    <>
      {configModal && (
        <>
          <StyledModal open={configModal}>
            <Heading size={3}>Configurações de Cookies para este site</Heading>
            <Row size={3}></Row>
            <CookiesCard>
              <Col>
                <Heading size={4}>Cookies de Performance</Heading>
                <SmallBody>
                  Geridos pelo Google Analytics. Permite-nos oferecer um melhor
                  serviço e personalizar a experiência do utilizador.F
                </SmallBody>
              </Col>
              <Switcher
                checked={performanceCookiesEnabled}
                action={handlePerformanceCookieChange}
              />
            </CookiesCard>
          </StyledModal>
          <StyledBackdrop
            open={configModal}
            onClick={() => setConfigModal(false)}
          />
        </>
      )}
      <StyledBanner>
        <div role='presentation' className='close' onClick={close}>
          <Icon name='Close' />
        </div>
        <p>
          {`${t('cookieWarning')} `}
          <Link to='/privacy-policy'>{t('privacyPolicy')}</Link>.
        </p>
        <div className='links'>
          <Button
            btnType='primary'
            text='Entendi'
            action={action}
            isFullWidth={true}
          />
          <Button
            btnType='transparent'
            className='configure-cookies'
            text={t('cookieSettings')}
            action={() => setConfigModal(true)}
          ></Button>
        </div>
      </StyledBanner>
    </>
  );
};

CookieBanner.propTypes = {
  action: PropTypes.func
};

export default CookieBanner;
