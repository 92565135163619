import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from 'Redux/appointments';

const useAppointments = ({ type, user, id, page, anotherFilter }) => {
  const dispatch = useDispatch();
  const {
    //show
    getAppointments,
    getAppointment,
    getAppointmentLoading,
    getAppointmentError,
    getLoading,
    getErrors,
    getSubmitState,
    getSuccessState,
    //create new apointment
    getAppointmentForm
  } = selectors;

  const {
    getUserAppointments,
    getClosedAppointments,
    getAppointmentDetails,
    getOpenAppointments,
    updateFilter
  } = actions;
  // SHOW

  const appointments = useSelector(
    state => getAppointments(state),
    shallowEqual
  );

  const filteredByType = useMemo(() => {
    return appointments?.[type + '-' + page] || {};
  }, [appointments, page, type]);

  const appointmentData = useSelector(state => getAppointment(state));
  const loading = useSelector(state => getLoading(state)[type], shallowEqual);
  const appointmentLoading = useSelector(
    state => getAppointmentLoading(state),
    shallowEqual
  );
  const errors = useSelector(state => getErrors(state), shallowEqual);
  const appointmentError = useSelector(
    state => getAppointmentError(state),
    shallowEqual
  );

  const isSubmitting = useSelector(
    state => getSubmitState(state),
    shallowEqual
  );

  const wasSuccessful = useSelector(
    state => getSuccessState(state),
    shallowEqual
  );

  const filter = useSelector((store) => {return store?.appointments?.filter;})

  const setUpdateFilter = (anotherFilter) => {
    updateFilter(dispatch, page, anotherFilter);
    getOpenAppointments(dispatch, page, anotherFilter);
    getClosedAppointments(dispatch, page, anotherFilter);
    getUserAppointments(dispatch, page, anotherFilter);
  }



  const dispatchGetAppointments = useCallback(() => {
    if (type === 'open') {
      getOpenAppointments(dispatch, page, filter);
    }
    if (type === 'closed') {
      getClosedAppointments(dispatch, page, filter);
    }
    if (type === 'all') {
      getUserAppointments(dispatch, page, filter);
    }
  }, [
    dispatch,
    getClosedAppointments,
    getOpenAppointments,
    getUserAppointments,
    page,
    type,
    filter
  ]);
  const appointment = useMemo(
    () =>
      appointmentData?.data ||
      (appointments?.data || []).find(apt => apt.id === id),
    [appointmentData, appointments, id]
  );

  useEffect(() => {
    if (user && !Object.keys(filteredByType).length && !errors && !loading) {
      dispatchGetAppointments(type, page);
    }
    if (
      id &&
      (!appointmentData || appointmentData?.data?.id !== id) &&
      !appointmentLoading &&
      !appointmentError
    ) {
      getAppointmentDetails(dispatch, id);
    }
  }, [
    filteredByType,
    dispatchGetAppointments,
    errors,
    loading,
    page,
    type,
    user,
    id,
    getAppointmentDetails,
    dispatch,
    appointmentLoading,
    appointmentError,
    appointment,
    appointmentData
  ]);

  const appointmentForm = useSelector(
    state => getAppointmentForm(state),
    shallowEqual
  );

  return {
    //show
    appointment,
    appointmentData,
    appointmentLoading,
    getAppointments: dispatchGetAppointments,
    appointments: appointments || appointmentData,
    loading,
    errors,
    isSubmitting,
    wasSuccessful,
    appointmentForm,
    setUpdateFilter
  };
};

export default useAppointments;
