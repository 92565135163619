import styled from 'styled-components';

import { SmallBody, Col, Row, Body, media, Icon } from 'ui-55';

export const MobileServiceHeader = styled(Row)`
  background-color: ${props => props.theme.colors.lightestBeige} !important;
  display: none;
  position: relative;
  min-width: 375px;
  right: 48px;
  padding: 0px 16px;
  margin-bottom: 24px;
  margin-top: 16px;
  align-items: center;
  button {
    margin-left: ${props => props.theme.margin * 2}px;
  }
  h2 {
    margin-left: ${props => props.theme.margin}px;
  }
  ${media.mobile(`display: flex;`)}
`;
export const NoInvoiceMessage = styled(Row)`
  margin-top: 40px;
`;
// invoices
export const StyledServiceName = styled(Body)`
  ${media.mobile(`display: none;`)}
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.colors.brand.orange};
  font-size: 16px;
  line-height: 24px;
`;

export const PaymentDetails = styled(Row)`
  width: 100%;
  border: solid 1px ${props => props.theme.colors.mediumBeige};
  ${media.mobile(`flex-flow: column;`)}
`;
export const Dot = styled.div`
  border-radius: 50%;
  height: ${props => props.theme.margin}px;
  width: ${props => props.theme.margin}px;
  margin-right: ${props => props.theme.margin * 0.5}px;
  background-color: ${props =>
    props.paid
      ? props.theme.colors.feedback.success.default
      : props.theme.colors.feedback.error.default};
`;

export const PaymentCol = styled(Col)`
  flex-direction: initial;
  align-items: center;
  ${media.mobile(`
    padding: 8px;
    max-height: 40px;
  `)}
`;

export const SmallBodyDetail = styled(SmallBody)`
  color: ${props => props.theme.colors.grey};
  margin-right: 4px;
`;

export const TableContainer = styled.div`
  margin-top: ${props => props.theme.margin * 1.5}px;
`;

export const InvoiceHeaders = styled(Row)`
  background-color: ${props => props.theme.colors.lightestBeige} !important;
  margin-top: ${props => props.theme.margin * 2}px;
  p {
    margin: 0px;
  }
  ${media.mobile(`
    display: none;
  `)}
`;

export const InvoiceDetailsBody = styled(Row)`
  button {
    padding: 8px;
    align-self: center;
    width: 100%;
    > span {
      white-space: nowrap;
    }
  }
  a[name='invoice-url'] {
    width: 100%;
  }
  a[name='pdf-url'] {
    align-self: center;
    &:hover {
      cursor: pointer;
    }
  }
  min-height: 96px;
  border-bottom: 1px solid ${props => props.theme.colors.mediumBeige};
  ${media.mobile(`
  flex-flow: column;
  div[name="service-name"] {
    display: none;
  }
  flex-flow: column;
  margin-top: 32px;
    > div {
      border: 1px solid #D2CCC6;
      border-radius: 2px;
    }
  `)}
`;

export const StyledIcon = styled(Icon)`
  display: none !important;
  align-self: center;
  background-color: ${props => props.theme.colors.lightBeige};
  border-radius: 6px;
  ${media.mobile(`display: block !important;`)};
`;

export const StyledMobileBodyHeaders = styled(SmallBody)`
  display: none;
  ${media.mobile(`display: flex;`)}
  color: ${props => props.theme.colors.grey};
`;

export const StyledMobileCols = styled(Col)`
  padding: 0;
  ${media.mobile(`
    p {
      margin: 4px;
      margin-left: 24px;
    }
  `)}
`;
