const stepOne = () => {
  return [
    {
      key: 'client',
      groupBy: 2,
      widget: 'login',
      value: 'client',
      children: [

        {
          key: 'name',
          type: 'text',
          label: 'name',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'lastName',
          type: 'text',
          label: 'lastName',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'country',
          type: 'dropdown',
          placeholder: 'select',
          label: 'country',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client',
          options: [{ label: 'Portugal', value: 1 }]
        },
        {
          key: 'nif',
          type: 'text',
          label: 'nif',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },

        {
          key: 'address',
          type: 'text',
          label: 'address',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'postalCode',
          type: 'text',
          label: 'postalCode',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'district',
          widget: 'district',
          label: 'district',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'language_code',
          type: 'dropdown',
          label: 'language',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client',
          placeholder: "select",
          options: [
            { label: 'pt', value: 'pt' },
            { label: 'en', value: 'en-uk' },
            { label: 'fr', value: 'fr' },
            { label: 'es', value: 'es' }]
        },
        {
          widget: 'space',
          hidden: true,
          label: '',
          dependencyType: 'value',
          dependencyValue: 'client'
        }
      ],
      options: []
    }
  ];
};

const stepTwo = () => {
  return [
    {
      key: 'client',
      groupBy: 2,
      value: 'client',
      widget: 'login',
      children: [
        {
          key: 'new-email',
          type: 'email',
          pattern: 'email',
          label: 'new-email',
          widget: 'email',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'new-email-repeat',
          type: 'email',
          pattern: 'email',
          label: 'new-email-repeat',
          widget: 'email',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'telephone',
          type: 'text',
          pattern: 'telephone',
          label: 'telephone',
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          widget: 'space',
          hidden: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        }
      ],
      options: []
    }
  ];
};
const stepThree = () => {
  return [
    { type: 'footnote', label: 'password-update' },
    {
      key: 'client',
      groupBy: 2,
      value: 'client',
      widget: 'login',
      children: [
        {
          key: 'password',
          type: 'password',
          label: 'current-password',
          widget: 'password',
          hasIcon: true,
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'new-password',
          type: 'password',
          label: 'new-password',
          widget: 'password',
          hasIcon: true,
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          key: 'new-password-repeat',
          type: 'password',
          label: 'new-password-repeat',
          widget: 'password',
          hasIcon: true,
          required: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        },
        {
          widget: 'space',
          hidden: true,
          dependencyType: 'value',
          dependencyValue: 'client'
        }
      ],
      options: []
    }
  ];
};
const detailsSteps = [stepOne(), stepTwo(), stepThree()];

const STEP_LABELS = ['Dados Pessoais', 'Dados de Contacto', 'Dados de Acesso'];
const detailsSchema = {
  step: detailsSteps.map((step, stepIndex) => ({
    stepIndex,
    key: `settings-${stepIndex}`,
    label: STEP_LABELS[stepIndex],
    questions: step
  }))
};

export default detailsSchema;
