import { ACTION_TYPES } from './actions';

const initialState = {
  invoices: null,
  loading: false,
  errors: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_USER_INVOICES:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.GET_USER_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action?.payload?.data
      };
    case ACTION_TYPES.GET_USER_INVOICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.error
      };
    case ACTION_TYPES.GET_INVOICES_BY_APPOINTMENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ACTION_TYPES.GET_INVOICES_BY_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload?.data,
        error: null
      };
    case ACTION_TYPES.GET_INVOICES_BY_APPOINTMENT_FAILED:
      return {
        ...state,
        loading: true,
        error: action?.error
      };
    default:
      return state;
  }
};
