import styled from "styled-components";
import { ReversedColumn, media } from "Components/Layout";
import theme from "Theme";
import { Heading } from "Components/Text";
export const Container = styled(ReversedColumn)`
  ${media.smallDesktop(`svg {width: 300px;}`)}
  ${media.tablet(`
    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  `)}
  ${media.mobile(`
  svg {width: 253px;}
  & > div {
    width: 100%;
   }
  `)}
`;

export const CustomHeading = styled(Heading)`
  ${media.mobile(`
    font-weight: bold;
    font-size: 16px !important;
    color: ${theme.colors.grey};
`)}
`;
