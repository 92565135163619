import React from 'react';

import { GridCol } from 'Components/Layout';
import { SubHeaderContainer } from './styles';
const SubHeader = ({ history, statistics }) => {
  return (
    <SubHeaderContainer>
      <GridCol text>
        {history}
        {statistics}
      </GridCol>
    </SubHeaderContainer>
  );
};

export default SubHeader;
